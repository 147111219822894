import React, { useState, useEffect } from "react";
// import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import userPic from "../../assets/images/user.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import countries from "../../assets/data/countries.json";
import antiClock from "../../assets/images/anticlock.png";
import {
  getOTP,
  registerOTP,
  switchPatient,
  verifyMobileOTP,
} from "../../actions/post-call";
import { getProfile, configurations } from "../../actions/get-calls";
import { setCallTokenReducer } from "../../redux/slice/call-token";
import { setSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setAllUsersReducer } from "../../redux/slice/all-users";
import { setSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer } from "../../redux/slice/user-profile";
import { jQueryLabels } from "../../utils/fliters";
import { useNavigate } from "react-router-dom";
import { countryState, stateCity } from "../../actions/dashboard-api";
import {
  APP_SUB_FOLDER,
  DefaultMetaTag,
  is_group_exist,
  Logo,
  selectedCountry,
  TermsPageURL,
} from "../../utils/constant";
import {
  subDays,
  addDays,
  subMonths,
  addMonths,
  addYears,
  subYears,
} from "date-fns";
import moment from "moment";
import SpinnerComponent from "../../component/spinnerComponent";
import QrNavBar from "../../component/navbar/qrNavbar";
import Helmet_SEO_META from "../../component/seo_meta";
import HospitalListModal from "../../component/hospital-list-modal";

const QrRegister = () => {
  var oneYearFromNow = new Date();
  const hospitalAddressList = useSelector(
    (state) => state.hospitalAddressList.value
  );
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 18);
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const [DOB, setDOB] = useState(null);
  const [valid, setValid] = useState(null);
  const [allState, setAllState] = useState(null);
  const [allCity, setAllCity] = useState(null);
  const [isTerm, setIsTerm] = useState(false);

  const [isVerify, setIsVerify] = useState(false);
  const [currentOTP, setCurrentOTP] = useState("");

  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);

  const [salutationList, setSalutationList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [bloodGroupList, setBloodGroupList] = useState([]);
  const [idTypeList, setIDTypeList] = useState([]);

  const [selectedFile, setSelectedFile] = useState();
  const [profileImage, setProfileImage] = useState();
  const [mobile_no, setMobileNo] = useState("");
  const [emailNaState, setEmailNaState] = useState(false);
  const [frontView, setFrontView] = useState();
  const [frontImg, setFrontImg] = useState();
  const [backView, setBackView] = useState();
  const [backImg, setBackImg] = useState();
  const [spinnerResponse, setSpinnerResponse] = useState(false);
  const [sucussMessage, setSucussMessage] = useState(false);

  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const navigate = useNavigate();
  //  console.log("isVerify", isVerify, sucussMessage)
  useEffect(() => {
    if (selectedUser) {
      // return window.location.href = '/';
      navigate(`/qr-register`);
    }
  }, [selectedUser]);

  useEffect(() => {
    jQueryLabels();
    countryState('101', currentLocation?.hospital_group_id)
      .then(function (response) {
        // {id: 101, name: 'India'}
        setAllState(response.data.states);
      })
      .catch(function (err) {
        console.log(err);
      });

  }, [currentLocation]);

  useEffect(() => {
    configurations()
      .then(function (response) {
        // console.log(response)
        setSalutationList(response.data.salutation);
        setGenderList(response.data.gender);
        setMaritalStatusList(response.data.marital_status);
        setBloodGroupList(response.data.blood_group);
        setIDTypeList(response.data.id_type);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  const onChangeState = (e) => {
    let state = e.target.options[e.target.selectedIndex].id;
    // console.log(e.target.options[e.target.selectedIndex].id)
    if (state != "") {
      stateCity(state, currentLocation?.hospital_group_id)
        .then(function (response) {
          setAllCity(response.data.cities);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  const profilePicChangeHandler = (e) => {
    //  console.log(e.target.files);
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setSelectedFile(reader.result);
      setProfileImage(file);
    }.bind(this);
  };

  const browseImage = (e, browsefrom) => {
    e.preventDefault();
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    const fileSize =  e.target.files[0].size;
    const fileMb = fileSize / 1024 ;
   

    if(fileMb>=1024){
      showError("Image size should be less than 1MB..");
      setFrontView("");
      setBackView("");
    }

    else{

   
    reader.onloadend = function (e) {
      if (browsefrom === "front") {
        setFrontView(reader.result);
        setFrontImg(file);
      }
      if (browsefrom === "back") {
        setBackView(reader.result);
        setBackImg(file);
      }
    }}
  };

  const handleEmailNaChange = (e) => {
    if (e.target.checked == true) {
      setEmailNaState(true);
      let email = (document.querySelector("#email").value = "");
    } else {
      setEmailNaState(false);
    }
  };

  const registerUpdateHandler = (e) => {
    e.preventDefault();
    if (document.querySelector("#salutation").value == "") {
      showError("Salutation is required");
      return false;
    }
    if (document.querySelector("#first_name").value == "") {
      showError("First Name is required");
      return false;
    }
    if (document.querySelector("#last_name").value == "") {
      showError("Last Name is required");
      return false;
    }
    if (document.querySelector("#mobile_no").value == "") {
      showError("Mobile No. is required");
      return false;
    }
    if (emailNaState == false && document.querySelector("#email").value == "") {
      showError("Email is required");
      return false;
    }
    if (emailNaState == false && document.querySelector("#email").value == "") {
      showError("Email is required");
      return false;
    }
    if (document.querySelector("#dob").value == "") {
      showError("Date of Birth is required");
      return false;
    }
    if (document.querySelector("#gender").value == "") {
      showError("Gender is required");
      return false;
    }
    if (document.querySelector("#address_1").value == "") {
      showError("Address Line 1 is required");
      return false;
    }
    if (document.querySelector("#state").value == "") {
      showError("State is required");
      return false;
    }
    if (document.querySelector("#city").value == "") {
      showError("City is required");
      return false;
    }

    setMobileNo(e.target.elements.mobile_no.value);

    var payload = new FormData();
    if(is_group_exist == true){
        payload.append("hospital_group_id", currentLocation?.hospital_group_id);
    }
    payload.append("registration_source", 1);
    payload.append('enterprise_id', currentLocation?.hospital_group_id);
    payload.append("salutation", e.target.elements.salutation.value);
    payload.append("first_name", e.target.elements.first_name.value);
    if (e.target.elements.middle_name.value != "") {
      payload.append("middle_name", e.target.elements.middle_name.value);
    }
    payload.append("last_name", e.target.elements.last_name.value);
    payload.append("isd_code", "91");
    payload.append("mobile_no", e.target.elements.mobile_no.value);
    if (emailNaState == true) {
      payload.append("email_na", 1);
      // payload.append('email', '');
    } else {
      payload.append("email", e.target.elements.email.value);
    }
    payload.append("dob", e.target.elements.dob.value);
    payload.append("gender", e.target.elements.gender.value);
    payload.append("marital_status", e.target.elements.marital_status.value);
    payload.append("blood_group", e.target.elements.blood_group.value);
    payload.append(
      "emergency_contact",
      e.target.elements.emergency_contact.value
    );

    if (profileImage) {
      payload.append("profile_picture", profileImage);
    }

    payload.append("address_1", e.target.elements.address_1.value);
    payload.append("address_2", e.target.elements.address_2.value);
    payload.append("country", e.target.elements.country.value);
    payload.append("pin_code", e.target.elements.pin_code.value);
    payload.append("state", e.target.elements.state.value);
    payload.append("city", e.target.elements.city.value);

    registerOTP(payload)
      .then(function (response) {
        let res = response.data;
        if (res && res.errors) {
          res.errors.map((e) => {
            return showError(e.message);
          });
          return false;
        }

        setIsResend(true);
        setIsVerify(true);
        // toast.success("Registrated successfully! Please enter OTP to login");
      })
      .catch(function (err) {});
  };

  function verifyOTP(e) {
    setSpinnerResponse(true);
    e.preventDefault();
    //hospital_group_id: currentLocation?.hospital_group_id,  group_id: currentLocation?.hospital_group_id
    verifyMobileOTP({ isd_code: "91", otp: currentOTP, uid: mobile_no })
      .then(function (login_response) {
        setSpinnerResponse(false);
        let login_res = login_response.data;
        let firstToken = login_response.headers.authorization;

        if (login_res.errors && login_res.errors.length > 0) {
          return showError(login_res.errors[0].message);
        }

        dispatch(setCallTokenReducer(firstToken));
        dispatch(setAllUsersReducer(login_res.users));

        if (login_res && login_res.users && login_res.users.length > 1) {
          var payload = new FormData();
          payload.append("patient_id", login_res.users[login_res.users.length - 1].id);
          if(is_group_exist == true){
              payload.append("hospital_group_id", currentLocation?.hospital_group_id);
              payload.append("group_id", currentLocation?.hospital_group_id);
          }
          switchPatient(payload, firstToken)
            .then(function (switch_response) {
              let switch_token = switch_response.headers.authorization;
              let switch_res = switch_response.data;

              if (
                switch_response &&
                switch_res &&
                switch_res.errors &&
                switch_res.errors.length > 0
              ) {
                return showError(switch_res.errors[0].message);
              }

              dispatch(setSwitchTokenReducer(switch_token));
              dispatch(setSelectedUserReducer(switch_res.user));

              getProfile(switch_token)
                .then(function (profile_response) {
                  let profile_res = profile_response.data;

                  if (
                    profile_response &&
                    profile_res &&
                    profile_res.errors &&
                    profile_res.errors.length > 0
                  ) {
                    return showError(profile_res.errors[0].message);
                  }

                  if (profile_response && profile_res) {
                    dispatch(setUserProfileReducer(profile_res.user));
                  }
                })
                .catch(function (err) {});
            })
            .catch(function (err) {});
        } else {
          getProfile(firstToken)
            .then(function (profile_response) {
              let profile_res = profile_response.data;

              if (
                profile_res &&
                profile_res.errors &&
                profile_res.errors.length > 0
              ) {
                return showError(profile_res.errors[0].message);
              }

              if (profile_res) {
                dispatch(setSwitchTokenReducer(firstToken));
                dispatch(setSelectedUserReducer(profile_res.user));
              }
            })
            .catch(function (err) {});
        }

        setIsResend(false);
        setIsVerify(false);
        setSucussMessage(true);
        timeLeft(30);
        //    toast.success("Registrated successfully! Please enter OTP to login");
        // window.location.href = '/';
        navigate(`/qr-register`);
      })
      .catch(function (err) {});
  }

  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }

  const backToPage = () => {
    setCurrentOTP("");
    setDOB(null);
    setSucussMessage(false);
  };
  function handleOtpRequest(e) {
    e.preventDefault();
    getOTP(mobile_no, 'phone', currentLocation?.hospital_group_id)
      .then(function (x_response) {
        let x_res = x_response.data;

        if (x_res && x_res.errors) {
          x_res.errors.map((e) => {
            return showError(e.message);
          });
          return false;
        }

        setIsResend(true);
        setTimeLeft(30);
      })
      .catch(function () {});
  }
  const pageURL = window.location.origin + window.location.pathname;
  const [SEO_META, setSEO_META] = useState({
    title: DefaultMetaTag.title,
    description: DefaultMetaTag.description,
    keywords: DefaultMetaTag.keyword,
    pageURL: pageURL,
  });
  return (
    <>
    <Helmet_SEO_META SEO_META={SEO_META} />
      <QrNavBar />
      <div className="min-body-height  container">
        {!isVerify ? (
          <form
            className={`mhc-register-form py-4 ${
              sucussMessage == false ? "d-block" : "d-none"
            }`}
            onSubmit={(e) => registerUpdateHandler(e)}
          >
          <h1 className="fs-4  text-center">Register {hospitalAddressList?.length > 1 ? "at":''}   <HospitalListModal isOpen={true}/></h1>
            <div className="row mt-4">
              <div className="col-12 d-flex justify-content-center">
                <div
                  className="mhc-user-profile"
                  style={{ width: "80px", height: "80px" }}
                >
                  <img src={selectedFile ? selectedFile : userPic} />
                  {selectedFile == undefined ? (
                    <span
                      className="mhc-icon icon-camera cameraIcon"
                      style={{ right: "32px" }}
                    ></span>
                  ) : (
                    ""
                  )}
                  <input
                    type="file"
                    name="profile_picture"
                    id="profile_picture"
                    onChange={(e) => profilePicChangeHandler(e)}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4 mt-lg-0">
              <div className="col-12">
                <h1 className="fs-5 text-start text-md-center text-lg-start">
                  Patient Details
                </h1>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="mhc-selectbox mb-3">
                  <select
                    className="form-select"
                    placeholder="text"
                    id="salutation"
                    name="salutation"
                    tabIndex="1"
                  >
                    <option></option>
                    {salutationList?.map((item) => {
                      return (
                        <option
                          key={item.name}
                          label={item.name}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="salutation">
                    Select Title <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name="first_name"
                    autoComplete="off"
                    placeholder="First name"
                    tabIndex="2"
                  />
                  <label htmlFor="first_name">
                    First Name <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="middle_name"
                    name="middle_name"
                    autoComplete="off"
                    placeholder="Middle name"
                    tabIndex="3"
                  />
                  <label htmlFor="middle_name">Middle Name</label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    autoComplete="off"
                    placeholder="Last name"
                    tabIndex="4"
                  />
                  <label htmlFor="last_name">
                    Last Name <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    maxLength="10"
                    placeholder="Mobile"
                    name="mobile_no"
                    id="mobile_no"
                    autoComplete="off"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    tabIndex="5"
                  />
                  <label htmlFor="mobile_no">
                    Mobile <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="d-flex align-items-center">
                  <div className="form-floating w-75 mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="email"
                      name="email"
                      autoComplete="off"
                      tabIndex="6"
                    />
                    <label htmlFor="email">
                      Email <sup>{emailNaState == false ? "*" : ""}</sup>
                    </label>
                  </div>
                  <div className="form-check w-25 mb-3 ms-3">
                    <input
                      tabIndex="7"
                      className="form-check-input"
                      type="checkbox"
                      id="email_na"
                      name="email_na"
                      onChange={(e) => handleEmailNaChange(e)}
                    />
                    <label
                      className="form-check-label d-block d-sm-inline "
                      htmlFor="email_na"
                    >
                      NA
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-floating mhc-dob date mb-3">
                  <DatePicker
                    name="dob"
                    id="dob"
                    calendarClassName="mhc-input-date"
                    dateFormat="dd/MM/yyyy"
                    selected={DOB}
                    maxDate={new Date()}
                    onChange={(date) => setDOB(date)}
                    calendarStartDay={1}
                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    openToDate={new Date(oneYearFromNow)}
                    adjustDateOnChange
                    autoComplete="off"
                    tabIndex="8"
                  />
                  <i className="mhc-icon icon-calendar"></i>
                  <label
                    htmlFor="Dob"
                    className={`${DOB != null ? "dob" : ""}`}
                  >
                    Date of Birth <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="mhc-selectbox mb-3">
                  <select
                    name="gender"
                    id="gender"
                    className="form-select"
                    // required
                    tabIndex="9"
                  >
                    <option></option>
                    {genderList?.map((item, index) => {
                      return (
                        <option key={item} label={item} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="gender">
                    Gender <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="mhc-selectbox mb-3">
                  <select
                    name="marital_status"
                    id="marital_status"
                    className="form-select"
                    // required
                    tabIndex="10"
                  >
                    <option></option>
                    {maritalStatusList?.map((item, index) => {
                      return (
                        <option key={item} label={item} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="marital_status">Marital Status </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="mhc-selectbox mb-3">
                  <select
                    name="blood_group"
                    id="blood_group"
                    className="form-select"
                    // required
                    tabIndex="11"
                  >
                    <option></option>
                    {bloodGroupList?.map((item, index) => {
                      return (
                        <option key={item} label={item} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="blood_group">Blood Group</label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    maxLength="10"
                    className="form-control"
                    id="emergency_contact"
                    placeholder="emergency Contact"
                    name="emergency_contact"
                    autoComplete="off"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    tabIndex="12"
                  />
                  <label htmlFor="emergency_contact">Emergency Contact</label>
                </div>
              </div>

              <div className="col-12">
                <h1 className="fs-5 text-start text-md-center text-lg-start">
                  Address
                </h1>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="address_1"
                    placeholder="Address Line 1"
                    name="address_1"
                    autoComplete="off"
                    tabIndex="13"
                  />
                  <label htmlFor="address_1">
                    Address Line 1 <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="address_2"
                    placeholder="Address Line 2"
                    name="address_2"
                    autoComplete="off"
                    tabIndex="14"
                  />
                  <label htmlFor="address_2">Address Line 2</label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="mhc-selectbox mb-3">
                  <select
                    name="country"
                    id="country"
                    className="form-select"
                    // value="India" defaultValue={{label:"India", value: "91"}} disabled
                    value="India"
                    disabled
                    tabIndex="15"
                  >
                    {countries?.map((item) => {
                      const { n } = item;
                      return (
                        <option key={n} label={n} value={n}>
                          {n}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="country">
                    Country <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="mhc-selectbox mb-3">
                  <select
                    name="state"
                    id="state"
                    // required
                    className="form-select"
                    onChange={(e) => {
                      onChangeState(e);
                    }}
                    tabIndex="16"
                  >
                    <option></option>
                    {allState?.map((item) => {
                      return (
                        <option
                          key={item.id}
                          label={item.name}
                          value={item.name}
                          id={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="state">
                    State <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="mhc-selectbox mb-3">
                  <select
                    name="city"
                    id="city"
                    className="form-select"
                    // required
                    tabIndex="17"
                  >
                    <option></option>
                    {allCity?.map((item) => {
                      return (
                        <option
                          key={item.name}
                          label={item.name}
                          value={item.name}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="city">
                    City <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="pin_code"
                    placeholder="PIN Code"
                    name="pin_code"
                    autoComplete="off"
                    tabIndex="18"
                    maxLength={6}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <label htmlFor="pin_code">PIN Code</label>
                </div>
              </div>

              <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    // required
                    value={isTerm}
                    onChange={(e) => setIsTerm(e.target.checked)}
                    tabIndex="23"
                  />
                  <label
                    className="form-check-label d-block d-sm-inline"
                    htmlFor="flexCheckDefault"
                  >
                    I have read and agree to the{" "}
                    <a
                      href={TermsPageURL}
                      target="_blank"
                      className="text-primary text-decoration-none"
                    >
                      Terms and Conditions.
                    </a>
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <button
                    className="btn btn-custom rounded-1"
                    type="submit"
                    disabled={!isTerm}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <form className="mhc-register-form verify_otp py-4">
            <div className="row justify-content-center mt-5">
              {/* <div className="col-md-12 mb-5 text-center"><img className='login-logo' src={Logo} /></div> */}
              <div className="col-md-12 mb-3">
                <h3 className="fs-4">Verify OTP</h3>
              </div>

              <div className="col-sm-8 ">
                <div className="d-flex ">
                  <div className="form-floating w-100 mb-3">
                    <input
                      type="text"
                      className="form-control border-radius-right-0 "
                      maxLength="4"
                      value={currentOTP}
                      id="OTP"
                      name="OTP"
                      placeholder="Enter OTP"
                      onChange={(e) => setCurrentOTP(e.target.value)}
                      tabIndex="24"
                    />
                    <label>
                      OTP <sup>*</sup>
                    </label>
                  </div>

                  {isResend ? (
                    <div className="input-group-text border-radius-left-0 border-start-0  custom-text bg-white">
                      <div className="anitClock ">
                        <img
                          src={`${APP_SUB_FOLDER}${antiClock}`}
                          className="anti-img"
                          alt=""
                        />
                        <p className="otp-time">{timeLeft}</p>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={(e) => handleOtpRequest(e)}
                      className="input-group-text border-radius-left-0 border-start-0 text-primary bg-white "
                      disabled={isResend}
                    >
                      Resend OTP
                    </button>
                  )}
                </div>
              </div>
              <div className="col-sm-4 ">
                <button
                  className="btn btn-custom rounded-1"
                  onClick={(e) => verifyOTP(e)}
                >
                  Verify OTP{" "}
                  {spinnerResponse == true ? <SpinnerComponent /> : null}
                </button>
              </div>
            </div>
            <div className="row mt-3 mt-md-0  justify-content-center">
              <div className="col-12">
                <p className="m-0">
                  An OTP has been sent to your mobile number, please enter to
                  verify.
                </p>
              </div>
            </div>
          </form>
        )}

        <div
          className={` ${
            sucussMessage == true
              ? "mhc-Loding-minHeight flex-column"
              : "d-none"
          }`}
        >
          {" "}
          <p className="fs-4 fw-bolder text-primary">
            You have been Registrated with {hospitalAddressList? hospitalAddressList[0].label : ''} successfully.
          </p>
          <button className="btn btn-primary" onClick={() => backToPage()}>
            Ok
          </button>
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default QrRegister;
