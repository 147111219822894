import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Tabs, Tab } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";
import DoctorConsult from "../patientMyBooking/doctorConsult";
import PatientHomeCare from "../patientMyBooking/patientHomeCare";
import PatientDiagnostics from "../patientMyBooking/patientDiagnostics";
import { Link, useNavigate } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import PatientMedicines from "../patientMyBooking/PatientMedicines";
import {
  appointmentCall,
  homeCareCall,
  diagnosticsCall,
  medicinesCall,
  healthcheckups,
} from "../../actions/dashboard-api";
import InfiniteScroll from "react-infinite-scroll-component";
import LodingComponent from "../../component/loding";
import NoMoreData from "../../component/no-more-data/noMoreData";

import moment from "moment";
import DatePicker from "react-datepicker";
import NoDataFounded from "../../component/no-data-found/index";
import DashboardLink from "../../component/dashboard-link";
import Helmet_SEO_META from "../../component/seo_meta";
import { DefaultMetaTag, servicecategory } from "../../utils/constant";
import HealthCheckupsList from "./healthCheckupsList";

const breadcrumArrry = [
  {
    name: "My Dashboard",
    url: "/my-dashboard",
  },
  {
    name: "My Bookings",
    url: "",
  },
];
const PatientMyBooking = () => {
  const switchToken = useSelector((state) => state.switchToken.value);
  const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const [currentTab, setCurrentTab] = useState("DoctorConsult");

  // For Doctor Consult Hooks
  const [appointmentData, setAppointment] = useState();
  const [apppagination, setAppPagination] = useState();
  const [appHasMore, setAppHasMore] = useState(true);
  const [appAppointmentsPage, setAppAppointmentsPage] = useState(1);
  const [appAppointmentsLoader, setAppAppointmentsLoader] = useState(false);

  // For Heatlcheckups List  Hooks
  const [healthCheckupData, setHealthCheckupData] =useState([]) 
  const [healthpagination, setHealthpagination] = useState();
  const [healthAppointmentsPage, setHealthAppointmentsPage] = useState(1);
  const [healthHasMore, setHealthHasMore] = useState(true);
  const [healthAppointmentsLoader, setHealthAppointmentsLoader] = useState(false);

// for Lab Test List Hooks
  const [labCheckupData, setLabCheckupData] =useState([]) 
  const [labpagination, setLabpagination,] = useState();
  const [labAppointmentsPage, setLabAppointmentsPage] = useState(1);
  const [labhHasMore, setLabhHasMore] = useState(true);
  const [labAppointmentsLoader, setLabAppointmentsLoader] = useState(false);

//  for  RadiologyTest list hooks
  const [radiologyData, setRadiologyData] =useState([]) 
  const [radiologypagination, setRadiologypagination] = useState();
  const [radiologyAppointmentsPage, setRadiologyAppointmentsPage] = useState(1);
  const [radiologyhHasMore, setRadiologyhHasMore] = useState(true);
  const [radiologyAppointmentsLoader, setRadiologyAppointmentsLoader] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);




  const allUsers = useSelector((state) => state.allUsers.value);
  const navigate = useNavigate();
  useEffect(() => {
    if (!allUsers && !selectedUserCard) {
      navigate(`/login`);
      return;
    }
  }, []);

  useEffect(() => {
    let isFamilyMember = "";
    if (selectedUserCard) {
      isFamilyMember =
        selectedUserCard.card == "member"
          ? "&family_member_id=" + selectedUserCard.id
          : "";
    }

    setAppointment();
    setHealthCheckupData()
    // For Doctor Consult
    appointmentCall(
      appAppointmentsPage,
      null,
      null,
      isFamilyMember,
      currentLocation.hospital_group_id,
      switchToken
    )
      .then(function (response) {
        setAppPagination(response.data.pagination);
        setAppointment(response.data.appointments);
      })
      .catch(function (err) {
        console.log(err);
      });

    // For HealthCheckup
    healthcheckups(    
      servicecategory[0]?.id,
      healthAppointmentsPage,
      isFamilyMember,
      currentLocation.hospital_group_id,
      currentLocation.hospitalID,
      switchToken
    )
      .then(function (response) {      
        setHealthpagination(response.data.pagination);
        setHealthCheckupData(response.data.appointments);
      })
      .catch(function (err) {
        console.log(err);
      });

          // for lab Data
          healthcheckups(    
            servicecategory[2]?.id,
            labAppointmentsPage,
            isFamilyMember,
            currentLocation.hospital_group_id,
            currentLocation.hospitalID,
            switchToken
          )
            .then(function (response) {              
              setLabpagination(response.data.pagination);
              setLabCheckupData(response.data.appointments);
            })
            .catch(function (err) {
              console.log(err);
            });

        // for    RadiologyTest
        healthcheckups(    
          servicecategory[1]?.id,
          radiologyAppointmentsPage,
          isFamilyMember,
          currentLocation.hospital_group_id,
          currentLocation.hospitalID,
          switchToken
        )
          .then(function (response) {
            //console.log("response", response)
            setRadiologypagination(response.data.pagination);
            setRadiologyData(response.data.appointments);
          })
          .catch(function (err) {
            console.log(err);
          });


  }, [switchToken, selectedUserCard]);



  // For Doctor Consult More Data
  const appFetchMoreData = () => {
    if (
      currentTab === "DoctorConsult" &&
      appAppointmentsPage < apppagination.total_pages
    ) {
      setAppAppointmentsLoader(true);
      let nextPage;
      nextPage = appAppointmentsPage + 1;

      let getStartDate = moment(startDate).format("YYYY-MM-DD");
      let getEndDate = moment(endDate).format("YYYY-MM-DD");

      let isFamilyMember =
        selectedUserCard.card == "member"
          ? "&family_member_id=" + selectedUserCard.id
          : "";
      setAppAppointmentsPage(nextPage);
      appointmentCall(
        nextPage,
        null,
        null,
        isFamilyMember,
        currentLocation.hospital_group_id,
        switchToken
      ).then(function (response) {
        setAppointment(appointmentData.concat(response.data.appointments));
        setAppAppointmentsLoader(false);
      });
    } else {
      setAppHasMore(false);
    }
  };

 const HealthLoadMore=()=>{
  if (
    currentTab === "HealthCheckups" &&
    healthCheckupData < healthpagination.total_pages
  ) {
    setHealthAppointmentsLoader(true);
    let nextPage;
    nextPage = healthAppointmentsPage + 1;



    let isFamilyMember =
      selectedUserCard.card == "member"
        ? "&family_member_id=" + selectedUserCard.id
        : "";
        setHealthAppointmentsPage(nextPage);
        healthcheckups(    
          servicecategory[0]?.id,
          nextPage,
          isFamilyMember,
          currentLocation.hospital_group_id,
          currentLocation.hospitalID,
          switchToken
        )
          .then(function (response) {           
            setHealthCheckupData(healthCheckupData.concat(response.data.appointments));
            setHealthAppointmentsLoader(false);
          });
          } else {
     setHealthHasMore(false);
  }
};
 
 const getLabLoadMore=()=>{
  if (
    currentTab === "LabTest" &&
    labCheckupData < labpagination.total_pages
  ) {
    setLabAppointmentsLoader(true);
    let nextPage;
    nextPage = labAppointmentsPage + 1;



    let isFamilyMember =
      selectedUserCard.card == "member"
        ? "&family_member_id=" + selectedUserCard.id
        : "";
        setLabAppointmentsPage(nextPage);
        healthcheckups(    
          servicecategory[2]?.id,
          nextPage,
          isFamilyMember,
          currentLocation.hospital_group_id,
          currentLocation.hospitalID,
          switchToken
        )
          .then(function (response) {           
            setLabCheckupData(labCheckupData.concat(response.data.appointments));
            setLabAppointmentsLoader(false);
          });
          } else {
            setLabhHasMore(false);
  }
 }


 const getRadiologyhLoadMore=()=>{

 }

  const handleDateApi = (date) => {
    setStartDate(date);
    setEndDate(date);
  };
  const handleDateApi1 = (date) => {
    setEndDate(date);
  };

  const getFilterData = () => {
    let getStartDate = moment(startDate).format("YYYY-MM-DD");
    let getEndDate = moment(endDate).format("YYYY-MM-DD");
    setAppointment();
    setAppAppointmentsLoader(false);
    let isFamilyMember =
      selectedUserCard.card == "member"
        ? "&family_member_id=" + selectedUserCard.id
        : "";
    appointmentCall(
      appAppointmentsPage,
      getStartDate,
      getEndDate,
      isFamilyMember,
      currentLocation.hospital_group_id,
      switchToken
    ).then(function (response) {
      setAppointment(response.data.appointments);
    });
  };

  const refreshData = () => {
    setStartDate();
    setEndDate();
    setAppointment();
    setAppAppointmentsLoader(false);
    setAppAppointmentsPage(1);
    let isFamilyMember =
      selectedUserCard.card == "member"
        ? "&family_member_id=" + selectedUserCard.id
        : "";
    appointmentCall(
      appAppointmentsPage,
      null,
      null,
      isFamilyMember,
      currentLocation.hospital_group_id,
      switchToken
    ).then(function (response) {
      setAppointment(response.data.appointments);
    });
  };


  const pageURL = window.location.origin + window.location.pathname;
  const [SEO_META, setSEO_META] = useState({
    title: DefaultMetaTag.title,
    description: DefaultMetaTag.description,
    keywords: DefaultMetaTag.keyword,
    pageURL: pageURL,
  });

  return (
    <React.Fragment>
      <Helmet_SEO_META SEO_META={SEO_META} />
      <section>
        <Container className="min-body-height">
          <div className="row  align-items-center breadcrumb_sticky">
            <div className="col-md-9">
              {" "}
              <Breadcrumb textUrl={breadcrumArrry} />{" "}
            </div>
            <div className="col-md-3 d-none d-md-block">
              <DashboardLink />
            </div>
          </div>
          <SwitchProfile />

          <div className="row mt-4">
            <div className="col-12">
              <h1 className="fs-4 mb-1 text-secondary">My Bookings</h1>
              <p className="m-0 fs-6">
                View your upcoming or previous appointments
              </p>
            </div>
          </div>
          <div className="mhc-tabs position-relative mt-4">
            <Tabs
              activeKey={currentTab}
              onSelect={(key) => setCurrentTab(key)}
              id="controlled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="DoctorConsult" title="Doctor Consult">
                <div className="row mt-3 d-none">
                  <div className="col-lg-3">
                    <div className="d-flex flex-column flex-lg-row">
                      <div className="form-floating mhc-dob date mb-4">
                        <DatePicker
                          calendarClassName="mhc-input-date"
                          dateFormat="dd/MM/yyyy"
                          selected={startDate}
                          onChange={(date) => handleDateApi(date)}
                          // minDate={moment().toDate()}
                          maxDate={moment().toDate()}
                          calendarStartDay={1}
                          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                          placeholderText={new Date()}
                        />
                        <i className="mhc-icon icon-calendar"></i>
                        <label htmlFor="Dob" className="dob">
                          Start Date
                        </label>
                      </div>
                      <div className="form-floating mhc-dob date mb-4 ms-0 ms-lg-3">
                        <DatePicker
                          calendarClassName="mhc-input-date"
                          dateFormat="dd/MM/yyyy"
                          selected={endDate}
                          onChange={(date) => handleDateApi1(date)}
                          minDate={startDate}
                          // maxDate={moment().toDate()}
                          calendarStartDay={1}
                          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                          placeholderText={new Date()}
                        />
                        <i className="mhc-icon icon-calendar"></i>
                        <label htmlFor="Dob" className="dob">
                          End Date
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="d-flex flex-column flex-lg-row">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => getFilterData()}
                      >
                        Go
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary ms-2"
                        onClick={() => refreshData()}
                      >
                        Refresh
                      </button>
                    </div>
                  </div>
                </div>
                {appointmentData?.length == 0 ? (
                  <h6 className="text-center mt-2 mb-4 ">
                    It seems like you do not have any doctor consult/services
                    booked with us.
                  </h6>
                ) : appointmentData === undefined ? (
                  <LodingComponent className="mhc-Loding-minHeight" />
                ) : (
                  <div id="scrollableDiv" className="mhc-scrollDiv">
                    <InfiniteScroll
                      dataLength={appointmentData.length}
                      next={appFetchMoreData}
                      hasMore={appHasMore}
                      loader={
                        appAppointmentsLoader == true ? (
                          <LodingComponent className="text-center" />
                        ) : (
                          ""
                        )
                      }
                      scrollableTarget="scrollableDiv"
                      endMessage={
                        apppagination.total_pages > 1 ? (
                          <NoMoreData className="text-center mb-3" />
                        ) : (
                          ""
                        )
                      }
                    >
                      {<DoctorConsult dectorConsultData={appointmentData} />}
                    </InfiniteScroll>
                  </div>
                )}
              </Tab>

              <Tab eventKey="HealthCheckups" title="Health Checkups">
              {healthCheckupData?.length == 0 ? (
                  <h6 className="text-center mt-2 mb-4 ">
                    It seems like you do not have any Health Checkup Test
                  </h6>
                ) : healthCheckupData === undefined ? (
                  <LodingComponent className="mhc-Loding-minHeight" />
                ) : (
                  <div id="scrollableDiv" className="mhc-scrollDiv">
                    <InfiniteScroll
                      dataLength={healthCheckupData.length}
                      next={HealthLoadMore}
                      hasMore={healthHasMore}
                      loader={
                        healthAppointmentsLoader == true ? (
                          <LodingComponent className="text-center" />
                        ) : (
                          ""
                        )
                      }
                      scrollableTarget="scrollableDiv"
                      endMessage={
                        healthpagination.total_pages > 1 ? (
                          <NoMoreData className="text-center mb-3" />
                        ) : (
                          ""
                        )
                      }
                    >
                        <HealthCheckupsList data={healthCheckupData} />
                    </InfiniteScroll>
                  </div>
                )}
  
            
              </Tab>
              <Tab eventKey="LabTest" title="Lab Tests">
              {labCheckupData?.length == 0 ? (
                  <h6 className="text-center mt-2 mb-4 ">
                    It seems like you do not have any Lab Test
                  </h6>
                ) : labCheckupData === undefined ? (
                  <LodingComponent className="mhc-Loding-minHeight" />
                ) : (
                  <div id="scrollableDiv" className="mhc-scrollDiv">
                    <InfiniteScroll
                      dataLength={labCheckupData.length}
                      next={getLabLoadMore}
                      hasMore={labhHasMore}
                      loader={
                        labAppointmentsLoader == true ? (
                          <LodingComponent className="text-center" />
                        ) : (
                          ""
                        )
                      }
                      scrollableTarget="scrollableDiv"
                      endMessage={
                        labpagination.total_pages > 1 ? (
                          <NoMoreData className="text-center mb-3" />
                        ) : (
                          ""
                        )
                      }
                    >
                        <HealthCheckupsList data={labCheckupData} />
                    </InfiniteScroll>
                  </div>
                )}
              </Tab>
              <Tab eventKey="RadiologyTest" title="Radiology Tests">
              {radiologyData?.length == 0 ? (
                  <h6 className="text-center mt-2 mb-4 ">
                    It seems like you do not have any Radiology Test
                  </h6>
                ) : radiologyData === undefined ? (
                  <LodingComponent className="mhc-Loding-minHeight" />
                ) : (
                  <div id="scrollableDiv" className="mhc-scrollDiv">
                    <InfiniteScroll
                      dataLength={radiologyData.length}
                      next={getRadiologyhLoadMore}
                      hasMore={radiologyhHasMore}
                      loader={
                        radiologyAppointmentsLoader == true ? (
                          <LodingComponent className="text-center" />
                        ) : (
                          ""
                        )
                      }
                      scrollableTarget="scrollableDiv"
                      endMessage={
                        radiologypagination.total_pages > 1 ? (
                          <NoMoreData className="text-center mb-3" />
                        ) : (
                          ""
                        )
                      }
                    >
                        <HealthCheckupsList data={radiologyData} />
                    </InfiniteScroll>
                  </div>
                )}
           </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default PatientMyBooking;
