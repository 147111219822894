import React, { useState, useEffect } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";

import { Link, useNavigate } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import PatientMyPrescriptionsDectorConsult from "../patientMyPrescriptions/patientMyPrescriptionsDectorConsult";
import PatientMyPrescriptionsHomeCare from "../patientMyPrescriptions/patientMyPrescriptionsHomeCare";
import { myPrescriptionHomecareCall } from "../../actions/dashboard-api";
import { myPrescriptionDocConsultCall } from "../../actions/dashboard-api";
import InfiniteScroll from "react-infinite-scroll-component";
import LodingComponent from "../../component/loding";
import NoMoreData from "../../component/no-more-data/noMoreData";
import { useSelector, useDispatch } from "react-redux";
import DashboardLink from "../../component/dashboard-link";
import { APP_SUB_FOLDER, DefaultMetaTag } from "../../utils/constant";
import Helmet_SEO_META from "../../component/seo_meta";
const breadcrumArrry = [
  {
    name: "My Dashboard",
    url: "/my-dashboard",
  },
  {
    name: "My Prescriptions",
    url: "",
  },
];
const PatientMyprescriptions = () => {
  const allUsers = useSelector((state) => state.allUsers.value);
  const navigate = useNavigate();
  useEffect(() => {
    if (!allUsers && !selectedUserCard) {
      navigate(`/login`);
      return;
    }
  }, []);

  const switchToken = useSelector((state) => state.switchToken.value);
  const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const [myHomecare, setMyHomecare] = useState([]);
  const [pagination, setPagination] = useState();
  const [hasMore, setHasMore] = useState(true);
  let [myHomecarePage, setMyHomecarePage] = useState(1);
  const [myHomecareLoader, setMyHomecareLoader] = useState(false);

  const [myDocConsult, setMyDocConsult] = useState();
  const [docpagination, setDocPagination] = useState();
  const [dochasMore, setDocHasMore] = useState(true);
  let [myDocConsultPage, setMyDocConsultPage] = useState(1);
  const [myDocConsultLoader, setMyDocConsultLoader] = useState(false);

  const [currentTab, setCurrentTab] = useState("DoctorConsult");

  useEffect(() => {
    let isFamilyMember = "";
    if (selectedUserCard) {
      isFamilyMember =
        selectedUserCard.card == "member"
          ? "&family_member_id=" + selectedUserCard.id
          : "";
    }
    setMyHomecare();
    setMyDocConsult();
    myPrescriptionHomecareCall(
      myHomecarePage,
      isFamilyMember,
      currentLocation.hospital_group_id,
      switchToken
    )
      .then(function (response) {
        setTimeout(() => {
        setPagination(response.data.pagination);
        setMyHomecare(response.data.reports);
      }, "500");
      })
      .catch(function (err) {
        console.log(err);
      });

    myPrescriptionDocConsultCall(
      myDocConsultPage,
      isFamilyMember,
      currentLocation.hospital_group_id,
      switchToken
    )
      .then(function (response) {
        setTimeout(() => {
        setDocPagination(response.data.pagination);
        setMyDocConsult(response.data.reports);
      }, "500");
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [switchToken, selectedUserCard, currentLocation]);

  const fetchMoreData = () => {
    setMyHomecareLoader(true);
    let nextPage;
    if (currentTab === "HomeCare" && myHomecarePage < pagination.total_pages) {
      nextPage = myHomecarePage + 1;
      setMyHomecarePage(nextPage);
      //console.log(">>next page", nextPage)

      myPrescriptionHomecareCall(
        nextPage,
        currentLocation.hospital_group_id,
        switchToken
      )
        .then(function (response) {
          setMyHomecare(myHomecare.concat(response.data.reports));
          setMyHomecareLoader(false);
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      setHasMore(false);
    }
  };

  const fetchDocConsultData = () => {
    setMyDocConsultLoader(true);
    let docNextPage;

    if (
      currentTab === "DoctorConsult" &&
      myDocConsultPage < docpagination.total_pages
    ) {
      docNextPage = myDocConsultPage + 1;
      setMyDocConsultPage(docNextPage);

      myPrescriptionDocConsultCall(
        docNextPage,
        currentLocation.hospital_group_id,
        switchToken
      )
        .then(function (response) {
          setMyDocConsult(myDocConsult.concat(response.data.reports));
          setMyDocConsultLoader(false);
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      setDocHasMore(false);
    }
  };
  const pageURL = window.location.origin + window.location.pathname;
  const [SEO_META, setSEO_META] = useState({
    title: DefaultMetaTag.title,
    description: DefaultMetaTag.description,
    keywords: DefaultMetaTag.keyword,
    pageURL: pageURL,
  });

  return (
    <React.Fragment>
      <Helmet_SEO_META SEO_META={SEO_META} />
      <Container className="min-body-height">
        <div className="row  align-items-center breadcrumb_sticky">
          <div className="col-md-9">
            <Breadcrumb textUrl={breadcrumArrry} />
          </div>
          <div className="col-md-3 d-none d-md-block">
            <DashboardLink />
          </div>
        </div>
        <SwitchProfile />

        <div className="row mt-4">
          <div className="col-12">
            <h1 className="fs-4 mb-1 text-secondary">My Prescriptions</h1>
            <p className="m-0 fs-6">
              My Prescriptions helps save time and manage your health
            </p>
          </div>
        </div>
        <div className="mhc-tabs position-relative mt-4">
          {myDocConsult?.length == 0 ? (
            <h6 className="text-center mt-2 mb-4">
              It seems like you do not have any prescriptions to display.
            </h6>
          ) : myDocConsult === undefined ? (
            <LodingComponent className="mhc-Loding-minHeight" />
          ) : (
            <div id="scrollableDiv" className="mhc-scrollDiv">
              <InfiniteScroll
                dataLength={myDocConsult.length}
                next={fetchDocConsultData}
                hasMore={dochasMore}
                loader={
                  myDocConsultLoader == true ? (
                    <LodingComponent className="text-center" />
                  ) : (
                    ""
                  )
                }
                endMessage={
                  docpagination.total_pages > 1 ? (
                    <NoMoreData className="text-center mb-3" />
                  ) : (
                    ""
                  )
                }
                scrollableTarget="scrollableDiv"
              >
                {
                  <PatientMyPrescriptionsDectorConsult
                    docConsultList={myDocConsult}
                  />
                }
              </InfiniteScroll>
            </div>
          )}
          {/* <Tabs
                        defaultActiveKey="DoctorConsult"
                        id="uncontrolled-tab-example"
                        className="mb-3">
                        <Tab eventKey="DoctorConsult" title="Doctor Consult">
                            {
                            myDocConsult?.length == 0 ? <h6 className="text-center mt-2 mb-4">No Data Found</h6> :
                            myDocConsult === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> : <div id="scrollableDiv" className="mhc-scrollDiv"><InfiniteScroll
                                dataLength={myDocConsult.length}
                                next={fetchDocConsultData}
                                hasMore={dochasMore}
                                loader={myDocConsultLoader == true ? <LodingComponent className="text-center" /> : ''}
                                endMessage={docpagination.total_pages > 1 ? <NoMoreData className="text-center mb-3" /> : ''}
                                scrollableTarget="scrollableDiv"

                            >
                                {
                                    <PatientMyPrescriptionsDectorConsult docConsultList={myDocConsult} />
                                }

                            </InfiniteScroll>
                            </div>
                            }
                        </Tab>
                        <Tab eventKey="HomeCare" title="Homecare">
                            {
                            myHomecare?.length == 0 ? <h6 className="text-center mt-2 mb-4">No Data Found</h6> : 
                            myHomecare === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> : <div id="scrollableDiv1" className="mhc-scrollDiv"><InfiniteScroll
                                 dataLength={myHomecare.length}
                                 next={fetchMoreData}
                                 hasMore={hasMore}
                                 loader={myHomecareLoader == true ? <LodingComponent className="text-center" /> : ''}
                                 endMessage={pagination.total_pages > 1 ? <NoMoreData className="text-center mb-3" /> : ''}
                                scrollableTarget="scrollableDiv1"

                            >
                                {
                                    <PatientMyPrescriptionsHomeCare homecareList={myHomecare} />
                                }

                            </InfiniteScroll>
                            </div>
                            }


                        </Tab>
                    </Tabs> */}
        </div>
      </Container>
    </React.Fragment>
  );
};
export default PatientMyprescriptions;
