import React, { useState, useEffect, useLayoutEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import BookPackage from "./package";
import BookTest from "./test";

import ServiceAvailability from "../../component/serviceavailability/index";
import SearchTextWidget from "../../component/search/search-text-widget";
import RequestCallback from "../../component/request-callback/requestCallback";
import { clearVendorReducer } from "../../redux/slice/vendor";
import { useDispatch, useSelector } from "react-redux";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import {
  APP_SUB_FOLDER,
  DefaultMetaTag,
  site_SEO_META,
} from "../../utils/constant";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import Helmet_SEO_META from "../../component/seo_meta";
import DashboardLink from "../../component/dashboard-link";
import CarePackageCategories from "../care-package/carePackageCategories";
import HomecareCategory from "../homecareService/homecareCategory";
import RadiologyTest from "../radiology-test";

const BookPackageOrTest = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [pincode, setPincode] = useState("");
  const [serviceable, setServiceable] = useState(false);
  const [callingType, setCallingType] = useState("package");
  const [loading, setLoading] = useState(false);
  const [testType, settestType] = useState("1");
  const [keyword, setKeyword] = useState("");
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
  
  const navigate = useNavigate();
  useEffect(() => {
    if (!allUsers && !selectedUserCard) {
      navigate('/login');
      return;
    }
  },[]);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    setPincode("121102");
  });

  useEffect(async () => {
    let cType =
      location.pathname === `${APP_SUB_FOLDER}/book-package-or-test/test`
        ? "tests"
        : "package";
    setCallingType(cType);
  }, []);

  const breadCrumbData = [
    { type: 1, url: `/`, name: "Home" },
    { type: 1, url: "/my-dashboard", name: "My Dashboard" },
    {
      type: 3,
      url: "",
      name: callingType === "tests" ? "Tests" : "Book Lab/ Radiology",
    },
  ];
  const pageURL = window.location.origin + window.location.pathname;
  const [SEO_META, setSEO_META] = useState({
    title: DefaultMetaTag.title,
    description: DefaultMetaTag.description,
    keywords: DefaultMetaTag.keyword,
    pageURL: pageURL,
  });

  return (
    <>
      <Helmet_SEO_META SEO_META={SEO_META} />
      <section>
        <div className="container min-body-height">
          <div className="row align-items-center">
            <div className="col-md-9">
              {/* <Breadcrumb textUrl={breadcrumArrry} /> */}
              <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
            </div>
            <div className="col-md-3 d-none d-md-block">
              <DashboardLink />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">Diagnostics</h1>
              <p className="m-0 fs-6">Book Package &amp; Test</p>
            </div>
            {/* <div className="col-lg-6 mt-4 mt-lg-0">
              <SearchTextWidget
                placeHolder={`Search ${callingType}`}
                btnDisplay={true}
                setKeyword={setKeyword}
              />
            </div> */}
          </div>
          <div className="mhc-tabs position-relative mt-3 mt-lg-4">
            <div className="row justify-content-between">
              <div className="col-auto">
                {/* <RequestCallback position={true} /> */}
              </div>
              <div className="col-auto d-none">
                <ServiceAvailability
                  pincode={pincode}
                  serviceable={serviceable}
                  setPincode={(pincode) => setPincode(pincode)}
                  setServiceable={setServiceable}
                  setLoading={setLoading}
                />
              </div>
            </div>
            <Tabs
              defaultActiveKey="Pathology"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="Pathology" title="Pathology">
                <ul className="list-unstyled mb-0 mt-lg-0 d-flex align-items-center">
                  <li>
                    <div className="form-check  ms-0 pt-0 pt-sm-1 d-none">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        name="flexRadioDefault"
                        id="Tests"
                        value="0"
                        checked={testType === "0"}
                        onChange={(e) => settestType(e.target.value)}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="Tests"
                      >
                        Tests
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check ms-0 ms-sm-0 pt-0 pt-sm-1">
                      <input
                        className="form-check-input cursor-pointer"
                        type="radio"
                        name="Packages"
                        id="Packages"
                        value="1"
                        checked={testType === "1"}
                        onChange={(e) => settestType(e.target.value)}
                      />
                      <label
                        className="form-check-label cursor-pointer"
                        htmlFor="Packages"
                      >
                        Packages
                      </label>
                    </div>
                  </li>
                </ul>

                {testType === "1" ? (
                  <BookPackage                   
                    keyword={keyword}
                  />
                ) : (
                  <BookTest
                    pincode={pincode}
                    serviceable={serviceable}
                    type={callingType === "tests" ? 1 : 0}
                    keyword={keyword}
                  />
                )}
              </Tab>
              <Tab eventKey="Radiology" title="Radiology">
                <RadiologyTest />
              </Tab>
              {/* <Tab eventKey="Cardiology" title="Cardiology">
                <CarePackageCategories />
              </Tab> */}
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookPackageOrTest;

{
  /* <Tabs activeKey={callingType} className="mb-3" onSelect={(k) => {
							setCallingType(k);
							setKeyword("");
							// dispatch(clearVendorReducer());
							window.history.replaceState(
								{},
								document.getElementsByTagName('title')[0].innerHTML,
								`${APP_SUB_FOLDER}/book-package-or-test/${k === 'tests' ? 'test' : 'packages'}`
							);
						}}>
							<Tab eventKey="package" title="Packages">
								<BookPackage
									pincode={pincode}
									serviceable={serviceable}
									type={callingType === "package" ? 2 : 0}
									keyword={keyword}
								/>

							</Tab>
							<Tab eventKey="tests" title="Tests">
								<div className="mt-0 mt-lg-3">
									<BookTest
										pincode={pincode}
										serviceable={serviceable}
										type={callingType === "tests" ? 1 : 0}
										keyword={keyword}
									/>
								</div>
							</Tab>
						</Tabs> */
}
