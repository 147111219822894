import React from "react";
import { Link } from "react-router-dom";

import SearchIcon from "../../assets/images/mhc-search-icon.svg";

const Iprops = {
  placholder: "",

};

function SearchForm (props=Iprops){


    return (
      <React.Fragment>
        <div className="Mhc-search w-100">
          <div className="input-group ">
            {/* <span className="input-group-text"><i className="mhc-icon  icon-search-icon"></i></span> */}
            <span className="input-group-text">
              <img src={SearchIcon} alt="" />
            </span>
            <input
              type="text"
              onKeyUp={() => this.handledropDown()}       

              className="form-control"
            
              placeholder="Search Specialities, Doctors"
            />
          </div>
         
         </div>
      </React.Fragment>
    );
  }


export default SearchForm;
