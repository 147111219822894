import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { getDayNumber } from "../../constants/app-constants";
function getDayName(dateStr, locale) {
  var date = new Date(dateStr);
  return date.toLocaleDateString(locale, { weekday: "long" });
}
const SelectDateTime = ({
  selectedDay,
  setSelectedDay,
  minDate,
  availableDays = null,
}) => {


  const [defaultDate, setDefaultDate] = useState(selectedDay);
  const [isTodayDateAvailable, setIsTodayDateAvailable] = useState(false);
  const [availabilityFound, setAvailibilityFound] = useState(false);
  
  let maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 3);

   
  let somedate = null;
  const isWeekday = (date) => {
    if (Array.isArray(availableDays)) {
      //
      let isAvailable =
        availableDays && availableDays.includes(getDayName(date));
      //

      if (
        selectedDay.getDate() === date.getDate() &&
        !isTodayDateAvailable &&
        isAvailable
      ) {
        somedate = "found";

        setDefaultDate(date);
        setIsTodayDateAvailable(true);
        setAvailibilityFound(true);
      } else if (
        isAvailable &&
        !availabilityFound &&
        !somedate &&
        defaultDate.getDate() !== date.getDate()
      ) {
        somedate = date;
        setSelectedDay(date);
        setDefaultDate(date);
        setAvailibilityFound(true);
        setIsTodayDateAvailable(false);
      }
      return isAvailable;
    } else {
      return true;
    }
  };
  return (
    <React.Fragment>
      <ul className="list-unstyled d-flex flex-wrap mb-0 mt-3">
        <li>
          <div className="mhc-slot-avability d-flex justify-content-center">
            <div className="mhc-bg Available"></div>
            <div className="mhc-text">Available</div>
          </div>
        </li>
        <li>
          <div className="mhc-slot-avability d-flex justify-content-center ms-3">
            <div className="mhc-bg Booked"></div>
            <div className="mhc-text">Booked</div>
          </div>
        </li>
        <li>
          <div className="mhc-slot-avability d-flex justify-content-center ms-3">
            <div className="mhc-bg Selected"></div>
            <div className="mhc-text">Selected</div>
          </div>
        </li>
      </ul>
      <div className="mhc-calendar mt-4">
        <DatePicker
          selected={isTodayDateAvailable ? selectedDay : defaultDate}
          showToday
          onChange={(date) => {
            setDefaultDate(date);
            setSelectedDay(date);
          }}
          minDate={minDate}
          maxDate={maxDate}
          inline
          dateFormat="DD/MM/YYYY"
          calendarStartDay={0}
          filterDate={isWeekday}
          // formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
          calendarClassName="customDatePickerWidth"
        
        />
      </div>
    </React.Fragment>
  );
};

export default SelectDateTime;
