import React from "react";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";
import { sitename, site_SEO_META, Logo } from "../../utils/constant";
// import thumbnail from "../../assets/images/thumbnail";

const Helmet_SEO_META =({SEO_META})=>{ 

return (
    <>
    {site_SEO_META === true ?
        <Helmet
            title={SEO_META.title}
            meta ={[
                {
                    name: 'description',
                    content: SEO_META.description,
                },
                {
                    name: 'keywords',
                    content: SEO_META.keywords,
                },
                {
                    name: 'robots',
                    content:"index, follow",
                },
                {
                    property: 'og:locale',
                    content: 'en_US'
                },
                {
                    property: 'og:type',
                    content: 'article'
                },
                {
                    property: 'og:image',
                    content: Logo
                },
                {
                    property: 'og:title',
                    content: SEO_META.title
                },
                {
                    property: 'og:description',
                    content: SEO_META.description
                },
                {
                    property: 'og:keywords',
                    content: SEO_META.keywords
                },
                {
                    property: 'og:url',
                    content: SEO_META.pageURL
                },
                {
                    property: 'og:site_name',
                    content: sitename + ' Patient Portal'
                },
                {
                    property: 'twitter:card',
                    content: SEO_META.description
                },
                {
                    property: 'twitter:url',
                    content: SEO_META.pageURL
                },
                {
                    property: 'twitter:title',
                    content: SEO_META.title
                },
                {
                    property: 'twitter:description',
                    content: SEO_META.description
                },
                {
                    property: 'twitter:image',
                    content: Logo
                }
            ]}
            script={[
                helmetJsonLdProp(
                    {
                        "@context": "http://schema.org",
                        "@type": "MedicalOrganization",
                        "name": sitename,
                        "description": SEO_META.description,
                        "logo": Logo,
                        "url": SEO_META.pageURL,
                        "sameAs": ["https://www.facebook.com/WoodlandsHospitalKolkata/"],
                        "image":Logo})
            ]}>
            <link rel="canonical" href={SEO_META.pageURL} />
        </Helmet>
        :
        <Helmet>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
    }
    </>    
)}
export default Helmet_SEO_META