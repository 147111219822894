import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb/index";
import { Link, useHistory, useParams } from "react-router-dom";
import { doctorAvailability, doctorTimeSlots } from "../../actions/get-calls";
import { specialityInfoById } from "../../actions/get-calls";
import Doctors from "./doctors";
import { useSelector } from "react-redux";
import LodingComponent from "../../component/loding";
import DashboardLink from "../../component/dashboard-link";

import SearchTextWidget from "../../component/search/search-text-widget";
import { defaultConsultType, DefaultMetaTag, showConsultType } from "../../utils/constant";
import Helmet_SEO_META from "../../component/seo_meta";
import HospitalListModal from "../../component/hospital-list-modal";
export default function ConsultDoctor() {
  const { specialities, Id, sId } = useParams();
  const [filterModal, setFilterModal] = useState(false);
  const [doctor, setDoctor] = useState([]);
  const [noDoctor, setNoDoctor] = useState(false);
  const [specialInfo, setSpecialInfo] = useState();
  const [consultType, setConsultType] = useState(defaultConsultType);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [tempKeyword, setTempKeyoword] = useState("");
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const searchButtonClick = () => {
    if (keyword.length > 3) {
      setTempKeyoword(keyword);
    } else if (keyword.length == 0) {
      setTempKeyoword(keyword);
    }
  };

  useEffect(async () => {
    getDoctors();
    const specials = await specialityInfoById(sId);
    setSpecialInfo(specials.data);
  }, []);

  useEffect(async () => {
    if (tempKeyword.length < 3) return false;
    getDoctors();
  }, [tempKeyword]);

  useEffect(() => {
    if (tempKeyword.length === 0) {
      getDoctors();
    }
  }, [tempKeyword]);

  function getDoctors() {
    const delayDebounceFn = setTimeout(async () => {
      setNoDoctor(false);
      setLoading(true);
      const doctors = await doctorAvailability(
        sId,
        Id,
        keyword.length >= 3 ? keyword : ""
      );

      const doctorsData = [...doctors.data.doctors].sort((a, b) => {
        // return (a.availability === null)-(b.availability === null) || -(a.availability>b.availability)||+(a.availability<b.availability)
        return (
          (a.availability === null) - (b.availability === null) ||
          -(a.availability > b.availability) ||
          +(a.availability < b.availability)
        );
      });
      // setDoctor(doctorsData);

      let filtered = doctorsData.filter(function (value) {
        return value.availability.length > 0;
      });

      if (filtered.length == 0) {
        setNoDoctor(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
      setDoctor(filtered);

      // setDoctor(doctors.data.doctors);
      // setLoading(false);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }

  const handleToggle = () => {
    setFilterModal(!filterModal);
  };

  if (specialInfo !== undefined) {
    var breadcrumArrry = [
      {
        name: `${specialInfo.speciality.name}`,
        url: "/",
      },
      {
        name: "Doctors",
        url: "",
      },
    ];
  }
  const pageURL = window.location.origin + window.location.pathname;
  const [SEO_META, setSEO_META] = useState({
    title: DefaultMetaTag.title,
    description: DefaultMetaTag.description,
    keywords: DefaultMetaTag.keyword,
    pageURL: pageURL,
  });

  return (
    <React.Fragment>
      <Helmet_SEO_META SEO_META={SEO_META} />
      <section>
        <Container className="min-body-height">
          <div className="row align-items-center">
            <div className="col-md-9">
              <Breadcrumb textUrl={breadcrumArrry} />{" "}
            </div>
            <div className="col-md-3 d-none d-md-block">
              <DashboardLink />
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">Book Consult <HospitalListModal isOpen={false}/></h1>
              <p className="m-0 fs-6">
                Consult with top doctors across specialities
              </p>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              <SearchTextWidget
                placeHolder={`Search doctors `}
                btnDisplay={false}
                setKeyword={setKeyword}
                searchClickHandler={searchButtonClick}
              />
            </div>
          </div>
          {showConsultType === true ? (
            <div className="mhc-consult-type d-flex bd-highlight flex-row align-items-start  align-items-lg-center position-relative mt-4 ">
              <div className="order-0 order-lg-0">
                <span className="fw-bold ">Consult Type: </span>
              </div>
              <div className="order-2 order-lg-0">
                <ul className="list-unstyled mb-0 mt-lg-0 d-flex align-items-center">
                  <li>
                    <div className="form-check  ms-0 ms-3 pt-0 pt-sm-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="All"
                        value="0"
                        checked={consultType === "0"}
                        onChange={(e) => setConsultType(e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="All">
                        All
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check ms-3 ms-sm-4 pt-0 pt-sm-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={
                          currentLocation?.type === "hospital"
                            ? "Hospital"
                            : "Clinic"
                        }
                        id="Hospital"
                        value="2"
                        checked={consultType === "2"}
                        onChange={(e) => setConsultType(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={
                          currentLocation?.type === "hospital"
                            ? "Hospital"
                            : "Clinic"
                        }
                      >
                        <span>At </span>
                        {currentLocation?.type === "hospital"
                          ? "Hospital"
                          : "Clinic"}
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check ms-3 ms-sm-4 pt-0 pt-sm-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="Virtual"
                        value="1"
                        checked={consultType === "1"}
                        onChange={(e) => setConsultType(e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="Virtual">
                        Virtual
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="mt-4 ">
              <span className="fw-bold ">Consult Type: At Hospital</span>
            </div>
          )}

          <div className="row mt-4">
            {loading === true ? (
              <LodingComponent className="mhc-Loding-minHeight" />
            ) : (
              <Doctors
                doctors={doctor}
                consultType={consultType}
                specialities={specialities}
                sId={sId}
                Id={Id}
              />
            )}

            {noDoctor === true ? (
              <p className="text-center fs-6 text-secondary fw-bold mt-5">
                There are no doctors available for the selected specialty at
                present. Please call{" "}
                <a
                  className="text-primary text-decoration-none"
                  href={`tel:${currentLocation.primary_contact}`}
                >
                  {currentLocation.primary_contact}
                </a>{" "}
                for assistance.
              </p>
            ) : null}
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
}
