import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Tabs, Tab } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";

import { Link, useNavigate } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import { externalDocumentsCall, myAppointmentsCall, PatientMyBillsCall } from "../../actions/dashboard-api";
import InfiniteScroll from 'react-infinite-scroll-component';
import LodingComponent from "../../component/loding";
import NoMoreData from "../../component/no-more-data/noMoreData";

import { configurations } from "../../actions/get-calls";
import { ToastContainer, toast } from 'react-toastify';

import PatientMyBillsListing from "../patientMyBillsListing";
import DashboardLink from "../../component/dashboard-link";
import { is_group_exist } from "../../utils/constant";
const breadcrumArrry=[
    {
      name:'My Dashboard',
      url:'/my-dashboard'
    }, 
    {
        name:'My Bills',
        url:''
      }, 
  ]
const PatientMyBills = () => {
    const switchToken = useSelector((state) => state.switchToken.value);
    const selectedUser = useSelector((state) => state.selectedUser.value);
    const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
    const currentLocation = useSelector((state) => state.currentLocation.value);
    const [doccurrentTab, setDocCurrentTab] = useState("MyBills");

    // For External Documents Hooks
    const [billsDocuments, setBillsDocuments] = useState();

    const [billsPage, setBillsPage] = useState(1);
    const [billLoder, setBillLoder] = useState(false);
    const [filterDays, setFilterDays] = useState(30);


    const [pagination, setPagination] = useState();
    const [hasMore, setHasMore] = useState(true);




    const allUsers = useSelector((state) => state.allUsers.value);
    const navigate = useNavigate();
      useEffect(() => {
        if (!allUsers && !selectedUserCard) {
          navigate('/login');
        }
      },[]);

      let patient_id;
      if(selectedUserCard){
        let patient_id = selectedUserCard.id;
      }

       
    useEffect(() => {
      setBillsDocuments()
      if(selectedUser){
        // const payload={
        //     'Uhid':selectedUser.uhid,
        //     'Noofdays':filterDays,
        //     'hospital_group_id':2,
        //     'group_id':1,
        //     'page':billsPage
        // }

        var payload = new FormData();
        payload.append("Uhid", selectedUser.uhid);
        payload.append("Noofdays", filterDays);
        payload.append("page", billsPage);
        if(is_group_exist == true){
            payload.append("hospital_group_id", currentLocation?.hospital_group_id);
            payload.append("group_id", currentLocation?.hospital_group_id);
        }

        PatientMyBillsCall( payload, switchToken).then(function (response) {  
        
          setTimeout(() => {
            setBillsDocuments(response.data.data);
          }, "500");
        }).catch(function (err) {
                console.log(err);
            });
     
      }

    }, [switchToken, selectedUser]);

    useEffect(() => {
        setBillsDocuments(billsDocuments)
    },[billsDocuments])


    const getDatFiltterCall=(e)=>{
      setBillsDocuments()
        let daysValue=e.target.value
        setFilterDays(daysValue);
     
        var payload = new FormData();
        payload.append("Uhid", selectedUser.uhid);
        payload.append("Noofdays", filterDays);
        payload.append("page", billsPage);
        if(is_group_exist == true){
            payload.append("hospital_group_id", currentLocation?.hospital_group_id);
            payload.append("group_id", currentLocation?.hospital_group_id);
        }

        PatientMyBillsCall( payload, switchToken).then(function (response) {  
        
          setTimeout(() => {
            setBillsDocuments(response.data.data);
          }, "500");
        }).catch(function (err) {
                console.log(err);
            });
    }



    // For My Appointments More Data
    const fetchExternalData = () => {
    
        // if (doccurrentTab === "ExternalDocuments" && billsDocuments < pagination.total_pages) {
        //     setExtLoder(true)       
        //     let extNextPage;
        //     extNextPage = extNextPage + 1;
        //     setMyAppointmentsPage(extNextPage)
        //     externalDocumentsCall(patient_id, extNextPage, '', switchToken).then(function (response) {
        //         setBillsDocuments(response.data.records);
        //         setExtLoder(false)
        //     })

        // } else {
        //     setExtMore(false);
        // }
    }


      function showError(str) {
        toast.error(str, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false
        });
      }
      function showToast(str) {
        toast.success(str, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false
        });
      }
    

    return (
        <React.Fragment>
            <Container className="min-body-height">
                <div className="row  align-items-center breadcrumb_sticky">
                    <div className="col-md-9">   <Breadcrumb textUrl={breadcrumArrry} />        </div>
                    <div className="col-md-3 d-none d-md-block">
                    <DashboardLink />
                    </div>

                </div>
                
                <SwitchProfile />

                <div className="row mt-4">
                    <div className="col-12">
                        <h1 className="fs-4 mb-1 text-secondary">My Bills</h1>
                        {/* <p className="m-0 fs-6">Self-upload your health documents</p> */}
                    </div>
                </div>
                <div className="mhc-tabs position-relative mt-4">
                {
                        // <LodingComponent className="mhc-Loding-minHeight" />
                                billsDocuments?.length ==  0 ? <h6 className="text-center mt-2 mb-4">   It seems like you do not have any bills to display.</h6> :
                                billsDocuments === undefined ? <h6 className="text-center mt-2 mb-4">   It seems like you do not have any bills to display.</h6>
                                
                           : <div id="billsDocuments" className="mhc-scrollDiv">
                                    
                                    <ul className="list-unstyled mb-0 mt-4 mt-lg-0 d-flex align-items-center">
                    <li><span className="fw-bolder">Last</span></li>
                      <li>
                  <div className="form-check mt-1 ms-0 ms-lg-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="30Days"
                      defaultValue="30"
                      defaultChecked={filterDays === 30}
                      onChange={(e) => getDatFiltterCall(e)}
                    />
                    <label className="form-check-label" htmlFor="30Days">30 Days</label>
                  </div>
                </li>
                <li>
                  <div className="form-check mt-1 ms-0 ms-lg-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="60Days"
                      defaultValue="60"
                      defaultChecked={filterDays === 60}
                      onChange={(e) => getDatFiltterCall(e)}
                    />
                    <label className="form-check-label" htmlFor="60Days">60 Days</label>
                  </div>
                </li>
                <li>
                  <div className="form-check mt-1 ms-0 ms-lg-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="180Days"
                      defaultValue="180"
                      defaultChecked={filterDays === 180}
                      onChange={(e) => getDatFiltterCall(e)}
                    />
                    <label className="form-check-label" htmlFor="180Days">180 Days</label>
                  </div>
                </li>
             
              </ul>
                                    {/* <InfiniteScroll
                                    dataLength={billsDocuments?.length}
                                    next={fetchExternalData}
                                    hasMore={hasMore}
                                    loader={billLoder == true ? <LodingComponent className="text-center" /> : ''}
                                    scrollableTarget="billsDocuments"
                                    endMessage={pagination.total_pages > 1 ? <NoMoreData className="text-center mb-3" /> : ''}
                                >
                                    {
                                        <PatientMyBillsListing patientMyBills={billsDocuments}  />
                                    }

                                </InfiniteScroll> */}
                                <PatientMyBillsListing patientMyBills={billsDocuments}  />
                                </div>
                            }
                
                    {/* <Tabs                 
                     activeKey={doccurrentTab}
                     onSelect={(key) => setDocCurrentTab(key)}
                    id="controlled-tab-example" className="mb-3">
                        <Tab eventKey="MyBills" title="My Bills">
                       
                            
                           <PatientMyBillsListing />
                        </Tab>
                 

                    </Tabs> */}


                </div>

            </Container>
 
        </React.Fragment>
    )
}


export default PatientMyBills