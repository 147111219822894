import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Accordion, Modal } from "react-bootstrap";

import { doctorTimeSlots } from "../../actions/get-calls";
import LoginUser from "../../component/login-register/login";
import VerifyRegister from "../../component/login-register/VerifyRegister";
import FamilyMember from "../../component/family-member/familyMember";
import BookingSummaryPayment from "../../component/booking-summary-payment/bookinSummaryPayment";
import NewUserRegister from "../../component/login-register/register";
import SelectDateTime from "../../component/select-date-time/selectDateTime";
import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  addDays,
  Day,
} from "date-fns";
import { clearRescheduleReducer } from "../../redux/slice/reschedule";
import { APP_SUB_FOLDER, showConsultType } from "../../utils/constant";
import { toast } from "react-toastify";
import { requestCallback } from "../../actions/post-call";
import LodingComponent from "../../component/loding";

const BookingSection = ({
  hospitalId,
  setConsultId,
  availableDays,
  reasonCode,
  docProfile,
}) => {
  const { specialities, dId, hId, sId, cId } = useParams();

   

  const [specialitiesID, setSpecialitiesID] = useState(sId);
  const [consultT, setConsultT] = useState("0");
  const [docTime, setDocTime] = useState([]);
  const [timeload, setTimeLoad] = useState(false);
  const [consultLoad, setConsultLoad] = useState(false);
  const [dateLoad, setDateLoad] = useState(false);

  const [registered, setRegistered] = useState(true);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [mobile, setMobile] = useState("");
  const [uhidNo, setUhidNo] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
        //  console.log("otpVerified", otpVerified)
  const [profileSelect, setProfileSelect] = useState(false);
  const [memberSelect, setMemberSelect] = useState(null);
  const [timeSlot, setTimeSlot] = useState();

  const [bookSuccess, setBookSuccess] = useState(false);
  const [bookAppointRes, setBookAppointRes] = useState(null);
  const [bookSuccessTime, setBookSuccessTime] = useState(false);
  const [cKey, setCKey] = useState("0");

  const [selectedDay, setSelectedDay] = useState(new Date());
  const [requestModal, setRequestModal]=useState(false)


  const callToken = useSelector((state) => state.callToken.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [isReschedule, setReschedule] = useState(false);
  let monthDate = new Date();
  monthDate.setDate(monthDate.getDate() + 120);
  const dispatch = useDispatch();
  useEffect(() => {
    if (searchParams.get("reschedule") == "true") {
      setReschedule(true);
    } else {
      dispatch(clearRescheduleReducer());
    }
  }, [searchParams]);


  useEffect(() => {
    if (cId === "0") {

      if(docProfile.is_active_vc==1 && docProfile.is_active_opd==0 ){
        setConsultT("1");
        setConsultId("1");
      }else{
        setConsultT("2");
        setConsultId("2");
      }

    } else {      
      setConsultT(cId);
    }
  }, [cId]);

  useEffect(() => {
    setConsultId(consultT);
  }, [hospitalId]);

  const resetBooking = () => {
    setProfileSelect(false);
    setBookSuccessTime(false);
    setTimeSlot([]);
  };

  function getJWTtoken() {
    if (typeof window !== "undefined") {
      if (switchToken) {
        return switchToken;
      } else {
        return callToken;
      }
    } else if (callToken === undefined) {
      return null;
    } else {
      return null;
    }
  }

  let JWTresponse = getJWTtoken();

  function checkToken() {
    if (JWTresponse !== null) {
      return true;
    } else {
      return false;
    }
  }

  const checkingToken = checkToken();

  useEffect(async () => {
    resetBooking();

    if (!selectedDay) {
      setDateLoad(true);
      return;
    }

    if (!consultT) {
      setConsultLoad(true);
      return;
    } else {
      setTimeLoad(false);
      const dateNew = moment(selectedDay).format("DD/MM/YYYY");
      const response = await doctorTimeSlots(
        dId,
        hospitalId,
        dateNew,
        consultT
      );
      if (response && response.data && response.data.time_slots.length !== 0) {
        setDocTime(response.data.time_slots);
        let slots = response.data.time_slots.all;
        let firstAvailableSlot = slots.find((slot) => {
          if (
            !(slot.blocked || slot.booked || slot.is_blocked || slot.lapsed)
          ) {
            return true;
          }
        });
   
        if (firstAvailableSlot) {
          setTimeSlot([firstAvailableSlot.slot, firstAvailableSlot.id]);
        }
        else if(firstAvailableSlot===undefined){
          setTimeSlot(undefined)
        }
        {
          setTimeSlot([])
        }
        setTimeLoad(true);
        setConsultLoad(false);
        setDateLoad(false);
      }
    }
  }, [hospitalId, selectedDay, consultT]);

  const displaySlotTime = () => {
    let content = (
      <div className="alert alert-danger text-center">
        No time slots available!
      </div>
    );
    if (!docTime) return content;

    if (timeload && docTime && docTime.all !== []) {
      return (content = (
        <>
          <h6 className="fs-6 my-3 date-time">Select Time Slot</h6>
          <ul id="timeSlots" className="list-unstyled d-flex flex-wrap mb-5">
            {docTime.all.map((item) => {
              return (
                <li key={item.id} id={`slotID_` + item.id}>
                  <button
                    disabled={
                      item.blocked ||
                      item.booked ||
                      item.is_blocked ||
                      item.lapsed
                    }
                    className={`btn time-slot-btn rounded-1 ${
                      item.blocked ||
                      item.booked ||
                      item.is_blocked ||
                      item.lapsed
                        ? "selected"
                        : ""
                    } ${timeSlot && timeSlot[1] === item.id ? "active" : ""}`}
                    onClick={() => setTimeSlot([item.slot, item.id])}
                  >
                    {item.slot.substring(item.slot.indexOf(" ") + 1)}
                  </button>
                </li>
              );
            })}
          </ul>
        </>
      ));
    }

    if (docTime && docTime.all === []) return content;
  };

  useEffect(() => {
    setBookSuccess(false);
    setBookAppointRes(null);
    setBookSuccessTime(false);
  }, [selectedDay, timeSlot]);

  useEffect(() => {
    if (bookAppointRes !== null) {
      setCKey("1");
    } else {
      setCKey("0");
    }
  }, [bookAppointRes]);

  function toggleAccordion(index) {
    let parentEl = document.querySelectorAll("#summary-tabs .accordion-item")[
      index
    ];
    let child = parentEl.firstElementChild || parentEl.firstChild;
    let secondChild = child.firstElementChild || child.firstChild;
    secondChild.click();
  }

  const requestModalHandle=()=>{
    setRequestModal(true)
  }
  const requestModalHandleClose=()=>{
    setRequestModal(false)
  }

const SubmitRequstForm= async(e)=>{
  e.preventDefault();

  if (document.querySelector("#FirstName").value == "") {  
    showError("First Name is required");  
    return false;
  }
 if (document.querySelector("#LastName").value == "") {
    alert("lastName")
    showError("Last Name is required");
    return false;
  }
   if (document.querySelector("#MobileNumber").value == "") {
    showError("Mobile No. is required");
    return false;
  }
  var payload = new FormData();

 
         
  
  payload.append("case_type_c", consultT===1 ? "Virtual":"OPD");
  payload.append("doctor_id", dId);
  payload.append("email", document.querySelector("#Email").value);
  payload.append("first_name", document.querySelector("#FirstName").value);
  payload.append("fortis_doctor_name_c", docProfile.name);
  payload.append("hospital_name_c", currentLocation.name);
  payload.append("hospital_id",  currentLocation.hospitalID);
  payload.append("last_name", document.querySelector("#LastName").value);
  payload.append("lead_source", "Patient Portal" );
  payload.append("phone_number", document.querySelector("#MobileNumber").value);
  if(memberSelect){
    payload.append("patient_id", memberSelect?.id);
  }

  payload.append("referred_for_specilaity_c", specialities);
  payload.append("speciality_id", sId);
  payload.append("type_of_query_c", "Request an appointment") ;
  requestCallback(payload)
  .then(function (response) {
    let res = response.data;
    if (response?.statusText == "OK" || response?.status == 200) {
      setRequestModal(false)
      return showToast(res.message)
    
    }
  


    if (res && res.errors) {
      res.errors.map((e) => {
        return showError(e.message);
      });
      return false;
    }


  })
  .catch(function (err) {});

}
  function showToast(str) {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }
function showError(str) {
  toast.error(str, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
  });
}

  return (
    <>
     {
        showConsultType ===true ?
      <div className="row mt-4">
        <div className="col-lg-6">
           <div className="d-flex flex-row justify-content-start align-items-lg-center">
            <span className="fw-bold ">Consult Type</span>
            <ul className="list-unstyled mb-0 mt-lg-0 d-flex align-items-center">
            <li
                className={
                  (isReschedule && consultT != "2") ||
                  docProfile.is_active_opd == 0
                    ? "d-none"
                    : ""
                }
              >
                <div className="form-check ms-3 ms-sm-4 pt-0 pt-sm-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="consultType"
                    id={
                      currentLocation.type === "hospital"
                        ? "Hospital"
                        : "Clinic"
                    }
                    value="2"
                    checked={consultT === "2"}
                    onChange={(e) => setConsultT(e.target.value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={
                      currentLocation.type === "hospital"
                        ? "Hospital"
                        : "Clinic"
                    }
                  >
                    <span>At </span>
                    {currentLocation.type === "hospital"
                      ? "Hospital"
                      : "Clinic"}
                  </label>
                </div>
              </li>
              <li
                className={
                  (isReschedule && consultT != "1") ||
                  docProfile.is_active_vc == 0
                    ? "d-none"
                    : ""
                }
              >
                <div className="form-check ms-3 ms-sm-4 pt-0 pt-sm-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="consultType"
                    id="Virtual"
                    value="1"
                    checked={consultT === "1"}
                    onChange={(e) => setConsultT(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="Virtual">
                    Virtual
                  </label>
                </div>
              </li>
         
            </ul>
          </div> 

          <div>
            {consultLoad && (
              <h6 style={{ color: "red" }}>
                *Please select a consult type first
              </h6>
            )}
          </div>
        </div>

        <div className="col-lg-6 mt-4 mt-lg-0">
        <h6 className="fs-6 mb-0 date-time">Select Date &amp; Time</h6>
        </div>
      </div>:''
}
      <div className="row mt-4">
        <div className="col-lg-6 order-1 order-lg-0">
          <Accordion
            className=" mhc-profile-tabs"
            id="summary-tabs"
            activeKey={cKey}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>1. Login/ Register</Accordion.Header>

              <Accordion.Body className="py-3">
                {registered && !checkingToken && (
                  <LoginUser
                    registeredState={setRegistered}
                    setMobile={setMobile}
                    setUhidNo={setUhidNo}
                    setOtpVerified={setOtpVerified}
                  />
                )}
     
                {!registered && !verifyOTP && (
                  <NewUserRegister
                    setVerifyOTP={setVerifyOTP}
                    mobile={mobile}
                    uhidNo={uhidNo}
                    setRegistered={setRegistered}
                  />
                )}

                {verifyOTP && !otpVerified && (
                  <VerifyRegister
                    mobile={mobile}
                    setOtpVerified={setOtpVerified}
                  />
                )}

                {(otpVerified || checkingToken) && (
                  <FamilyMember
                    consultT={consultT}
                    timeSlot={timeSlot}
                    docId={dId}
                    hospitalId={hospitalId}
                    btnDisplay={true}
                    setProfileSelect={setProfileSelect}
                    setMemberSelect={setMemberSelect}
                    setBookSuccess={setBookSuccess}
                    setBookAppointRes={setBookAppointRes}
                    setBookSuccessTime={setBookSuccessTime}
                    resetBooking={resetBooking}
                    reasonCode={reasonCode}
                  />
                )}
              </Accordion.Body>
            </Accordion.Item>

            {bookAppointRes ? (
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2. Booking Summary &amp; Payment
                </Accordion.Header>
                <Accordion.Body className="py-3">
                  <BookingSummaryPayment
                    consultT={consultT}
                    memberSelect={memberSelect}
                    bookAppointRes={bookAppointRes}
                    setBookAppointRes={setBookAppointRes}
                    setBookSuccess={setBookSuccess}
                    setBookSuccessTime={setBookSuccessTime}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              ""
            )}
          </Accordion>
        </div>
        <div className="col-lg-6 order-0 order-lg-1">
   
        {
           showConsultType ===false ? <h6 className="fs-6 mb-0 date-time">Select Date &amp; Time</h6> :''
         }
       
      
          <SelectDateTime
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            minDate={moment().toDate()}
            availableDays={availableDays}
            resetBooking={resetBooking}
          />
      
          {timeload === false || timeSlot===undefined ? (
             <LodingComponent className="text-center mt-4" />
          ) : docTime?.all?.length > 0 ? (
            <>{displaySlotTime()}</>
          ) : (

            <>
               <div className="alert alert-warning mt-3 fs-5 rounded-0 text-center">
              Doctor is not available for the selected date. Please select any
              other date &amp; proceed 
              {/* or 
              <a
                className="text-primary text-decoration-none fw-bolder"
                href={`tel:${currentLocation.primary_contact }`}
              >
                <span className="mhc-icon icon-phone"></span>{" "}
                {currentLocation.primary_contact}
              </a> */}
              <div className="w-100 text-center my-3 " ><p className="text-primary  or-circle border-primary" ><b>OR</b></p></div>
              <div className="w-100 text-center">
              <button className="btn btn-primary mt-1" type="button" onClick={requestModalHandle}>Request a call-back</button>
              </div>
             
            </div>

         
            </>
         
          )}
        </div>
      </div>


      <Modal show={requestModal} onHide={requestModalHandleClose} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title>Request a call-back</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={e=>SubmitRequstForm(e)} >
        <div className="row ">
         <div className="col-lg-6">
            <div className="form-floating mb-4">
                <input type="text" className="form-control" name="FirstName" id="FirstName" placeholder="First Name"                
            
                />
                <label htmlFor="FirstName">First Name <sup>*</sup></label>
              </div>
          </div>
          <div className="col-lg-6">
            <div className="form-floating mb-4">
                <input type="text" className="form-control" name="LastName" id="LastName" placeholder="Last Name"
   
                 />
                <label htmlFor="LastName">Last Name <sup>*</sup></label>
              </div>
          </div>
          <div className="col-lg-6">
            <div className="form-floating mb-4">
                <input type="text" className="form-control" name="MobileNumber" id="MobileNumber" placeholder="Mobile Number"   
                  maxLength="10"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <label htmlFor="MobileNumber">Mobile Number<sup>*</sup></label>
              </div>
          </div>
          <div className="col-lg-6">
            <div className="form-floating mb-4">
                <input type="text" className="form-control" name="Email" id="Email" placeholder="Email" 
               
             
                 />
                <label htmlFor="Email">Email </label>
              </div>
          </div>
            <div className="col-12 text-center">
           <button className="btn btn-primary mb-4"  >Request a call-back</button>
           </div>
        </div>
          </form>
        </Modal.Body>
  
      </Modal>

    </>
  );
};

export default BookingSection;
