import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DownloadIcon from '../../assets/images/eye.svg'
import PdfIcon from '../../assets/images/mhc-pdf.svg'
import { Collapse, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import countries from "../../assets/data/countries.json";
import moment from "moment";
import { saveAs } from "file-saver";
import CallTOHelp from "../../component/call-to-help/index";
import { myPrescriptionsOrderTest, myPrescriptionTrackOrder, SendMedicinePrescription } from "../../actions/dashboard-api";
import StepProgressBar from "react-step-progress";
import LodingComponent from "../../component/loding";
import ImageUploading from "react-images-uploading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { countryState, stateCity } from "../../actions/dashboard-api";
import { capitalizeFirst } from "../../utils/fliters";
import { groupId, hospitalgroupId, hospitalName, hospital_type, APP_SUB_FOLDER } from "../../utils/constant";
const PatientMyPrescriptionsDectorConsult = ({ docConsultList }) => {

    const selectedUser = useSelector((state) => state.selectedUser.value);
    const switchToken = useSelector((state) => state.switchToken.value);
    const currentLocation = useSelector((state) => state.currentLocation.value);
    const maxNumber = 8;
    const filesize=2242880;
 
    const [opendelivered, setOpenDelivered] = useState(false);
    const [modal, setModal] = useState(false);
    const [showIcon, setShowIcon] = useState(true) 
    const [open, setOpen] = useState(false);
    const [trackOrder, setTrackOrder]=useState()
    const [addDocs, setAddDocs] = React.useState([]);
    const [addMedPres, setAddMedPres] = React.useState([]);
    const [viewNoteDetails, setViewNoteDetails]=useState()
    const [medAgree, setMedAgree] = useState(0);
    const [testAgree, setTestAgree] = useState(0);

    //hook Order Medicines
    const[prescriptionForMedicine, setPrescriptionForMedicine] = useState();
    const [medicineModal, setMedicineModal]=useState(false)
    const [allState, setAllState] = useState(null);
    const [allCity, setAllCity] = useState(null);

    const handlecollspedDevlivered = () => {
        setOpenDelivered(!opendelivered);
    }


    const handleClose = () => {
        setModal(false);
    }

 
    const saveDocConsultPdf = (e, upload_pdf) => {
        saveAs(upload_pdf, "doctorconsult.pdf");
    };
    //right Track Order
    const handlecollspe = (e, i, bookingCode) => { 
           let booking_code=bookingCode     
        setOpen({[i]:!open[i]})

        setTrackOrder(null)
        myPrescriptionTrackOrder(bookingCode, switchToken).then(function (response) {
        
            let trackData = response.data.data.order_status;
            setTrackOrder(trackData)       
         
        })
            .catch(function (err) {
                console.log("error", err);
            });
    }

    const onTestUploadChange = (imageList, addUpdateIndex) => {
        // console.log(imageList)
        setAddDocs(imageList);   
    };
    const uploadErrortest = (errors) => {
      if (errors && errors.maxFileSize == true) {
        return showError("File size must be less than to 2MB.");
      }
      if (errors && errors.maxNumber ==  true) {
        return showError("Maximum " + maxNumber + " images are allowed.");
      }
    };
    const uploadError = (errors) => {
      if (errors && errors.maxFileSize == true) {
        return showError("File size must be less than to 2MB.");
      }
      if (errors && errors.maxNumber ==  true) {
        return showError("Maximum " + maxNumber + " images are allowed.");
      }
    };

    const onMedUploadChange = (imageList, addUpdateIndex) => {
      // console.log(imageList)
      setAddMedPres(imageList);   
    };
    const handleOrderTest = (e, details) => {
        setViewNoteDetails(details)
        setTestAgree(0)
  
        const imagesList = details.documents?.map((item)=>{
            return {'data_url': item}
        })
        setAddDocs(imagesList)
        setModal(true);
    }
    const onMedAgreeHandle=(e)=>{
        if(e.target.checked == true){
            setMedAgree(1)     
          }else{
              setMedAgree(0)
          }
        }


      const onTestAgreeHandle=(e)=>{
        if(e.target.checked == true){
            setTestAgree(1)     
          }else{
              setTestAgree(0)
          }
        }
       
    const orderTestUpload=()=>{

        var payload = new FormData();
        payload.append("name", selectedUser.first_name);
        payload.append("address2", selectedUser.address_2);
        payload.append("street1", selectedUser.address_2);
        payload.append("street2", selectedUser.address_2);
        payload.append("contact_number", selectedUser.mobile_number);
   
        addDocs.map((item)=>{
            if(item.file !=undefined){
                payload.append('prescription[]', item.file);
            }
        })
    
        myPrescriptionsOrderTest(payload, switchToken).then(function (response) {
                // console.log(response)
                if(response.statusText == "OK" || response.status == 200){
                    setModal(false)
                    // return showToast(response.data.message);
                    return showToast("Your diagnostic request has been received successfully, Our patient care team will get back to you shortly.");
                }else{
    
                }
    
                
        
        })
            .catch(function (err) {
                console.log(err);
            });
    }


      //  Order Medicines function
      useEffect(() => {
        countryState('101', currentLocation.hospital_group_id).then(function (response) {
          // {id: 101, name: 'India'}
          setAllState(response.data.states)
          })
          .catch(function (err) {
              console.log(err);
          });
      },[]);
    
      const onChangeState = (e) => {
        let state = e.target.options[e.target.selectedIndex].id;
        // console.log(e.target.options[e.target.selectedIndex].id)
          if(state !=''){
            stateCity(state, currentLocation.hospital_group_id).then(function (response) {
                setAllCity(response.data.cities)
              })
              .catch(function (err) {
                  console.log(err);
              });
          }
      }
      const medicinesModalHandle=(upload_pdf)=>{
        setPrescriptionForMedicine(upload_pdf)
        // console.log(selectedUser)
        if(selectedUser){
          stateCity(selectedUser.state, currentLocation.hospital_group_id).then(function (response) {
                setAllCity(response.data.cities)
                setTimeout(() => {
                    let cityName = selectedUser.city_name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                    let cityEle = document.querySelector('#city');
                    cityEle.value = cityName;
                    cityEle.dispatchEvent(new Event('change'));
                }, 600); 
              })

        }

        setMedicineModal(true)
        setMedAgree(0)
      }

      
      const handleMedicineOrder=(e)=>{
        e.preventDefault();
        
        if(e.target.elements.first_name.value == ""){
          showError("First name is required");
          return false;
        }
        if(e.target.elements.mobile_no.value == ""){
          showError("Mobile number is required");
          return false;
        }
        if(e.target.elements.email.value == ""){
          showError("Email is required");
          return false;
        }
        if(e.target.elements.address_line_1.value == ""){
          showError("Address line 1 is required");
          return false;
        }
        if(e.target.elements.pin_code.value == ""){
          showError("PIN Code is required");
          return false;
        }
        if(e.target.elements.stateName.value == ""){
          showError("State is required");
          return false;
        }
        if(e.target.elements.city.value == ""){
          showError("City is required");
          return false;
        }

        var payload = new FormData();
        // payload.append('hospital_group_id', 1);
        payload.append('first_name', e.target.elements.first_name.value);
        payload.append('last_name', e.target.elements.last_name.value);
        payload.append('mobile_no', e.target.elements.mobile_no.value);
        payload.append('email', e.target.elements.email.value);
        payload.append('address_line_1', e.target.elements.address_line_1.value);
        payload.append('address_line_2', e.target.elements.address_line_2.value);
        payload.append('country', e.target.elements.country.value);
        payload.append('pin_code', e.target.elements.pin_code.value);
        payload.append('state', e.target.elements.stateName.value);
        payload.append('city', e.target.elements.city.value);
        payload.append('vendor_id', 1);

        payload.append('hospital_group_id',currentLocation.hospital_group_id)
        payload.append('group_id',currentLocation.hospital_group_id)
        payload.append('hospital_type', hospital_type)
        payload.append('prescription[]', prescriptionForMedicine)
        addMedPres.map((item)=>{
          if(item.file !=undefined){
              // console.log(item);
              payload.append('prescription[]', item.file);
          }
        })

        SendMedicinePrescription(payload, switchToken).then(function (response) {
            if(response.statusText == "OK" || response.status == 200){
              setMedicineModal(false)
              return showToast("Your medicines request has been received successfully, Our patient care team will get back to you shortly.");
            }
            
        })
        .catch(function (err) {
            console.log(err);
        });
        
    

        
        // return false;
      }


      
    function showError(str) {
      toast.error(str, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false
      });
    }
  
    function showToast(str) {
      toast.success(str, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false
      });
    }

      
    return (
        <React.Fragment>
             {/* <Link class={`btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 `} to="" onClick={()=>medicinesModalHandle()}>Order Medicines</Link> */}
            {docConsultList.map((item, i) => {
                let appt_date = moment(item.date).format("DD MMM, YYYY");
                let appt_time = moment(item.date).format("LT");

                let update_pres_date = moment(item.date).format("DD MMM, YYYY");
                let update_pres_time = moment(item.date).format("LT");

                let upload_pdf = item.upload_prescription;
                let pdfsecond = item.pdf_document

                let bookingCode=item.booking_code

                return (
                    
                        <div className=" predcription-dector-card" key={i+1}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-11 col-sm-11 col-md-11 col-lg-4 order-1 order-lg-1" >
                                        <div className="d-flex align-items-center">
                                            <div><img 
                                            src={`${APP_SUB_FOLDER}${PdfIcon}`}
                                            alt="PdfIcon" className="pdf-icon" /></div>
                                            <div className="ms-3">
                                                <h4 className="mhc-tittle m-0">{item.doctor_name}</h4>
                                                <p className="m-0">{item.patient_name} </p>
                                            </div>

                                        </div>
                                        <div className="mt-3 mt-lg-2">
                                            {/* <Link class={`btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 ${item.is_medicine_order_id ? 'disabled-link' : ''}`} to="/patient-my-prescription">Order Medicines</Link> */}
                                            {/* <button disabled={item.is_medicine_order_id != null} className={`btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 `}  onClick={()=>medicinesModalHandle(upload_pdf)}>Order Medicines</button> */}
                                            {item.is_medicine_ordered != 0 ?
                                                <button className="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 d-none " type="button" onClick={handlecollspedDevlivered} aria-controls="trackorder" aria-expanded={opendelivered}>Track Order</button>
                                                : ''
                                            }
                                        </div>
                                        {item.is_medicine_order_id? 
                                        <p className="mb-0 mt-2 mt-lg-3">Order ID : <span className="fw-bolder">{item.is_medicine_order_id}</span></p>
                                        :null
                                    }
                                        
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 order-3 order-lg-2 mt-3 mt-lg-0">
                                        <p className="m-0">Appointment </p>
                                        <p className="custom-text mb-0">{appt_date} | {appt_time}</p>
                                        <div className="mt-3 mt-lg-2">
                                            {/* <button className="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 " onClick={(e)=>handleOrderTest(e, item)}>Order Tests</button> */}
                                            {/* <button className="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 " type="button" onClick={(e)=>handlecollspe(e, i, bookingCode)} aria-controls="trackorder"     aria-expanded={open[i]} >Track Order</button> */}
                                        </div>
                                        
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 order-4 order-lg-3 mt-3 mt-lg-0">
                                        <p className="m-0">Prescription updated at </p>
                                        <p className="custom-text mb-0">{update_pres_date} | {update_pres_time}</p>
                                    </div>
                                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 order-2 order-lg-4 text-end" >
                                        {/* <img src={DownloadIcon} alt="DownloadIcon" className="download-icon" /> */}
                                        {upload_pdf && pdfsecond != undefined ?
                                            <a
                                                onClick={(e) => saveDocConsultPdf(e, upload_pdf)}
                                                download={upload_pdf != undefined ? upload_pdf : pdfsecond}>
                                                <img
                                                    src={`${APP_SUB_FOLDER}${DownloadIcon}`}
                                                    alt=""
                                                    className='show download-icon'
                                                />
                                            </a>
                                            : ''
                                        }

                                    </div>
                                </div>
                            </div>
                       
                            <Collapse in={open[i]}  >
                                <div id="trackorder" className="mhc-collapse">
                                {
                                trackOrder != null || trackOrder != undefined ?
                                  
                                       
                                    <div id="trackorder" className="mhc-collapse">
                                   
                                        <StepProgressBar
                                            startingStep={0}
                                            //   onSubmit={onFormSubmit}
                                            wrapperClass="Mhc-progressbar"
                                            progressClass="progressbar"
                                            stepClass="step"
                                            contentClass="content"
                                            labelClass="tittle"
                                            subtitleClass="sub-tittle"
                                            buttonWrapperClass="bth-wrapper"                                            
                                            steps={
                                                trackOrder.map((trackItem)=>{
                                                    return(
                                                        {
                                                            label: `${trackItem.status_name}`,
                                                            subtitle: `${trackItem.created_at}`,
                                                        }
                                                    )
                                                })
                                               
                                             
                                            }
                                            
                                        >
                                            </StepProgressBar>
                                    </div>
                                 
                                    : <LodingComponent className="text-center" />
                                }


                                </div>
                            </Collapse>
                            <Collapse in={opendelivered}>
                                <div id="trackorder" className="mhc-collapse">
                                    <p className="order-delivered"><span>Medicines delivered</span></p>
                                </div>
                            </Collapse>
                        </div>
                    
                );
            })}


       
            <Modal show={modal} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title className="fs-6">Book Test</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="d-flex align-items-start align-items-md-center flex-column flex-md-row">
                        <div className="fs-7">Call our patient care team </div>
                        <div className=" ms-0 ms-md-3 mt-2 mt-md-0">
                        <CallTOHelp />
                        </div>
                    </div>
                    <p className="fs-7 text-primary fw-bolder mb-0"> OR</p>
                    <p className="fs-7  fw-bolder mb-0 mt-2 mt-md-3">Upload Prescription</p>
                    <div className="mt-3">
                    <div className="mhc-multiImage">
                    <ImageUploading
        multiple
        name="file_upload[]"
        value={addDocs}
        onChange={onTestUploadChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        maxFileSize={filesize}
        onError={uploadErrortest}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div className="d-flex flex-wrap">
              {imageList?.map((image, index) => (
                <div key={index} className="image-item">
          <img src={ image["data_url"].includes("application/pdf")== true? `${APP_SUB_FOLDER}${PdfIcon}`: image["data_url"].split('.').slice(-1)[0] == "pdf" ? `${APP_SUB_FOLDER}${PdfIcon}` :image["data_url"]} alt={image.pic_id} width="100"  />
                  <div className="image-item__btn-wrapper">
                    <button
                      onClick={() => onImageRemove(index)}
                      className="close"
                    ></button>
                  </div>
                </div>
              ))}

              <button
                className="btn mhc-add-more"
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <br /> Add     More
              </button>
            </div>
          </div>
        )}
      </ImageUploading>
</div>
                    </div>
                    <p className="fs-7  fw-bolder mb-0 mt-3">Patient Consent</p>
                    <p>By confirming the order i consent to my prescription being shared with MyHealthcare pharmacies for home delivery of medicines.</p>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultValue={testAgree} id="testAgree" name="testAgree" onChange={(e)=>onTestAgreeHandle(e)} />
                        <label className="form-check-label d-block d-sm-inline " htmlFor="testAgree">
                            I Agree
                        </label>
                    </div>
                    <button className="btn btn-custom rounded-1  mt-3" disabled={testAgree==1?false:true} onClick={()=>orderTestUpload()}>Confirm Order</button>
                </Modal.Body>
            </Modal>
            <Modal show={medicineModal} onHide={()=>setMedicineModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="fs-6">Order Medicines</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <form className="mhc-register-form" onSubmit={(e) => handleMedicineOrder(e)}>
                   <div className="row">
                    <div className="col-md-6">
                    <div className="form-floating mb-4">
                     <input type="text"   className="form-control" name="first_name" id="first_name"  placeholder="first_name" defaultValue={selectedUser.first_name} />
                    <label htmlFor="first_name">First Name <sup>*</sup></label>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-floating mb-4">
                     <input type="text"   className="form-control" name="last_name" id="last_name"  placeholder="last_name" defaultValue={selectedUser.last_name} />
                    <label htmlFor="last_name">Last Name </label>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-floating mb-4">
                     <input type="tel" maxLength={10}   className="form-control" name="mobile_no" id="mobile_no"  placeholder="mobile_no" defaultValue={selectedUser.mobile_number} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} />
                    <label htmlFor="mobile_no">Mobile No <sup>*</sup></label>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-floating mb-4">
                     <input type="email"   className="form-control" name="email" id="email"  placeholder="email" defaultValue={selectedUser.email} />
                    <label htmlFor="email">Email <sup>*</sup></label>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-floating mb-4">
                     <input type="text"   className="form-control" name="address_line_1" id="address_line_1"  placeholder="address_line_1" defaultValue={selectedUser.address} />
                    <label htmlFor="address_line_1">Address Line 1 <sup>*</sup></label>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-floating mb-4">
                     <input type="text"   className="form-control" nbame="address_line_2" id="address_line_2"  placeholder="address_line_2" defaultValue={selectedUser.address_2} />
                    <label htmlFor="address_line_2">Address Line 2 </label>
                    </div>
                    </div>
                    <h6 className="mb-3">Delivery Address</h6>
                    <div className="col-md-6">
                    <div className="mhc-selectbox">
                    <select
                      name="country"
                      id="country"
                      className="form-select"
                      // value="India" defaultValue={{label:"India", value: "91"}} disabled
                      defaultValue="India"
                      >
                      {
                        countries?.map((item) => {
                          const { n } = item;
                          return (<option key={n} value={n}>{n}</option>);
                        })
                      }
                    </select>
                        <label htmlFor="country">Country</label>
                    </div>
                        </div>
                        <div className="col-md-6">
                    <div className="form-floating mb-4">
                     <input type="text"   className="form-control" name="pin_code" id="pin_code"  placeholder="pin_code" defaultValue={selectedUser.pin_code} />
                    <label htmlFor="pin_code">PIN Code <sup>*</sup></label>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="mhc-selectbox">
                    <select
                      name="stateName"
                      id="stateName"
                      // required
                      className="form-select"
                      onChange={(e) => { onChangeState(e) }}
                      defaultValue={selectedUser.state_name}
                      >
               
                      {
                         allState?.map((item) => {
                          return (<option key={item.id} defaultValue={item.name} id={item.id}>{item.name}</option>);
                        })
                      }
                    </select>
                        <label htmlFor="stateName">State</label>
                    </div>
                        </div>
                        <div className="col-md-6">
                    <div className="mhc-selectbox">
                    <select
                      name="city"
                      id="city"
                      className="form-select"
                      // required
                      defaultValue={selectedUser.city_name}
                      >
       
                      {
                          allCity?.map((item) => {
                          return (<option key={item.name} defaultValue={capitalizeFirst(item.name)}>{capitalizeFirst(item.name)}</option>);
                        })
                      }
                    </select>
                        <label htmlFor="city">City</label>
                    </div>
                        </div>
                        
                  
                        
                   </div>
                   
                    <div className="mt-3">
                    <p>Upload Prescription</p>
                    <div className="mhc-multiImage">
                    <ImageUploading
                      multiple
                      name="file_upload[]"
                      value={addMedPres}
                      onChange={onMedUploadChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["jpg", "gif", "png", "pdf"]}
                      maxFileSize={filesize}
                      onError = {uploadError}
                   >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div className="d-flex flex-wrap">
              {imageList?.map((image, index) => (
                <div key={index} className="image-item">
<img src={ image["data_url"].includes("application/pdf")== true? `${APP_SUB_FOLDER}${PdfIcon}`: image["data_url"].split('.').slice(-1)[0] == "pdf" ? `${APP_SUB_FOLDER}${PdfIcon}` :image["data_url"]} alt={image.pic_id} width="100"  />
                  <div className="image-item__btn-wrapper">
                    <button
                      onClick={() => onImageRemove(index)}
                      className="close"
                    ></button>
                  </div>
                </div>
              ))}

              <button
                type="button"
                className="btn mhc-add-more"
                style={isDragging ? { color: "red" } : undefined}
                disabled={imageList.length == maxNumber}
                onClick={onImageUpload}
                {...dragProps}
              >
                <br /> Add  More
              </button>
            </div>
          </div>
        )}
      </ImageUploading>
</div>
                    </div>
                 
                    <p className="fs-7  fw-bolder mb-0 mt-3">Patient Consent</p>
                    <p>By confirming the order i consent to my prescription being shared with {hospitalName} pharmacies for home delivery of medicines.</p>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultValue={medAgree} id="medAgree" name="medAgree" onChange={(e)=>onMedAgreeHandle(e)} />
                        <label className="form-check-label d-block d-sm-inline " htmlFor="medAgree">
                            I Agree
                        </label>
                    </div>
                    <button className="btn btn-custom rounded-1  mt-3" type="submit" disabled={medAgree==1?false:true}>Confirm Order</button>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default PatientMyPrescriptionsDectorConsult;