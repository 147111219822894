import React from "react";
import { Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { APP_SUB_FOLDER } from "../../utils/constant";
import { useState } from "react";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import { compareHealthComparison } from "../../actions/post-call";
import { useEffect } from "react";
import { setHealthCheckUpTestDetailReducer } from "../../redux/slice/healthCheckUp-testDetail";
import { useDispatch, useSelector } from "react-redux";
import { setBookPackagedetailsReducer } from "../../redux/slice/bookPackagedetails";
import LodingComponent from "../../component/loding";

export default function PackageComparison (){
    const dispatch = useDispatch();
    const currentLocation = useSelector((state) => state.currentLocation.value);
    const { compare1, compare2, categoryName } = useParams();
    const [comparedResult, setComparedResult] = useState(null);
    const [testListload, setTestListload] = useState(false);
    useEffect(async () => {
       
        let payload = { phc_ids: [compare1, compare2] ,        
            hospital_id:currentLocation.hospitalID,
            group_id:currentLocation.hospital_group_id,
            hospital_group_id:currentLocation.hospital_group_id};
            await compareHealthComparison(payload)
            .then(function(response){
              setComparedResult(response.data.health_checkup)
              setTestListload(true)
            })
        // console.log("comparison", comparison)
      
    },[compare1, compare2])



    const forwordTestDetails=(e, details)=>{
        dispatch(setBookPackagedetailsReducer(details))
      }
     



    const breadCrumbData = [
        { type:1, url: `/`, name: 'Home' },
        {  type:1, url: "/my-dashboard", name: "My Dashboard" },
        {  type:1,  url: `/book-package-or-test/packages`, name: 'Book Lab' },
        {  type:1,  url: "", name: 'Package Comparison' },
     
    ];
        return(
            <React.Fragment>
                <div className="min-body-height">
                   {
                      testListload===true ? <div className='container'>
                      <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
                          <div className="row">
                              <div className="col-lg-6 ">
                                  <h1 className="fs-4 mb-1">Package Comparison</h1>
                              </div>
                          </div>
                          <div className='mhc-compare'>
                          <div className='table-compare table-responsive mt-2 mb-4' >
                              <Table bordered className='table-width'>
                                  
                                  <thead>
                                      <tr>
                             
                                          {
                                              comparedResult?.map((item)=>{
                                          
                                                  return(
                                                      <th>
                                                      <div className='row'>
                                                          <div className='col-8 col-sm-7 '>
                                                              <h6 className="mb-1"> {item.title}  </h6>
                                                              <p className="packg-parm mb-0"> {item.test_count} Tests </p>
                                                          </div>
                                                          <div className='col-4 col-sm-5 d-flex justify-content-end d-none'>
                                                              <span className='img-holder'><img src={item.image} alt="1mg" className='vendor-image'></img></span>
                                                          </div>
                                                      </div>
                                                      <div className='row mt-3'>
                                                          <div className='col-8 d-flex align-items-center'>
                                                           <span className="text-primary">INR {item.discount_price}</span>
                                                          </div>
                                                          <div className='col-4 d-flex justify-content-end'>
                                                          <Link onClick={(e)=>forwordTestDetails(e,item)} 
                               
                                                                  to={`/book-package-or-test/packages/${
                                                                      categoryName
                                                                      ? categoryName
                                                                          .toLowerCase()
                                                                          .trim()
                                                                          .replaceAll(" ", "-")
                                                                      : "-"
                                                                  }/${item.id}`} 
                                                                  className="btn btn-primary rounded-1">Book</Link>
                                                          </div>
                                                      </div>
                                                  </th>
                                            
                                                  )
                                              })
                                          }
                                   
                                         
                                      </tr>
                                  </thead>
                                  <tbody>
  
                            <tr>
                            <td >
                            {comparedResult[0]?.features.length >0 ?<>
                             {
                              comparedResult[0]?.features.map((item)=>{
                                  return(
                                      <><span>{item}</span> <br /></>
                                  )
                              })
                             }
                            </> :<>---</>
                          
                          }
                            </td>
                            <td>
                            {comparedResult[1]?.features.length >0 ?<>
                             {
                              comparedResult[1]?.features.map((item)=>{
                                  return(
                                      <><span>{item}</span> <br /></>
                                  )
                              })
                             }
                            </> :<>---</>
                          
                          }
                            </td>
                            </tr>
                         
                                  </tbody>
                              </Table>
                          </div>
                          </div>
                      </div>:<LodingComponent  className="mhc-Loding-minHeight" />
                   }
                   
             </div>
            </React.Fragment>
        )
    }


