import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {setcurrentLocationReducer, clearcurrentLocationReducer} from "../../redux/slice/current-location";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
const HospitalListModal = ({isOpen}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const hospitalAddressList = useSelector((state) => state.hospitalAddressList.value);
    const currentLocation = useSelector((state) => state.currentLocation.value);
    const [prevLocation, setPrevLocation] = useState(hospitalAddressList? hospitalAddressList[0]: null);
    
    
    useEffect(() => {
      let is_modal_show = localStorage.getItem('alreadyShow');
      if((is_modal_show != 'alredy shown' || is_modal_show == null) &&  isOpen == true && hospitalAddressList?.length > 1){ 
        localStorage.setItem('alreadyShow','alredy shown');
        setModal(true);
      }
    })


    const handleHopital = (e, index) => {
        setPrevLocation(currentLocation);
        dispatch(setcurrentLocationReducer(hospitalAddressList[index]));
      };
      const handleBookConsultModal = (e) => {
        e.preventDefault();
        setModal(true);
        setPrevLocation(hospitalAddressList[0]);
        
      };
      const closeBookConsultModal = (e, status) => {
        setModal(false);
        
        if (status == "ok") {          
          window.location.pathname == '/user-register' || window.location.pathname == '/qr-register' ? navigate("") : navigate(`/`);             
        } 
        else {
         dispatch(setcurrentLocationReducer(prevLocation));
        }
      };
          
    return(
        <>
           {hospitalAddressList?.length > 1?
        <>  <a href="#" onClick={handleBookConsultModal} className="hospital_name_link">{currentLocation?.name}</a></>
       
        :null}
         <Modal
        show={modal}
        // onHide={closeBookConsultModal}
        backdrop="static"
        className="mhc-imgPopup"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <div className="p-0">
          <div className="hospital-list px-3 pt-3">
            {hospitalAddressList?.map((item, index) => {
              //  console.log("item", item)
              return (
                <div
                  key={index}
                  className="form-check mt-0 custom-checkbox mb-3"
                >
                  <input
                    className="form-check-input optionHopistal"
                    type="radio"
                    name={`optionHopistal`}
                    value={index}
                    data-type={item.type}
                    title={item.label}
                    id={`hopistall${item.hospitalID}`}
                    onChange={(e) => handleHopital(e, index)}
                    checked={item.hospitalID === currentLocation?.hospitalID}
                  />

                  <label
                    className="form-check-label custom-text cursor-pointer d-inline fw-bolder"
                    htmlFor={`hopistall${item.hospitalID}`}
                  >
                    {item.label}
                    <br />
                    <small className="fw-normal">{item.address}</small>
                  </label>
                </div>
              );
            })}
          </div>
          <div className="hospital-footer mt-1 text-center">
            <button
              type="submit"
              onClick={(e) => closeBookConsultModal(e, "ok")}
              className="btn btn-custom rounded-1 py-1 px-4"
            >
              Ok{" "}
            </button>
          </div>
        </div>
      </Modal>
        </>
    )
}

export default HospitalListModal
