import axiosClient from "./api-handlers";
import { groupId, hospitalgroupId, hospitalgroupName, is_group_exist} from "../utils/constant";
import {apitoken } from "../utils/constant";
import axiosMHClient from "./second-api-handlers";


//POST method to get OTP or 404 for new user
export async function getOTP(uid, type, hospital_group_id) {
  let group_exist = '';
  let Type='';
  if(type === 'uhid'){
    Type = `&type=uhid`;
  }
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }
  const response = await axiosClient.post(
    //`/patients/web/v2/patients/get-otp?uid=${uid}${Type}&hospital_group_code=${hospitalgroupName}${group_exist}`
    `/patients/web/v2/patients/get-otp?uid=${uid}${Type}&hospital_group_code=${hospitalgroupName}${group_exist}`
  );
  return response;
}
export async function requestPin(uid, isd, type, hospital_group_id,hospital_id ) {
  let Type='';
  if(type === 'uhid'){
    Type = `&type=uhid`;
  }
  let group_exist = '';
  if(is_group_exist === true){
  
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}&hospital_id=${hospital_id}`;
  }
  const response = await axiosClient.post(
    `/patients/web/v2/patients/request-pin?uid=${uid}${Type}&isd_code=${isd}${group_exist}`,
    // payload
  );
  return response;
}
export async function verifyPIN(uid, pin, hospital_group_id,hospital_id) {
  let group_exist = '';
  if(is_group_exist === true){
  
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}&hospital_id=${hospital_id}`;
  }
  const response = await axiosClient.post(
    `/patients/web/v2/patients/verify-pin?uid=${uid}&pin=${pin}${group_exist}`,
    // payload
  );
  return response;
}
export async function savePIN(uid, pin,  hospital_group_id,hospital_id) {
  let group_exist = '';
  if(is_group_exist === true){
  
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}&hospital_id=${hospital_id}`;
  }
  const response = await axiosClient.post(
    `/patients/web/v2/patients/save-pin?uid=${uid}&pin=${pin}${group_exist}`,
    // payload
  );
  return response;
}
export async function getOTPWithPayload(payload) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/get-otp`,
    payload
  );
  return response;
}

export async function getOTPWithPayloadTokenBased(payload, token) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/get-otp`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }
  );
  return response;
}

//register api
export async function registerOTP(payload) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/register`,
    payload
  );
  return response;
}

//verify OTP
export async function verifyMobileOTP(payload) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/verify-otp`,
    payload
  );
  return response;
}

export async function verifyMobileOTPTokenBased(payload, token) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/verify-otp`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }
  );
  return response;
}

export async function bookAppointmentHospital(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/doctors/book-appointment`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function applyCoupon(payload, token) {
  const response = await axiosClient.post(
    `/patients/web/v2/doctors/appointment/apply-coupon`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }
  );
  return response;
}

export async function removeCoupon(payload, token) {
  const response = await axiosClient.post(
    `/patients/web/v2/doctors/appointment/remove-coupon`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }
  );
  return response;
}

export async function removeWallet(payload, token) {
  const response = await axiosClient.post(
    `/patients/web/v2/doctors/patients/remove-apply-wallet`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }
  );
  return response;
}
export async function addWallet(payload, token) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/apply-wallet`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }
  );
  return response;
}

export async function updateFamilyMember(payload, jwt) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/profile/update`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${jwt}`,
      },
    }
  );
  return response;
}


export async function addFamilyMember(payload, jwt) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/family-members/add`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${jwt}`,
      },
    }
  );
  return response;
}

export async function switchPatient(payload, token) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/switch`, payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }
  );
  return response;
}
export async function orderPackage(payload) {
  
  const response = await axiosClient.post(
    `/patients/web/v2/patients/create-order`,
    // `/patients/ios/v2/special-health-offers/confirm-booking`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        aggregatorType: "market_place",
        apitoken: apitoken,
      },
    }
  );
  return response;
}

export async function payLater(payload, token) {
  const response = await axiosClient.post(
    `/patients/web/v2/doctors/pay-later`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }
  );
  return response;
}
export async function compareThyrocare(payload) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/compare-thyrocare-package`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        aggregatorType: "market_place",
        apitoken: apitoken,
      },
    }
  );
  return response;
}
export async function orderProgram(payload) {
  
  const response = await axiosClient.post(

    `/patients/web/v2/special-health-offers/confirm-booking`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        aggregatorType: "market_place",
        apitoken: apitoken,
      },
    }
  );
  return response;
}
export async function searchTextAPI(keyword, type) {
  if (keyword === "") return false;
  let payLoad = {
    keyword: keyword,
    type: type,
  };

  const response = await axiosMHClient.post(
    `/patients/android/v2/global-search`,
    payLoad
  );
  return response;
}
export async function bookHomecarePackage(payload) {
  const response = await axiosMHClient.post(
    `/patients/ios/v2/test/homecare-request-patient`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        aggregatorType: "market_place",
        apitoken: apitoken,
      },
    }
  );
  return response;
}

export async function requestCallback(payload) {
  const response = await axiosMHClient.post(
    `/shared/v1/doctors/request-callback`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // aggregatorType: "market_place",
        // apitoken: apitoken,
      },
    }
  );
  return response;
}


// compair health-checkUp Package
export async function compareHealthComparison(payload) {
  const response = await axiosClient.post(
    `/patients/web/v2/health-checkups-comparison`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        aggregatorType: "market_place",
        apitoken: apitoken,
      },
    }
  );
  return response;
}

export async function compareHealthupRequest(payload) {
  const response = await axiosClient.post(
    `/patients/web/v2/health-checkup-request`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        aggregatorType: "market_place",
        apitoken: apitoken,
      },
    }
  );
  return response;
}


export async function applyCouponHealth(payload, token) {
  const response = await axiosClient.post(
    `/patients/web/v2/doctors/appointment/apply-coupon`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
        // aggregatorType: "market_place",
        // apitoken: apitoken,
      },
    }
  );
  return response;
}

export async function removeCouponHealth(payload, token) {
  const response = await axiosClient.post(
    `/patients/web/v2/doctors/appointment/remove-coupon`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
        // aggregatorType: "market_place",
        // apitoken: apitoken,
      },
    }
  );
  return response;
}