import axiosClient from "./api-handlers";
import mheaaxiosClient from "./api-mheaHandlers"
import {aggregatorType, apitoken, groupId, hospitalgroupId, opdEmr, mheaUrl, is_group_exist} from "../utils/constant";
import opdEmraxiosClient from "./api-OPDEMRHandlers";

export async function appointmentCall(current_page, startDate, endDate, isFamilyMember, hospital_group_id, JWT) {
  //&hospital_group_id=1
  let date = '';
  if(startDate != null && endDate !=null){
      date = `&start_date=${startDate}&end_date=${endDate}`
  }
  let group_exist = '';
 if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
 }
  const response = await axiosClient.get(
    `/patients/web/v2/patients/appointments?type=all${date}&page=${current_page}${isFamilyMember}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}
//delete image in dector consult

export async function deletDectorConsult(id, hospital_group_id, JWT) {
  let group_exist = '';
 if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }
  const response = await axiosClient.post(
    `patients/ios/v2/doctors/delete-documents/${id}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}
///Add Notes/ Documents


export async function addNotesDocuments(payload, id, hospital_group_id, JWT) {
  let group_exist = '';
 if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }
  const response = await axiosClient.post(
    `/patients/web/v2/doctors/upload-documents/${id}${group_exist}`,
    payload,
    {
      "mimeType": "multipart/form-data",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  
  return response;
}


/// show add not details
export async function addNotesDetails(id,hospital_group_id, JWT) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }
  const response = await axiosClient.get(
    `/patients/ios/v2/doctors/appointment-status/${id}?is_booking_details=1${group_exist}`,
  
    {
      // "mimeType": "multipart/form-data",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  
  return response;
}

//delete image
export async function addNotesImgDelete(doc_id, hospital_group_id,JWT) {
  let payload = '';
  if(is_group_exist === true){
    payload ={'hospital_group_id':hospital_group_id, 'group_id':hospital_group_id}
  }
  const response = await axiosClient.post(
    `/patients/android/v2/doctors/delete-documents/${doc_id}`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

// sendFeedBack
export async function sendFeedBack(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/android/v2/call`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}
/// booking
export async function cancelBookingReason(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/android/v2/doctors/cancel-appointment`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}
// add My Prescriptions Order Test
export async function myPrescriptionsOrderTest(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/diagnostic-orders-via-prescription`,
    payload,
    {
      "mimeType": "multipart/form-data",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  
  return response;
}

export async function homeCareCall(current_page, isFamilyMember,hospital_group_id, JWT) {
  let group_exist = '';
 if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}&hospital_id=5`;
 }
  const response = await axiosClient.get(
    `/patients/web/v2/health-checkups/appointments?type=all&service_category=1&page=${current_page}${isFamilyMember}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


export async function diagnosticsCall(current_page, isFamilyMember,hospital_group_id, JWT) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }
  const response = await axiosClient.get(
    `/patients/web/v2/patients/throcare-appointment?page=${current_page}${isFamilyMember}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


export async function diagnosticsShowDetails(booking_code, hospital_group_id, JWT) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }
  const response = await axiosClient.get(
    `/patients/web/v2/patients/diagnostic-booking-showtestdetails?booking_code=${booking_code}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


// For Diagnostic Parameters details
export async function diagnosticsParametersDetails(diagnosis_id, hospital_group_id, pincode) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }  
  const response = await axiosClient.get(
    `/patients/web/v2/patients/thyrocare-test-Details?diagnosis_id=${diagnosis_id}&pincode=${pincode}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}

// For Homecare Parameters details
export async function homecareParametersDetails(hospital_id,hospital_group_id, test_id) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }   
  const response = await axiosClient.get(
    `/patients/android/v2/test/hospital_id/${hospital_id}/test/${test_id}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}

export async function medicinesCall(current_page, isFamilyMember,hospital_group_id, JWT) {
  let group_exist = '';
 if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }   
  const response = await axiosClient.get(
    `/patients/web/v2/patients/medicine-orders?page=${current_page}${isFamilyMember}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

///Medicine track order

export async function MedicineTrackOrder(order_id, vendor_id, contact_number, JWT) { 
  let payload = {'order_id':order_id, 'vendor_id': vendor_id, 'contact_number':contact_number, hospital_group_id:1, group_id:1}
  const response = await axiosClient.post(
    `/patients/android/v2/patients/track-order?order_id=${order_id}&vendor_id=${vendor_id}&contact_number=${contact_number}`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

///Diagnostic track order

export async function DiagnosticTrackOrder(book_code,hospital_group_id, JWT) { 
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
 }   
  const response = await axiosClient.get(
    `/patients/web/v2/patients/throcare-appointment-tracking-order-status?booking_id=${book_code}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

////My Documents
export async function externalDocumentsCall(patient_id, current_page, category_id, hospital_group_id,JWT) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
 } 
  const response = await axiosClient.get(
    `/patients/web/v2/getPhrDetails?patient_id=${patient_id}&page=${current_page}${category_id}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

///add Document externalDocument
export async function addExternalDocumentUpload(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/ios/v2/uploadDocuments`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

// delete External Documents Data in my Document
export async function ExternalDataDelete(doc_id,hospital_group_id, JWT) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }   
  const response = await axiosClient.get(
    `patients/android/v2/delete-record/${doc_id}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function myAppointmentsCall(current_page,hospital_group_id, JWT) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
 } 
  const response = await axiosClient.get(
    `/patients/web/v2/patients/appointments?type=all&is_documents=1&page=${current_page}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function myReportsCall(type,current_page, hospital_group_id, JWT) { 
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
 } 
  const response = await axiosClient.get(
    // `/patients/web/v2/patients/throcare-report?page=${current_page}${group_exist}`,
    `/patients/android/v2/patient-test-result-appointments?&type=${type}&page=${current_page}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}
// my Report Date Filter

export async function myReportsDateFilter(start_date, end_date,hospital_group_id, JWT) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  } 
  const response = await axiosClient.get(
    `/patients/android/v2/patients/throcare-report?start_date=${start_date}&end_date=${end_date}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

/// WalletHistory
export async function WalletHistoryCall(JWT) { 
  const response = await axiosClient.get(
    `/patients/web/v2/patients/wallet-history`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

// mybills
export async function PatientMyBillsCall(payload,JWT) {
  const response = await axiosClient.post(
    // `/LIS_RIS_Report/InvInvoiceReportList`,
    `/patients/ios/v2/patient/get-push-bills-generated`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}

// DischargeSummary
export async function myDischargeSummary(id,hospital_group_id, pageid, JWT) {

  const response = await opdEmraxiosClient.get(
    `/ipdemr/discharge_patient_summary_status_patient_id/${id}?hospital_group_id=${hospital_group_id}?page=${pageid}&allow_pagination=Y`,
    // `/ipdemr/discharge_patient_summary_status_patient_id/${id}?hospital_group_id=${hospital_group_id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function MemberProfileDelete(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/family-members/delete`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function MemberProfileEdit(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/profile/update`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

//// myPrescription
export async function myPrescriptionHomecareCall(current_page, isFamilyMember,hospital_group_id, JWT) { 
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
 } 
  const response = await axiosClient.get(
    `/patients/web/v2/patient-documents-by-hospital?is_homecare=1&page=${current_page}&family_member_id=${isFamilyMember}${group_exist}`,
    // `/patients/web/v2/patient-documents-by-hospital?is_homecare=1&page=${current_page}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function myPrescriptionDocConsultCall(current_page, isFamilyMember, hospital_group_id,JWT) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
 } 
  const response = await axiosClient.get(
    `/patients/web/v2/patient-documents-by-hospital?is_homecare=0&page=${current_page}&family_member_id=${isFamilyMember}${group_exist}`,

    // `/patients/web/v2/patient-documents-by-hospital?is_homecare=0&page=${current_page}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

/// order Track
export async function myPrescriptionTrackOrder(book_code, JWT) { 
  const response = await axiosClient.get(
    `/patients/web/v2/patients/throcare-appointment-tracking-order-status?booking_id=${book_code}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

// For Hospital Country List
export async function countryList(hospital_group_id) { 
  let group_exist = '';
  if(is_group_exist === true){
   // group_exist = `?&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }   
  const response = await axiosClient.get(
    `/patients/web/v2/patients/country${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `${JWT}`,
      },
    }
  );
  return response;
}
// For Hospital State
export async function countryState(country_id, hospital_group_id) { 
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
 }   
  const response = await axiosClient.get(
    `/patients/web/v2/patients/state?&country_id=${country_id}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

// For Hospital City
export async function stateCity(state_id, hospital_group_id) { 
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&group_id=${hospital_group_id}`;
 }  
  const response = await axiosClient.get(
    `/patients/web/v2/patients/city?state_id=${state_id}${group_exist}`,    
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


export async function SendMedicinePrescription(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/partner-diagnostic-orders-via-prescription`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}



//healthcheckups List
export async function healthcheckups(sid, current_page, isFamilyMember, hospital_group_id, hid, JWT) {
  let group_exist = '';
 if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}&hospital_id=${hid}`;
 }
  const response = await axiosClient.get(    
    `/patients/web/v2/health-checkups/appointments?type=all&service_category=${sid}&page=${current_page}${isFamilyMember}${group_exist}`, 
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}
export async function cancelBookingReasonHealth(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/health-checkups/cancel`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", 
        Authorization: `${JWT}`,
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}