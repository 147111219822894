import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const bookPackagedetails = createSlice({
  name: "bookPackagedetails",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setBookPackagedetailsReducer: (state, action) => {
      state.value = action.payload;
    },

    clearBookPackagedetailsReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setBookPackagedetailsReducer, clearBookPackagedetailsReducer } = bookPackagedetails.actions;
export default bookPackagedetails.reducer;
