import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const radiologyPackageDetails = createSlice({
  name: "radiologyPackageDetails",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setRadiologyPackageDetailsReducer: (state, action) => {
      state.value = action.payload;
    },

    clearRadiologyPackageDetailsReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setRadiologyPackageDetailsReducer, clearRadiologyPackageDetailsReducer } = radiologyPackageDetails.actions;
export default radiologyPackageDetails.reducer;
