import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";

import locationSlice from "./slice/location-slice";
import deliverySlice from "./slice/delivery-address";

import userProfile from "./slice/user-profile";
import allUsers from "./slice/all-users";
import callToken from "./slice/call-token";
import switchToken from "./slice/switch-token";
import selectedUser from "./slice/selected-user";
import memberProfile from "./slice/member-profile";
import selectedUserCard from "./slice/selected-userCard";
import hospitalAddressList from "./slice/group-hospitals";
import currentLocation from "./slice/current-location";
import reschedule from "./slice/reschedule";
import selectedMemberId from "./slice/selected-member-id";
import selectedMembers from "./slice/selected-members";
import selectedPincode from "./slice/selected-pincode";
import bookingSource from "./slice/booking-source";
import packageDetail from "./slice/package-detail";
import { packageOrderHistory } from "./slice/package-order-id";
import vendor from "./slice/vendor";
import programHospital from "./slice/program-hospital";
import { homeCareImg } from "./slice/homecare-serviceImg";
import healthCheckUpTestDetail from "./slice/healthCheckUp-testDetail";
import  bookPackagedetails  from "./slice/bookPackagedetails";
import radiologyPackageDetails from "./slice/radiologyPackageDetails"

const rootReducer = combineReducers({
  allUsers: allUsers,
  callToken: callToken,
  switchToken: switchToken,
  selectedUser: selectedUser,
  userProfile: userProfile,
  location: locationSlice,
  delivery: deliverySlice,
  memberProfile: memberProfile,
  selectedUserCard:selectedUserCard,
  hospitalAddressList:hospitalAddressList,
  currentLocation:currentLocation,
  reschedule:reschedule,
  bookingSource:bookingSource,
  packageDetail:packageDetail,
  packageOrderHistory:packageOrderHistory,
  selectedMemberId:selectedMemberId,
  selectedMembers:selectedMembers,
  selectedPincode:selectedPincode,
  vendor: vendor,
  programHospital:programHospital,
  homeCareImg:homeCareImg,
  healthCheckUpTestDetail:healthCheckUpTestDetail,
  bookPackagedetails:bookPackagedetails,
  radiologyPackageDetails:radiologyPackageDetails
  
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
