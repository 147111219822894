import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Tabs, Tab } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";

import { Link, useNavigate } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import PatientMyDocumentExternalDocument from "../patientMyDocument/patientMyDocumentExternalDocument";
import PatientMyDocumentMyAppointments from "../patientMyDocument/patientMyDocumentMyAppointments";
import {
  externalDocumentsCall,
  myAppointmentsCall,
} from "../../actions/dashboard-api";
import PdfIcon from "../../assets/images/mhc-pdf.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import LodingComponent from "../../component/loding";
import NoMoreData from "../../component/no-more-data/noMoreData";
import {
  Dropdown,
  DropdownButton,
  Col,
  Form,
  Button,
  Popover,
} from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ShareIcon from "../../assets/images/share-icon.svg";
import { Modal } from "react-bootstrap";
import { configurations } from "../../actions/get-calls";
import { ToastContainer, toast } from "react-toastify";
import { addExternalDocumentUpload } from "../../actions/dashboard-api";
import moment from "moment";
import { array } from "yup";
import DashboardLink from "../../component/dashboard-link";
import { APP_SUB_FOLDER, DefaultMetaTag, hospitalName } from "../../utils/constant";
import Helmet_SEO_META from "../../component/seo_meta";
const breadcrumArrry = [
  {
    name: "My Dashboard",
    url: "/my-dashboard",
  },
  {
    name: "My Documents",
    url: "",
  },
];
const PatientMyDocument = () => {
  const switchToken = useSelector((state) => state.switchToken.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
  const [doccurrentTab, setDocCurrentTab] = useState("ExternalDocuments");

  // For External Documents Hooks
  const [externalDocuments, setExternalDocuments] = useState();
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const [extPagination, setExtPagination] = useState(1);
  const [extMore, setExtMore] = useState(true);
  const [extPage, setextPage] = useState(1);
  const [extLoder, setExtLoder] = useState(false);

  //filer and Add Document Hooks
  const [modal, setModal] = useState(false);
  const [addDocument, setAddDocument] = useState();
  const [category_id, setCategory_id] = useState();
  const [filterDocType, setfilterDocType] = useState(null);
  const [filterList, setfilterList] = useState();
  const [PopClose, setPopClose] = useState(false);
  const [hasCheckBox, setHasCheckBox] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const maxNumber = 8;
  const filesize=2242880;
  const [docAgree, setDocAgree] = useState(0);

  // For My Appointments Hooks
  const [myAppointments, setMyAppointments] = useState();
  const [pagination, setPagination] = useState();
  const [hasMore, setHasMore] = useState(true);
  let [myAppointmentsPage, setMyAppointmentsPage] = useState(1);
  const [myAppointmentsLoader, setMyAppointmentsLoader] = useState(false);

  const allUsers = useSelector((state) => state.allUsers.value);
  const navigate = useNavigate();
  useEffect(() => {
    if (!allUsers && !selectedUserCard) {
      navigate(`${APP_SUB_FOLDER}/login`);
      return;
    }
  }, []);

  let patient_id;
  if (selectedUserCard) {
    patient_id = selectedUserCard.id;
  }

  useEffect(() => {
    // For External Documents
    setExternalDocuments();
    setMyAppointments();
    externalDocumentsCall(
      patient_id,
      extPage,
      "",
      currentLocation.hospital_group_id,
      switchToken
    )
      .then(function (response) {
        setTimeout(() => {
        setExtPagination(response.data.pagination);
        setExternalDocuments(response.data.records);
      }, "500");
      })
      .catch(function (err) {
        console.log(err);
      });
    // For My Appointments
    myAppointmentsCall(
      myAppointmentsPage,
      currentLocation.hospital_group_id,
      switchToken
    )
      .then(function (response) {
        setTimeout(() => {
        setPagination(response.data.pagination);
        setMyAppointments(response.data.appointments);

          }, "500");
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [switchToken, selectedUserCard]);

  useEffect(() => {
    setExternalDocuments(externalDocuments);
  }, [externalDocuments]);

  useEffect(() => {
    // For Document Type Filter List
    configurations()
      .then(function (response) {
        setfilterDocType(response.data);
      })
      .catch(function (err) {});
  }, []);

  // For External Documents  More Data
  const fetchMoreData = () => {
    //  alert("hello")
    if (
      doccurrentTab === "MyAppointments" &&
      myAppointmentsPage < pagination.total_pages
    ) {
      setMyAppointmentsLoader(true);
      let nextPage;
      nextPage = myAppointmentsPage + 1;
      setMyAppointmentsPage(nextPage);
      myAppointmentsCall(
        nextPage,
        currentLocation.hospital_group_id,
        switchToken
      ).then(function (response) {
        setMyAppointments(myAppointments.concat(response.data.appointments));

        setMyAppointmentsLoader(false);
      });
    } else {
      setHasMore(false);
    }
  };

  // For My Appointments More Data
  const fetchExternalData = () => {
    if (
      doccurrentTab === "ExternalDocuments" &&
      externalDocuments < pagination.total_pages
    ) {
      setExtLoder(true);
      let extNextPage;
      extNextPage = extNextPage + 1;
      setMyAppointmentsPage(extNextPage);
      externalDocumentsCall(
        patient_id,
        extNextPage,
        "",
        currentLocation.hospital_group_id,
        switchToken
      ).then(function (response) {
        setExternalDocuments(response.data.records);
        setExtLoder(false);
      });
    } else {
      setExtMore(false);
    }
  };

  /// add document and filter functon
  const handleAddDocumentShow = () => {
    setAddDocument();
    setCategory_id();
    setModal(true);
  };

  const handlefilterList = () => {
    setPopClose(true);
  };

  const handleAddDocumentClose = (e) => {
    setModal(false);
  };

  const onChange = (imageList, addUpdateIndex) => {
    setAddDocument(imageList);
  };
  const uploadError = (errors) => {
    if (errors && errors.maxFileSize == true) {
      return showError("File size must be less than to 2MB.");
    }
    if (errors && errors.maxNumber ==  true) {
      return showError("Maximum " + maxNumber + " images are allowed.");
    }
  };
  const getRadioButtonValue = (e) => {
    setCategory_id(e.target.value);
  };

  const filtterReset = (e) => {
    var clist = document.querySelectorAll('[name="filter"]');
    for (var i = 0; i < clist.length; ++i) {
      clist[i].checked = false;
    }
    setHasCheckBox([]);
    externalDocumentsCall(
      patient_id,
      extPage,
      "",
      currentLocation.hospital_group_id,
      switchToken
    )
      .then(function (response) {
        setExternalDocuments(response.data.records);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const filterCategoryId = () => {
    let mapCategoryID = "";
    const catID = [];
    var clist = document.querySelectorAll('[name="filter"]:checked');
    for (var i = 0; i < clist.length; ++i) {
      mapCategoryID += "&category_id[]=" + clist[i].value;
      catID.push(parseInt(clist[i].value));
      setHasCheckBox(catID);
    }

    externalDocumentsCall(
      patient_id,
      extPage,
      mapCategoryID,
      currentLocation.hospital_group_id,
      switchToken
    )
      .then(function (response) {
        setExternalDocuments(response.data.records);
      })
      .catch(function (err) {
        console.log(err);
      });
    setPopClose(false);
  };

  const onDocAgreeHandle = (e) => {
    if (e.target.checked == true) {
      setDocAgree(1);
    } else {
      setDocAgree(0);
    }
  };

  const uploadNewDocument = (e) => {
    e.preventDefault();
    var payload = new FormData();
    if (document.querySelector("#document_name").value == "") {
      showError("Document name is required");
      return false;
    }
    if (addDocument == undefined) {
      showError("Please Upload one or more files to proceed");
      return false;
    }
    if (category_id == undefined) {
      showError("Document Category is required");
      return false;
    }

    payload.append("record_type", 1);
    payload.append("document_name", e.target.elements.document_name.value);
    payload.append("category_id", category_id);
    payload.append("patient_id", selectedUserCard.id);
    payload.append("name", selectedUserCard.name);
    payload.append("record_date", moment(new Date()).format("YYYY-MM-DD"));

    addDocument?.map((item) => {
      if (item.file != undefined) {
        payload.append("documents[]", item.file);
      }
    });

    addExternalDocumentUpload(payload, switchToken)
      .then(function (response) {
        if (response?.statusText == "OK" || response?.status == 200) {
          externalDocumentsCall(
            patient_id,
            1,
            "",
            currentLocation.hospital_group_id,
            switchToken
          )
            .then(function (response) {
              setExternalDocuments(response.data.records);
            })

            .catch(function (err) {
              console.log(err);
            });
          setPopClose(false);

          setModal(false);
          return showToast("Documents added successfully");
        } else {
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }
  function showToast(str) {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }
  const pageURL = window.location.origin + window.location.pathname;
  const [SEO_META, setSEO_META] = useState({
    title: DefaultMetaTag.title,
    description: DefaultMetaTag.description,
    keywords: DefaultMetaTag.keyword,
    pageURL: pageURL,
  });

  return (
    <React.Fragment>
      <Helmet_SEO_META SEO_META={SEO_META} />
      <Container className="min-body-height">
        <div className="row  align-items-center breadcrumb_sticky">
          <div className="col-md-9">
            {" "}
            <Breadcrumb textUrl={breadcrumArrry} />{" "}
          </div>
          <div className="col-md-3 d-none d-md-block">
            <DashboardLink />
          </div>
        </div>

        <SwitchProfile />

        <div className="row mt-4">
          <div className="col-12">
            <h1 className="fs-4 mb-1 text-secondary">My Documents</h1>
            <p className="m-0 fs-6">Self-upload your health documents</p>
          </div>
        </div>
        <div className="mhc-tabs position-relative mt-4">
          <Tabs
            activeKey={doccurrentTab}
            onSelect={(key) => setDocCurrentTab(key)}
            id="controlled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="ExternalDocuments" title="External Documents">
              <div className="row d-flex justify-content-end">
                <div className="col-auto">
                  <a
                    className="btn btn-outline py-1 btn-outline-custom text-decoration-none fs-7"
                    onClick={handleAddDocumentShow}
                  >
                    Add Document
                  </a>
                </div>
                <div className="col-auto ">
                  <OverlayTrigger
                    trigger="click"
                    key={"1"}
                    placement={"left"}
                    rootClose={true}
                    show={PopClose}
                    overlay={
                      <Popover
                        id="popover-positioned-bottom"
                        className="filter_pop"
                      >
                        <Popover.Body>
                          <div className="container">
                            <div className="row">
                              <div className="col-12">
                                <h6 className="mb-0">Filter</h6>
                              </div>
                            </div>
                            <div className="row">
                              {filterDocType?.document_categories?.map(
                                (item, i) => {
                                  return (
                                    <div className="col-12 mt-3" key={i}>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="filter"
                                          defaultChecked={
                                            hasCheckBox.length > 0 &&
                                            Array.from(hasCheckBox).includes(
                                              item.id
                                            ) == true
                                              ? "checked"
                                              : ""
                                          }
                                          defaultValue={item.id}
                                          id={"doc_" + item.id}
                                        />
                                        <label
                                          className="form-check-label d-block d-sm-inline"
                                          htmlFor={"doc_" + item.id}
                                        >
                                          {" "}
                                          {item.category_name}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>

                            <div className="row justify-content-between mt-3">
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary"
                                  onClick={(e) => filtterReset(e)}
                                >
                                  Reset
                                </button>
                              </div>
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary rounded-pill"
                                  onClick={filterCategoryId}
                                >
                                  Apply Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      variant=""
                      className="btn-filters"
                      type="button"
                      onClick={(e) => handlefilterList(e)}
                    >
                      <img
                        src={`${APP_SUB_FOLDER}${ShareIcon}`}
                        alt={"share"}
                        className="share-icon-size"
                      />
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
              {externalDocuments?.length == 0 ? (
                <h6 className="text-center mt-2 mb-4 ">No Data Found</h6>
              ) : externalDocuments === undefined ? (
                <LodingComponent className="mhc-Loding-minHeight" />
              ) : (
                <div id="ExternalDocuments" className="mhc-scrollDiv">
                  <InfiniteScroll
                    dataLength={externalDocuments.length}
                    next={fetchExternalData}
                    hasMore={extMore}
                    loader={
                      extLoder == true ? (
                        <LodingComponent className="text-center" />
                      ) : (
                        ""
                      )
                    }
                    scrollableTarget="ExternalDocuments"
                    endMessage={
                      extPagination.total_pages > 1 ? (
                        <NoMoreData className="text-center mb-3" />
                      ) : (
                        ""
                      )
                    }
                  >
                    {
                      <PatientMyDocumentExternalDocument
                        externalDocuments={externalDocuments}
                      />
                    }
                  </InfiniteScroll>
                </div>
              )}
            </Tab>
            <Tab eventKey="MyAppointments" title="My Appointments">
              {myAppointments?.length == 0 ? (
                <h6 className="text-center mt-2 mb-4">No Data Found</h6>
              ) : myAppointments === undefined ? (
                <LodingComponent className="mhc-Loding-minHeight" />
              ) : (
                <div id="myAppointments" className="mhc-scrollDiv">
                  <InfiniteScroll
                    dataLength={myAppointments.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={
                      myAppointmentsLoader == true ? (
                        <LodingComponent className="text-center" />
                      ) : (
                        ""
                      )
                    }
                    scrollableTarget="myAppointments"
                    endMessage={
                      pagination.total_pages > 1 ? (
                        <NoMoreData className="text-center mb-3" />
                      ) : (
                        ""
                      )
                    }
                  >
                    {
                      <PatientMyDocumentMyAppointments
                        documentList={myAppointments}
                      />
                    }
                  </InfiniteScroll>
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </Container>
      <Modal show={modal} size="lg" onHide={handleAddDocumentClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Add Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => uploadNewDocument(e)}>
            <div className="row">
              <div className="col-12">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="document_name"
                    name="document_name"
                    autoComplete="off"
                  />
                  <label htmlFor="document_name">Enter Document Name</label>
                </div>
              </div>
            </div>

            <div className="mt-0">
              <div className="mhc-multiImage">
                <ImageUploading
                  multiple
                  name="file_upload[]"
                  value={addDocument}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["jpg", "gif", "png", "pdf"]}
                  allowNonImageType={true}
                  maxFileSize={filesize}
                  onError={uploadError}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <div className="d-flex flex-wrap">
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                           <img src={ image["data_url"].includes("application/pdf")== true? PdfIcon: image["data_url"].split('.').slice(-1)[0] == "pdf" ? PdfIcon :image["data_url"]} alt={image.pic_id} width="100"  />
                            <div className="image-item__btn-wrapper">
                              <button     type="button"
                                onClick={() => onImageRemove(index)}
                                className="close"
                              ></button>
                            </div>
                          </div>
                        ))}

                        <button
                          className="btn mhc-add-more"
                          type="button"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          disabled={imageList.length == maxNumber}
                          {...dragProps}
                        >
                          <br /> Add More
                        </button>
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
            <p className="fs-7  fw-bolder mb-0 mt-2 mt-md-3">
              Select Document Category
            </p>
            <ul className="list-unstyled d-flex flex-wrap mt-4">
              {filterDocType?.document_categories?.map((item, i) => {
                return (
                  <li className="me-2 mb-2 document_category" key={i + 1}>
                    <div
                      className={`form-check btn btn-outline-secondary position-relative   ${
                        item.id == category_id ? "active" : ""
                      }`}
                    >
                      <input
                        className="form-check-input maxking-radio"
                        type="radio"
                        name="category_id"
                        id={item.id}
                        required=""
                        defaultValue={item.id}
                        onChange={(e) => getRadioButtonValue(e)}
                      />
                      <label
                        className="form-check-label d-block d-sm-inline cursor-pointer"
                        htmlFor={item.id}
                      >
                        {item.category_name}{" "}
                      </label>
                    </div>
                  </li>
                );
              })}
            </ul>
            <p className="fs-7  fw-bolder mb-2 mt-3">Patient Consent</p>
            <p>
              By confirming the order I consent to my prescription being shared
              with {hospitalName} pharmacies for home delivery of medicines.
            </p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue={docAgree}
                id="docAgree"
                name="docAgree"
                onChange={(e) => onDocAgreeHandle(e)}
              />
              <label
                className="form-check-label d-block d-sm-inline "
                htmlFor="docAgree"
              >
                I Agree
              </label>
            </div>
            <button
              type="submit"
              className="btn btn-custom rounded-1  mt-3"
              disabled={docAgree == 1 ? false : true}
            >
              Confirm{" "}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default PatientMyDocument;
