import React from "react";
import { configurations, healthCheckuppayOnNow } from "../../actions/get-calls";
import { apiPATH } from "../../utils/constant";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { cancelBookingReason, cancelBookingReasonHealth } from "../../actions/dashboard-api";
import { toast } from "react-toastify";
const HealthCheckupsList=({data})=>{
    const currentLocation = useSelector((state) => state.currentLocation.value);
    const switchToken = useSelector((state) => state.switchToken.value);
    const [cancleModal, setCancleModal] = React.useState(false);
    const [cancelReason, setCancelReason] = React.useState();
    const [cancelReasonValue, setCancelReasonValue] = React.useState();
    const [cancelText, setCancelText] = React.useState();
    const [cancelBookingID, setCancelBookingID] = React.useState();
    const payNow =async(item)=>{     
        await healthCheckuppayOnNow(item.id)
        .then(function(response){             
               window.open(apiPATH + item.actions[0]?.uri, '_self').focus();
       
        })
        .catch(function (err) {         
       });
      }
      const handleReasonOnchange = (e) => {
        setCancelReasonValue(parseInt(e.target.value));
      };
      const handleReasonTest = (e) => {
        setCancelText(e.target.value);
      };
      const handleCancelAppotinment = (bookingid) => {
        configurations().then(function (response) {        
          setCancelReason(response.data.phc_cancellation_reasons);
        });
        setCancleModal(true);
        setCancelBookingID(parseInt(bookingid));
      };
      const handleCalcleClose = () => {
        setCancleModal(false);
      };

      const postHandleCancelReason = () => {
        const payload = {
          hospital_group_id: currentLocation.hospital_group_id,
          group_id: currentLocation.hospital_group_id,
          hospital_id: currentLocation.hospitalID,
          booking_id: cancelBookingID,
          cancellation_reason: cancelReasonValue,
          other_reason: cancelText,
        };
        cancelBookingReasonHealth(payload, switchToken)
          .then(function (response) {
            if(response.statusText == "OK" || response.status == 200){
              setCancleModal(false);
               let getEle = document.querySelector("#id_" + cancelBookingID);          
               let ctaCancle = getEle.querySelector(".cta_cancel");
               let ctaPay= getEle.querySelector(".cta_pay");
              let txtStatus = getEle.querySelector(".booking_status");    
    
               ctaCancle.style.display = "none";
               ctaPay.style.display="none"
              txtStatus.classList.add("text-danger");
              txtStatus.innerText = "Cancelled ";
    
              return showToast(response.data.message);
            }
    
            if (response.statusText === "Bad Request" || response.status === 400) {
              setCancleModal(false);
              return showError(response.data.errors[0].message);
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      };
      function showError(str) {
        toast.error(str, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
    
      function showToast(str) {
        toast.success(str, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
    return(
        <React.Fragment>
            {
              data?.map((item, i)=>{

                    return(
                        <div
                        id={`id_` + item.id}
                        className="mhc-patient-details-card mhc-homecare"
                        key={i + 1}
                        >
                        
                        <div className="row">
                            <div className="col-md-4 col-lg-4">
                                <p className="mb-1 text-primary fw-bold">{item.booking_date}</p>
                                <p className="mb-0 tittle ">{item.patient_name}</p>
                            </div>
                            <div className="col-md-4 col-lg-4 mt-3 mt-md-0 text-start ">       
                                <p className="mb-0 tittle ">{item.health_checkup.title}</p>
                                <p className="mb-0 ">{item.booking_code}</p>
                            </div>
                    
                            <div className="col-md-4  col-lg-4 mt-3 mt-md-0 text-start text-md-end">
                            <p
                  className={`mb-1 fw-bolder booking_status ${
                    item.booking_status === "Upcoming" ? "d-none1" : ""
                  }  ${
                    item.booking_status === "Pending" ||
                    item.booking_status === "PENDING"
                      ? "text-primary"
                      : item.booking_status === "Canceled" ||
                        item.booking_status === "CANCELLED"
                      ? "text-danger"
                      : item.booking_status === "Missed" ||
                        item.booking_status === "MISSED"
                      ? "text-missed"
                      : "text-success"
                  }`}
                >
                  {item.booking_status
                   .replace("Canceled", "Cancelled")
                   .replace("Arrived", "Paid")
                   .replace(
                    "Confirmed",
                    item.payment_status == "pay on arrival"
                      ? "Pay on arrival" : "Paid"
                     
                  )
                  }
                </p>
                                <p className="mb-0 ">{item.amount}</p>
                            </div>
                            <div className="col-12">
                                {
                                    item.booking_status=="Confirmed" ? <button className="btn cta_cancel btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2"    onClick={() => handleCancelAppotinment(item.id)}>cancel</button>:''

                                }
                                {
                                    item.payment_status=="pay on arrival" &&   item.booking_status=="Confirmed"  ? <button className="btn cta_pay btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2" onClick={()=>payNow(item)}>Pay now</button>:''

                                }
                            </div>
                        </div>  
                       </div>
                    )
                })
            }
                        <Modal show={cancleModal} onHide={handleCalcleClose} size="md">
        <Modal.Body>
          <h3 className="text-secondary fs-7">
            Please let us know reason for cancellation
          </h3>
          <ul className="list-unstyled p-0">
            {cancelReason?.map((reason) => (
              <li>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    value={reason.id}
                    onChange={(e) => handleReasonOnchange(e)}
                    type="radio"
                    name="flexRadioDefault"
                    id={reason.id}
                  />
                  <label
                    className="form-check-label d-block d-sm-inline"
                    for={reason.id}
                  >
                    {reason.name}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          {cancelReasonValue == 9 ? (
            <div className="form-floating mb-4 mt-4">
              <input
                type="text"
                className="form-control"
                placeholder="Reason"
                onChange={(e) => handleReasonTest(e)}
              />
              <label htmlFor="first_name">
                Reason <sup>*</sup>
              </label>
            </div>
          ) : (
            ""
          )}

          <div className="w-100 text-center">
            <button
              className="btn btn-outline btn-outline-secondary  rounded-1 me-3 "
              onClick={handleCalcleClose}
            >
              Dismiss
            </button>
            <button
              type="submit"
              onClick={postHandleCancelReason}
              className="btn btn-custom rounded-1  "
            >
              Cancel Appointment{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>  
        </React.Fragment>
    )
}

export default HealthCheckupsList