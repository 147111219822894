import React from "react";
import bloodTest from "../../assets/images/Blood Test CBC.svg";
import UltrasoundWholeAbdomen from "../../assets/images/Ultrasound Whole Abdomen.svg";
import DownloadIcon from "../../assets/images/download-btn.svg";
import Pickadate from "pickadate/builds/react-dom";
import LodingComponent from "../../component/loding";
import moment from "moment";
import { saveAs } from "file-saver";
import { APP_SUB_FOLDER } from "../../utils/constant";

const PatientMyReportData = ({ reportList, tabKey }) => {


  const savePdf = (e, url) => {   
    saveAs(url, "myreport.pdf");
  };

  return (
    <React.Fragment>
         

      <div className="table-responsive">
        <table className="table mhc-document-table">
          <thead>
            <tr>
              <th>Description</th>
              {/* <th>Type</th> */}
              <th>Order Date & Time</th>
              <th className="text-end">Test Status</th>
            </tr>
          </thead>
       

          <tbody>
            {reportList.map((item, i) => {
        
              let date = moment(item.booking_date).format("DD MMM, YYYY");
              let time = moment(item.booking_date).format("LT");
              let reportType = item.report_type;
              let url = item.resultLink;
              return (
                <>
                  <tr eventkey={item.id} key={i + 1}>
                          <td>{item.test_name}</td>
                          {/* <td>{item.test_type}</td> */}
                          <td>  {date},&nbsp;{time}</td>
                          <td className="text-end">{item.test_status}</td>
                  </tr>
                </>
              );
            })}

          
          
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default PatientMyReportData;
