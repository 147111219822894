import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";


import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { ExternalDataDelete } from "../../actions/dashboard-api";
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true
};

  const PatientMyDocumentExternalDocument = ({ externalDocuments}) => {
   
  // const selectedUser = useSelector((state) => state.selectedUser.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);
  // const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
  // let patient_id = selectedUserCard.id;
  const [imaData, setiMaData] = useState();
  const [show, setShow] = useState(false);
const [deleteModal, setDeleteModal]=useState(false)
const [record_id, setRecord_id]=useState()
 
const [getexternalDocuments, setExternalDocuments] = useState(externalDocuments)

useEffect(() => {
  setExternalDocuments(externalDocuments)
},[getexternalDocuments, externalDocuments])


  const handleClose = () => setShow(false);
  const handlerSlider = (e, url) => {
    e.preventDefault();
    let geturl = url;
    setiMaData(geturl)
    setShow(true)   
  }

  const handleDeleteModal=(e, record_id)=>{
    setDeleteModal(true)
    setRecord_id(record_id)
  }
 const closeDeleteModal=()=>{
  setDeleteModal(false)
  setRecord_id()
 }
 const deleteRecord=()=>{
      ExternalDataDelete(record_id, currentLocation.hospital_group_id, switchToken).then(function (response) {   
      if(response.data.status == true){
        let arr =  getexternalDocuments;
        let index = arr.findIndex(x=>x.record_id=== record_id);
        let newArr = arr.splice(index, 1);
        setExternalDocuments(newArr)
        return showToast(response.data.message); 
      }else{
        return showError("Delete failed. Please try again later");  
      }
       

      })
      .catch(function (err) {
        console.log(err);
      });
setDeleteModal(false)
 }
 function showError(str) {
  toast.error(str, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });
}

 function showToast(str) {
  toast.success(str, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });
}
  return (
    <React.Fragment>
  
      {/* <p className="">           <Link to="" onClick={handleAddDocumentShow}>Add Document</Link></p> */}

      <div className="table-responsive">
        <table className="table mhc-document-table">
          <thead>
            <tr>
              <th>Document Type</th>
              <th>Documents</th>
              <th>Date & Time</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              
              getexternalDocuments?.map((item, i) => {
              
                  let img = item.documents;
                  let date = moment(item.record_date).format('DD MMM YYYY')
                  let day = moment(item.record_date).format('ddd')
                  return (
                    <tr key={i+1}>
                      <td>
                            {item.documents.length > 0 ? <Link className="text-secondary text-decoration-none" to="" onClick={(e) => handlerSlider(e, img)}><img src={item.cat_image} alt={item.cat_image} className="test-icon" /> {item.document_name}</Link>
                              : <><img src={item.cat_image} alt={item.cat_image} className="test-icon" /> {item.document_name}</>
                            }
                      </td>
                      <td>{item.documents.length} {item.documents.length > 1 ? "Documents" : 'Document'}</td>
                      <td>{day}, {date} | {item.document_time}</td>
                      <td className="text-end">{item.documents.length > 0 ? <i className="mhc-icon icon-eye text-document-icon fs-13 cursor-pointer  me-2" onClick={(e) => handlerSlider(e, img)}></i> : ''} <i className="mhc-icon icon-trash-empty text-document-icon fs-4 cursor-pointer" onClick={(e)=>handleDeleteModal(e, item.record_id)}></i></td>
                    </tr>
                  )
                })
            }


          </tbody>
        </table>
      </div>
      <Modal show={show} onHide={handleClose} className="mhc-imgPopup"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="minHeight5">


          <Slider {...settings}>
      
            {
              imaData === undefined ? "" : imaData.map((itemm, i) => (
                <div key={i}>
            
                  <embed type="" src={itemm.document_path} className="popup-img" />
                </div>
              ))
            }


          </Slider>
        </Modal.Body>

      </Modal>
      <Modal show={deleteModal} onHide={closeDeleteModal} className="mhc-imgPopup"
        size="sm"
        centered
      >
     
        <Modal.Body>
          <div className="text-center py-2">
          <p className="mb-3">Are you sure you want to delete this Record?</p>


<button className="btn btn-outline-custom py-1 px-4 me-3" onClick={closeDeleteModal}>No</button>
<button className="btn btn-custom rounded-pill  py-1 px-4" onClick={()=>deleteRecord()}>Yes</button>
          </div>
                  
        </Modal.Body>

      </Modal>
     
    </React.Fragment>
  )
}

export default PatientMyDocumentExternalDocument