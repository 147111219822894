import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';


import videoCall from "../../assets/images/video-call.svg";
import print from "../../assets/images/print.svg";

import { useDispatch, useSelector } from 'react-redux';
import { changeDayString } from '../../utils/fliters';
import { APP_SUB_FOLDER, showConsultType } from '../../utils/constant';
import { setcurrentLocationReducer } from '../../redux/slice/current-location';



export default function Doctors({ doctors, consultType, specialities, sId, Id }) {
  const hospitalAddressList = useSelector((state) => state.hospitalAddressList.value);
  const currentLocation = useSelector((state) => state.currentLocation.value); 
  const dispatch = useDispatch();

  useEffect(()=>{
      if (currentLocation && Id != currentLocation.hospitalID) {
            let index = hospitalAddressList.findIndex(function(e) {
                  return e.hospitalID == Id;
            })
            dispatch(setcurrentLocationReducer(hospitalAddressList[index]))

      }
      if (!Id) {
      window.location.href = `${APP_SUB_FOLDER}/`;
      }
  },[])




  const displayDoctors = () => {
    let docs;
    if (consultType === "0") docs = doctors;
    if (consultType === "1") docs = doctors.filter((doc) => doc.is_active_vc == 1);
    if (consultType === "2") docs = doctors.filter((doc) => doc.is_active_opd == 1);

    if(doctors && doctors.length>0 ){
      return (
        <>
        {
          docs?.length>0 ?
          docs?.map((item) => {
            return (
              <div className="col-md-6 col-lg-6 col-xl-4 mb-4" key={item.id}>
                <div className="mhc-dector-card">
                  <div className="row">
                    <div className="col-9">
                      <div className="d-flex align-items-center">
                        <div className="img-holder">
                          <img src={item.profile_image}></img>
                        </div>
                        <div className="ms-3">
                          <p className="m-0 fw-bolder  text-secondary text-tittle">
                            {item.name}  
                          </p>
                          <p className="text-secondary sub-text">
                            {item.designation} <br />
                            {item.experience > 0? `Exp. ${item.experience}  ${item.experience >1 ? "Yrs":"Yr"}`: null}
                            
                          </p>
                        </div>
                      </div>
                    </div>
                    {
                           showConsultType===true?<div className="col-3 text-end">
                           <ul className="list-unstyled mb-0 d-flex justify-content-end">
                             {item.is_active_vc === 1 && (
                               <li>
                                 <img src={`${APP_SUB_FOLDER}${videoCall}`} className="icon me-3"></img>
                               </li>
                             )}
                             {item.is_active_opd === 1 && (
                               <li>
                                 <img src={`${APP_SUB_FOLDER}${print}`} className="icon"></img>
                               </li>
                             )}
                           </ul>
                         </div>:''
                    }
                    
                  </div>
      
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <ul className="list-unstyled d-flex flex-wrap mb-0 text-capitalize fs-6">
                        {
                          (item.availability && item.availability.length > 0) && (
                            <li className="px-1">
                              <span className="text-secondary  me-2">Available</span>
                            </li>
                          )
                        }
                        {
                          item.availability.map((day, index) => {
                            return (
                              <li className="px-1" key={day}>
                                <span className="text-primary fw-bolder">{changeDayString(day)}{((index + 1) !== item.availability.length) && ', '}</span>
                              </li>
                            );
                          })
                        }
                      </ul>
                    </div>
                    <div>
                  
                      {
                        (item.availability && item.availability.length > 0) ? (
                          <Link to={`/book-consult/${specialities == undefined? item.specialities_slug[0]?.slug: specialities}/${item.slug}/${Id}/${sId==0? item.specialities_slug[0].id:sId}/${item.id}/${consultType}`}
                            className="btn btn-primary" style={{borderRadius:"50px"}}>Book</Link>
                        ) :
                        (
                          <button className="btn btn-primary" disabled>Book</button>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            );
          })
          :	<p className="text-center fs-6 text-secondary fw-bold">There are currently no doctors available for the selected consult type, please select a different consult type to book appointments.</p>
        }
        </>
      )
  
    }
    else{
      return '';
      
      // <h5 className="text-center text-secondary fw-bold">
      //   There are no doctors available for the selected speciality at present. Please call <a className='text-primary text-decoration-none' href={`tel:${currentLocation.primary_contact}`}>{currentLocation.primary_contact}</a> for assistance.
      // </h5>
        }
  };
  return displayDoctors()
}