import React, { useEffect, useState } from 'react';
import facebook from "../../assets/images/facebook.png"
import instagram from "../../assets/images/instagram.png"
import linkdin from "../../assets/images/linkdin.png"
import twitter from "../../assets/images/twitter.png"
import googlePlay from "../../assets/images/google-play.png"
import appStore from "../../assets/images/app-store.png"
import { Link } from 'react-router-dom';

import { useLocation } from 'react-router-dom'
import { APP_SUB_FOLDER, sitename } from '../../utils/constant';
function Footer() {
    const location = useLocation();
    let current = new Date();
    let year = current.getFullYear();
    return (
        <React.Fragment>
            <section className="mhc-mobile-menu d-block d-lg-none">
                <div className="dd">
                    <ul className="list-inline d-flex justify-content-around mb-0">
                        <li className={`list-inline-item ${(location.pathname === "/my-bookings") ? "active" : ""}`} >
                            <Link className="d-flex align-items-center flex-column justify-content-center" to="/my-bookings">
                                <div className="image-holder ">
                                    <i className="mhc-icon icon-patientmybookings" ></i>
                                </div>
                                <span>My <br /> Bookings</span>
                            </Link>

                        </li>
                        <li className={`list-inline-item ${(location.pathname === "/my-prescriptions") ? "active" : ""}`} >
                            <Link className="d-flex align-items-center flex-column justify-content-center" to="/my-prescriptions">
                                <div className="image-holder">
                                    <i className="mhc-icon icon-patientmyprescriptions" ></i>
                                </div>
                                <span>My <br /> Prescriptions</span>
                            </Link>
                        </li>
                        <li className={`list-inline-item ${location.pathname === "/my-documents" ? "active" : ""}`}>
                            <Link className="d-flex align-items-center flex-column justify-content-center" to="/my-documents">
                                <div className="image-holder">
                                    <i className="mhc-icon icon-patientmydocuments" ></i>
                                </div>
                                <span>My <br /> Documents</span>
                            </Link>
                        </li>
                        {/* <li className={`list-inline-item ${(location.pathname==="/homecare-services") ? "active" :(location.pathname==="/homecare-service-category") ? "active" :(location.pathname==="/homecare-service-category-compare") ? "active" :(location.pathname==="/home-care-login") ? "active" :(location.pathname==="/care-booking-confirmed") ? "active"  :""}`} > */}
                        <li className={`list-inline-item ${location.pathname === "/my-reports" ? "active" : ""}`}>
                            <Link className="d-flex align-items-center flex-column justify-content-center" to="/my-reports">
                                <div className="image-holder">
                                    <i className="mhc-icon icon-patientmyreports" ></i>
                                </div>
                                <span>My <br /> Reports</span>
                            </Link>
                        </li>
                        <li className={`list-inline-item ${location.pathname === "/my-bills" ? "active" : ""}`}>
                            <Link className="d-flex align-items-center flex-column justify-content-center" to="/my-bills">
                                <div className="image-holder">
                                    <i className="mhc-icon icon-my-bills" ></i>
                                </div>
                                <span>My <br /> Bills</span>
                            </Link>
                        </li>
                        {/* <li className={`list-inline-item ${(location.pathname==="/care-package") ? "active" :(location.pathname==="/care-package-comparison") ? "active" :(location.pathname==="/care-package-login") ? "active" :(location.pathname==="/care-booking-confirmedd") ? "active" :""}`} > */}
                        {/* <li className="list-inline-item">
                            <Link className="d-flex align-items-center flex-column justify-content-center" to="/care-package">
                                <div className="image-holder">
                                    <i className="mhc-icon icon-care-packages" ></i>
                                </div>
                                <span>Care <br />  Packages</span>
                            </Link>
                        </li> */}

                    </ul>
                </div>
            </section>

            <section className='bg-footer'><div className="copy-right container">
                <div className='row py-3 py-lg-4'>
                    <div className='col-12 col-lg-6 text-center text-lg-start'><p>Copyright &copy;  {year} {sitename}. All Rights Reserved.</p></div>
                    <div className='col-12 col-lg-6 text-center text-lg-end'><p>Powered by MyHealthcare Technologies Private Limited</p></div>
                </div>

            </div></section>
        </React.Fragment>
    )

}

export default Footer