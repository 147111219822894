import React, { useState } from "react";

import DashboardLink from "../../component/dashboard-link";
import SearchTextWidget from "../../component/search/search-text-widget";
import Serviceavailability from "../../component/serviceavailability";
import { APP_SUB_FOLDER, DefaultMetaTag, servicecategory } from "../../utils/constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import LodingComponent from "../../component/loding";
import {
  HealthCheckupList,
  HealthCheckupListMore,
  configurations,
  thyrocareDetail,
  thyrocareList,
} from "../../actions/get-calls";
import { clearVendorReducer, setVendorReducer } from "../../redux/slice/vendor";
import {
  clearPackageDetailReducer,
  setPackageDetailReducer,
} from "../../redux/slice/package-detail";
import { setHealthCheckUpTestDetailReducer } from "../../redux/slice/healthCheckUp-testDetail";
import NoMoreData from "../../component/no-more-data/noMoreData";
import Breadcrumb from "../../component/breadcrumb";
const breadcrumArrry=[
  {
    name:'My Dashboard',
    url:'/my-dashboard'
  }, 
  {
      name:'Health checkup',
      url:''
    }, 
]
const HealthCheckUp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [keyword, setKeyword] = useState("");
  const [callingType, setCallingType] = useState("package");

  const breadCrumbData = [
    { type: 1, url: `/`, name: "Home" },
    { type: 1, url: "/my-dashboard", name: "My Dashboard" },
    {
      type: 3,
      url: "",
      name:  "Health checkup",
    },
  ];
  const pageURL = window.location.origin + window.location.pathname;
  const [SEO_META, setSEO_META] = useState({
    title: DefaultMetaTag.title,
    description: DefaultMetaTag.description,
    keywords: DefaultMetaTag.keyword,
    pageURL: pageURL,
  });
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUserCard = useSelector((state) => state.selectedUserCard.value);

  const [diagnosticCategory, setDiagnosticCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [diagnosticCategories, setDiagnosticCategories] = useState(null);
  const [healthCheckUpList, setHealthCheckUpList]=useState([])
  const [loding, setLoding]=useState(false)
  const [lodingcat, setlodingcat]=useState(false)
  const [pageNo, setPageNo]=useState(1)
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [compareId1, setCompareId1] = useState(null);
  const [compareId2, setCompareId2] = useState(null);

  const [packageDetail, setPackageDetail] = useState(null);
	const [showPackageDetail, setShowPackageDetail] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!allUsers && !selectedUserCard) {
      navigate('/login');
      return;
    }
  },[]);


  useEffect(async () => {
    
    await HealthCheckupList(1, currentLocation.hospital_group_id, currentLocation.hospital_group_id, currentLocation.hospitalID, servicecategory[0]?.id)
    .then(function(response){
      setlodingcat(true)
       let healthData=response.data.health_checkup 
     let sortData=  healthData.sort((a, b) => parseFloat(a.category_id) - parseFloat(b.category_id));
  
            var unique = sortData.filter(function (obj, idx, arr) {
              return arr.findIndex((o) => o.category_id === obj.category_id) === idx
              });   
                 
              setDiagnosticCategory(sortData[0]?.category_id);     
           const catid=(sortData[0]?.category_id);  
       setDiagnosticCategories(unique);
       setSelectedCategoryName(sortData[0]?.category_name)  
   
       if(healthData.length>0){
        HealthCheckupListMore(pageNo, currentLocation.hospital_group_id, currentLocation.hospital_group_id, currentLocation.hospitalID, servicecategory[0]?.id,catid)
        .then(function(response){ 
       
           setTotal(response.data.pagination.total )
           setTotalPage(response.data.pagination.total_pages)
           setHealthCheckUpList(response.data.health_checkup)
           setLoding(true) 
           setLoadingMore(false); 
        })
    
        .catch(function (err) {
          setLoding(true)
          setLoadingMore(false); 
    
        });
       }
     

    })

    .catch(function (err) {
      setLoding(false)
    });
  }, []);
 


            const LoadDataByTab=async(e)=>{
             const page=1
             setHealthCheckUpList([])
             setLoding(false) 
              let currentIndex = diagnosticCategories && diagnosticCategories.map((t) => t.category_id).indexOf(parseInt(e));    
              setSelectedCategoryName(diagnosticCategories[currentIndex].category_name);
              await HealthCheckupListMore(page, currentLocation.hospital_group_id, currentLocation.hospital_group_id, currentLocation.hospitalID, servicecategory[0]?.id,parseInt(e))
              .then(function(response){ 
           
                 setTotal(response.data.pagination.total )               
                 setTotalPage(response.data.pagination.total_pages)
                 setHealthCheckUpList(response.data.health_checkup)
                 setLoding(true) 
                 setLoadingMore(false); 
              })
          
              .catch(function (err) {
                setLoding(true)
                setLoadingMore(false); 
          
              });

            }

  


// Load Mor Not Working Properly 
   const loadMoreHealthProducts=async(e, diagnosticCategors)=>{

    setLoadingMore(true); 
    setLoding(false) 
    const page=pageNo + 1
    setPageNo(page);
    await HealthCheckupListMore(page, currentLocation.hospital_group_id, currentLocation.hospital_group_id, currentLocation.hospitalID, servicecategory[0]?.id, parseInt(e))
    .then(function(response){    

      setTotal(response.data.pagination.total )
      setTotalPage(response.data.pagination.total_pages)
      setHealthCheckUpList(healthCheckUpList.concat(response.data.health_checkup))
      setLoding(true) 
      setLoadingMore(false); 
    })

    .catch(function (err) {
      setLoding(true)
      setLoadingMore(false); 

    });
    
   }
  
    
   async function handleShow(id) {
   
    setShowPackageDetail(true);
   // const detailsData=healthCheckUpList.findIndex(x => x.id === id);
    const object = healthCheckUpList.find(obj => obj.id === id);
    setPackageDetail(object)
   
   }
   function handleClose() {
		setShowPackageDetail(false);
		setPackageDetail(null);
	}
   function setCompare(e) {
    if (e.target.checked) {
      if (!compareId1) {
        setCompareId1(parseInt(e.target.value));
      } else {
        setCompareId2(parseInt(e.target.value));
      }
    } else {
      if (compareId1 === parseInt(e.target.value)) {
        setCompareId1(null);
      } else {
        setCompareId2(null);
      }
    }
  }

 const forwordTestDetails=(e, details)=>{
   dispatch(  setHealthCheckUpTestDetailReducer(details))
 }

  return (
    <>
      {/* <Helmet_SEO_META SEO_META={SEO_META} /> */}
      <section>
        <div className="container min-body-height">
          <div className="row align-items-center">
            <div className="col-md-9">
            <Breadcrumb textUrl={breadcrumArrry} />   
            </div>
            <div className="col-md-3 d-none d-md-block">
              <DashboardLink />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center">
              <h1 className="fs-4 mb-1">Health Check-Up</h1>
            </div>
            {/* <div className="col-lg-6 mt-4 mt-lg-0">
              <SearchTextWidget
                placeHolder={`Search `}
                btnDisplay={true}
                setKeyword={setKeyword}
              />
            </div> */}
          </div>

          <div className="mhc-tabs Package-btn-tabs mt-3">

 

                    <>
                    {
                     lodingcat==false ?<LodingComponent  className="mhc-Loding-minHeight" />:
                      diagnosticCategories && diagnosticCategories.length >0 ?
                 
                 <Tabs
                       activeKey={diagnosticCategory}
                       onSelect={(e) => {                
                         setDiagnosticCategory(e);              
                         setCompareId1(null)
                         setCompareId2(null)
                         LoadDataByTab(e)
                       }}
                     >
                        {diagnosticCategories &&
                         diagnosticCategories.map((mainItem) => (
                           <Tab
                           eventKey={mainItem.category_id}
                           title={mainItem.category_name}
                           key={mainItem.category_id}
                          // onClick={setDiagnosticCategory(mainItem.category_id)}
                         >
                
                     {

                     loding===false ?     <LodingComponent  className="mhc-Loding-minHeight" /> :
                      healthCheckUpList && healthCheckUpList.length >0 ?
                      <div className="row mt-2 mb-4">
                     
                      {
                         healthCheckUpList.map((item) => (
                           <div className="col-lg-6 col-xl-4 mb-4" >
                             <div className="mhc-package-tab ">
                               <div className="d-flex justify-content-between align-items-start ">
                                 <div><p className="m-0 packg-tittle">{item.title}</p>
                                   <span className="packg-parm">Includes {item.test_count} Test{item.test_count>1? "s":''} {item.test_count > 0 ? <i className="mhc-icon icon-mhc-info cursor-pointer"  onClick={(e)=>handleShow(item.id)}></i> : ''} </span>
                                 </div>
                                 <div>
                                  {
                                    
                                   parseInt(item.discount_price) < parseInt(item.actual_price) ? <span className="text-primary text-nowrap ms-2">{item.discount_percent}</span>:""
                                  }
                                 </div>
                            
                               </div>
                               <div className="d-flex justify-content-between align-items-center mt-3">
                               <div>
                                    {
                                       parseInt(item.discount_price) < parseInt(item.actual_price) ?
                                       <>
                                       <span  >
                                          <del>INR {item.actual_price}</del>
                                        </span>
                                       <span className="devider mx-2"> </span>
                                     <span className="text-primary "> INR {item.discount_price}</span>
                                     </>:  <span  >
                                          INR {item.actual_price}
                                        </span>
                                    }
                                    
                                 </div>
                                
                                </div>
                          
                               <div className="d-flex justify-content-between align-items-center mt-3">
                             
                                 <div className="form-check ">
                                     <input className="form-check-input" type="checkbox" defaultValue={item.id} id={item.id} 
                                        value={item.id}

                                        disabled={
                                          compareId1 &&
                                          compareId2 &&
                                          item.id !== compareId1 &&
                                          item.id !== compareId2
                                        }
                                       onChange={(e) => setCompare(e)}
                                     />
                                     <label className="form-check-label d-block d-sm-inline" htmlFor={item.id}>
                                     Compare Now
                                     </label>
                                   </div>
                                 <Link onClick={(e)=>forwordTestDetails(e,item)} 
                                
                                 to={`/health-checkup/${
                                  selectedCategoryName
                                    ? selectedCategoryName
                                        .toLowerCase()
                                        .trim()
                                        .replaceAll(" ", "-")
                                    : "-"
                                }/${item.id}`} 
                                className="btn btn-primary rounded-1">Book</Link>
                               </div>
                             </div>
     
                           </div>
     
                         ))
                       }
   
    
                               {loadingMore? (
                                  <LodingComponent  className="text-center" />
                           
                     
                               ) : (
                                 <>
                                 {
                                  totalPage > 1 ?   healthCheckUpList?.length == total ? (
                                    <div className="text-center mb-4">
                                      <button
                                        onClick={(e)=>loadMoreHealthProducts(diagnosticCategory)}
                                        className="btn btn-outline-primary rounded-1"
                                      >
                                        Load more
                                      </button>
                                    </div>
                                  ):(          <NoMoreData className="text-center mb-3" />)
                                  :''
                                 }
                                 
                                 </>
                             
                               )
                               
                              } 
                           {
                             compareId1 && compareId2 &&(
                               <div className="col-12 text-center">
                               <Link                         
                                to={`/health-checkup/package-comparison/${
                                  selectedCategoryName
                                    ? selectedCategoryName
                                        .toLowerCase()
                                        .trim()
                                        .replaceAll(" ", "-")
                                    : "-"
                                }/${compareId1}/${compareId2}`}
                               className="btn btn-primary rounded-1">Compare</Link>
                             </div>
                             )
                           }
                
                     
                  
                    
                   </div>
                      
                      :<>
                      <div className="col-12">
                        <div className="alert alert-danger text-center">
                          No packages available!
                        </div>
                      </div>
                    </>
                     }
            
         
                           </Tab>
                       ))}
         
         
                     </Tabs>
                     :<>
                     <div className="col-12">
                       <div className="alert alert-danger text-center">
                         No packages available!
                       </div>
                     </div>
                   </>
                       }
                       
                    </>
       
           
          </div>
        </div>
      </section>

      <Modal
				show={showPackageDetail}
				onHide={() => handleClose()}
				size="lg"
				className="mhc-package-modal"
			>
				<Modal.Header className="mb-0 pb-0" closeButton>
        {packageDetail && (
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<h6 className="m-0">{packageDetail.title}</h6>
								<p className="m-0 mt-1">
									Profile Includes ({packageDetail.test_count} Parameters)
								</p>
							</div>
							<div>
								<img
									src={packageDetail.image}
									className="pacg-icon"
									alt="MyHealthcare Package Details"
								/>
							</div>
						</div>
					)}
				</Modal.Header>
				<Modal.Body>
	           {
              packageDetail && (
                <>
                 <ul class="m-0">
                   {
                    packageDetail.features.map(item=>(
                      <li className="mb-1">{item}</li>
                    ))
                   }
                 
                  
                   </ul>
                </>
             
              )
             }
				</Modal.Body>
			</Modal>
    </>
  );
};

export default HealthCheckUp;
