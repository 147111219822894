// =============================================================================
// API TO GET API
// =============================================================================

import { groupId, hospitalgroupId, is_group_exist } from "../utils/constant";
import axiosClient from "./api-handlers";
import {apitoken} from "../utils/constant";
import axiosMHClient from "./second-api-handlers";

const httpHeaderWithPrimaryToken = {
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "aggregatorType": "market_place",
    "apitoken": apitoken,
  },
};
//get list of countries
export async function countries() {
  const response = await axiosClient.get(
    `/template-parts/order-medicine/countries.json`
  );
  return response;
}

//get indian cities list
export async function indianCities(diagnostic, page) {
  const response =
    await axiosClient.get(`/patients/web/v2/patients/indian-city?is_diagnostic=${diagnostic}&page=${page}
`);
  return response;
}

//get configurations
export async function configurations() {
  const response = await axiosClient.get(`/patients/web/v2/configurations`);
  return response;
}


// get Hospitals Group List
export async function getHospitalGroupListing(GroupName) {
  const response =
    await axiosClient.get(`/patients/web/v2/mhea-hospital-list?hospital_group_code=${GroupName}
`);
  return response;
}

//get hostpitals by cities
export async function hospitalByCity(lat, lng) {
  const response =
    await axiosClient.get(`/patients/web/v2/hospitals-by-city?lat=${lat}&lng=${lng}
`);
  return response;
}
// get pincode
export async function getOneMGPincode(pincode) {
  const response = await axiosClient.get(
    `/patients/ios/v2/website/get-onemgpincode?pincode=${pincode}`,
    httpHeaderWithPrimaryToken
  );
  return response;
}
// get order medicine
export async function getOrderMedicinePincode(pincode) {
  const response = await axiosClient.get(
    `/patients/ios/v2/website/get-medicine-order-pincode?pincode=${pincode}`,
    httpHeaderWithPrimaryToken
  );
  return response;
}
//get specialities
export async function specialitiesListByHospital(id, hospital_group_id, token,) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }
  const response = await axiosClient.get(
    `/patients/web/v2/specialities?hospital_id=${id}${group_exist}`,
    // `/patients/web/v2/partner-specialities?hospital_id=${id}${group_exist}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "aggregatorType": "market_place",
        apitoken: `${token}`,
      },
    }
  );
  return response;
}

export async function getSpecialitiesDectorSearch(payload) {
  const response = await axiosClient.get(
    `/patients/web/v2/search` + payload);
  return response;
}

//get hospitals by id
export async function getHospitals(id) {
  const response = await axiosClient.get(`/patients/web/v2/hospitals/${id}`);
  return response;
}

/**
 * ^location of the clinic will be retrived from the clinic only
 */

//get clinic distance
export async function clinicDistance() {
  const response = await axiosClient.get(
    `/patients/web/v2/patinets/clinic-distance`
  );
  return response;
}

//get doctors Availability
export async function getDocAvailability() {
  const response = await axiosClient.get(
    `/patients/web/v2/doctors/2054/availability`
  );
  return response;
}

//get profile inforamtion
export async function getProfile(JWT) {
  const response = await axiosClient.get(
    `/patients/web/v2/patients/profile`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function getProfileWithPayload(payload, token) {
  const response = await axiosClient.get(
    `/patients/web/v2/patients/profile` + payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }
  );
  return response;
}
/**
 * ^add family memeber -> process diagram Reference
 */

//get family member information
export async function familyMember(familyMember) {
  const response = await axiosClient.get(
    `/specialities/75/doctors/2057/user/account/${familyMember}`
  );
  return response;
}

/**
 * ^ Work in progress
 */

//get availability time slots
export async function timeslots(time) {
  const response =
    await axiosClient.get(`/patients/web/v2/doctors/1630/availability-timeslots?${time}
`);
  return response;
}

//get indian pincode API
export async function indianPincode(pincode) {
  const response =
    await axiosClient.get(`/patients/web/v2/patients/get-indian-pincode?pincode=${pincode}
`);
  return response;
}
/**
 */

//get time slot with lab/ hospital name
export async function getTimeslotByHospital() {
  const response =
    await axiosClient.get(`/patients/web/v2/patients/lalpath-slots-availability?
`);
  return response;
}


//get thyrocare test details
export async function thyrocareTestDetails() {
  const response =
    await axiosClient.get(`/patients/web/v2/patients/thyrocare-test-Details?
`);
  return response;
}

//get thyrocare list products
export async function thyrocareListProducts() {
  const response =
    await axiosClient.get(`/patients/web/v2/patients/thyrocare-list-product
`);
  return response;
}

/**
 * ^waiting for clarification
 */
//get thyrocare list products
export async function testDetails() {
  const response =
    await axiosClient.get(`/patients/web/v2/patients/get-test-details?
`);
  return response;
}

export async function thyrocareList(type, category, pincode, page, keyword) {
	keyword = keyword ? "&keyword=" + keyword : type === 2 ? "&category=" + category : "";
	const response = await axiosClient.get(`/patients/web/v2/patients/thyrocare-list-products?type=${type}&pincode=${pincode}&page=${page}${keyword}`,
		httpHeaderWithPrimaryToken);
	return response;
}
/**

 * ^waiting for clarification
 */
//get patient vendors
export async function patientVendor() {
  const response =
    await axiosClient.get(`/patients/web/v2/patients/lalpath-cities?
`);
  return response;
}

/**
 */
//get vendor name
export async function vendorName() {
  const response = await axiosClient.get(
    `/patients/web/v2/patients/lalpath-cities?`
  );
  return response;
}

/**
 */
//get patient get medicine order via pincode
export async function medicinePincode(pincode) {
  const response =
    await axiosClient.get(`/patients/ios/v2/website/get-onemgpincode?${pincode}
`);
  return response;
}
export async function thyrocareDetail(id, pincode) {
	const response = await axiosClient.get(`/patients/web/v2/patients/thyrocare-test-Details?diagnosis_id=${id}&pincode=${pincode}`,
		httpHeaderWithPrimaryToken);
	return response;
}

//GET homecare category
export async function getHomecareCategory() {
  const response = await axiosClient.get(
    `/patients/ios/v2/test/homecare-category`
  );
  return response;
}

// GET Hospital listing based on category
// export async function getHospitalListingByCat(catId) {
//   const response =
//     await axiosClient.get(`/patients/ios/v2/hospitals-by-city?categor
// y_id=${catId}&is_homecare=1
// `);
//   return response;
// }
export async function checkBookingStatusPackage(order_id) {
	const response = await axiosMHClient.get(`/patients/web/v2/patients/diagnostics-order-status?ref_order_id=${order_id}`,
	httpHeaderWithPrimaryToken);
	return response;
}
//GET Package listing based on hospital
export async function packageByHospital(catId, hospitalId, page,hospital_group_id, type) {
  let group_exist = '';
  if(is_group_exist === true){
    group_exist = `&hospital_group_id=${hospital_group_id}&group_id=${hospital_group_id}`;
  }
  const response =
    await axiosClient.get(`/patients/ios/v2/test-list-by-category?category_id=${catId}&page=${page}&type=${type}${group_exist}
`);
  return response;
}


//GET Package details
export async function packageDetails() {
  const response = await axiosClient.get(
    `/patients/ios/v2/test/hospital_id/28/test/75`
  );
  return response;
}


//GET package availability
export async function packageAvailability() {
  const response =
    await axiosClient.get(`/patients/ios/v2/test?hospital_id=28&testid=75
`);
  return response;
}

//get doctors availability
export async function doctorAvailability(specialId, hospitalId, keyword) {
  keyword = keyword !== "" ? "&keyword=" + keyword : "";
  const response = await axiosClient.get(
    `/patients/web/v2/specialities/${specialId}/doctors?hospital_id=${hospitalId}${keyword}`
  );
  return response;
}

//get doctors imediate availbility
export async function doctorImmediateAvailability(specialId, hospitalId) {
  const response = await axiosClient.get(
    `/patients/web/v2/specialities/${specialId}/immediate-doctors?hospital_id=${hospitalId}`
  );
  return response;
}

//get specialityInformation by ID
export async function specialityInfoById(Id) {
  const response = await axiosClient.get(`/patients/web/v2/specialities/${Id}`);
  return response;
}

//get Doctors profile by hospitla ID
export async function doctorProfileById(docId, hospitalId) {
  const response = await axiosClient.get(
    `/patients/web/v2/doctors/${docId}?hospital_id=${hospitalId}`
  );
  return response;
}

export async function doctorAvailabilityById(Id) {
  const response = await axiosClient.get(
    `/patients/web/v2/doctors/${Id}/availability`
  );
  return response;
}

export async function doctorTimeSlots(docId, hospitalId, date, consultT) {
  const response = await axiosClient.get(
    `/patients/web/v2/doctors/${docId}/availability-timeslots?hospital_id=${hospitalId}&date=${date}&consult_type=${consultT}`
  );
  return response;
}

//get profile inforamtion
export async function globalSearch(keyword) {
  const response = await axiosClient.get(
    `/patients/web/v2/search?type=all&keyword=${keyword}`,
  );
  return response;
}


//get Pay Later
export async function payLaterCall(booking_id, currentLocation, JWT) { 
  const response = await axiosClient.get(
    `/patients/web/v2/doctors/pay-on-arrival?booking_id=${booking_id}&hospital_group_id=${currentLocation}`,
    {
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


/// get reasion

export async function reasonBookingCall(did,hid, JWT) {
  const response = await axiosClient.get(
    `/patients/web/v2/booking/reasons/details?doctor_id=${did}&hospital_id=${hid}`,
    {
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function carePackages() {
	const response = await axiosMHClient.get(`/patients/web/v2/special-health-offers`);
	return response;
}


// HealthCheckUP api Call
export async function HealthCheckupList(page, gId, hgId, hId, Sid) {
	const response = await axiosClient.get(`patients/web/v2/health-checkup-list?page=${page}&group_id=${gId}&hospital_group_id=${hgId}&hospital_id=${hId}&service_category=${Sid}`,
		httpHeaderWithPrimaryToken);
	return response;
}
export async function HealthCheckupListMore(page, gId, hgId, hId, Sid, type) {
	const response = await axiosClient.get(`patients/web/v2/health-checkup-list?page=${page}&group_id=${gId}&hospital_group_id=${hgId}&hospital_id=${hId}&service_category=${Sid}&type=${type}`,
		httpHeaderWithPrimaryToken);
	return response;
}
export async function getHealthCheckupSlot(date, pId, gId, hgId, hId) {
	const response = await axiosClient.get(`/patients/web/v2/health-checkup?requested_date=${date}&phc_id=${pId}&group_id=${gId}&hospital_group_id=${hgId}&hospital_id=${hId}`,
		httpHeaderWithPrimaryToken);
	return response;
}

export async function healthCheckuppayOnArrival(bid, gId, hgId, hId) {
	const response = await axiosClient.get(`/patients/web/v2/health-checkups/pay-on-arrival?booking_id=${bid}&group_id=${gId}&hospital_group_id=${hgId}&hospital_id=${hId}`,
		httpHeaderWithPrimaryToken);
	return response;
}
export async function healthCheckuppayOnNow(bid) {
	const response = await axiosClient.get(`/patients/web/v2/doctors/make-payment?booking_id=${bid}&payment_type=hc`,
		httpHeaderWithPrimaryToken);
	return response;
}