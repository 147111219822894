import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getOTP, requestPin, savePIN, switchPatient, verifyMobileOTP, verifyPIN } from "../../actions/post-call";
import { getProfile } from "../../actions/get-calls";
import { Link, useNavigate } from "react-router-dom";
import antiClock from "../../assets/images/anticlock.png";

import {
  setCallTokenReducer,
  clearCallTokenReducer,
} from "../../redux/slice/call-token";
import {
  setSwitchTokenReducer,
  clearSwitchTokenReducer,
} from "../../redux/slice/switch-token";
import {
  setAllUsersReducer,
  clearAllUsersReducer,
} from "../../redux/slice/all-users";
import {
  setSelectedUserReducer,
  clearSelectedUserReducer,
} from "../../redux/slice/selected-user";
import {
  setUserProfileReducer,
  clearUserProfileReducer,
} from "../../redux/slice/user-profile";
import SpinnerComponent from "../spinnerComponent";
import {
  APP_SUB_FOLDER,
  Logo,
  TermsPageURL,
  PrivacyPageURL,
  DefaultMetaTag,
  sitename,
  showUhidLogin,
  is_group_exist,
} from "../../utils/constant";
import Helmet_SEO_META from "../seo_meta";
import OTPInput from "react-otp-input";

const DashboardLogin2 = () => {
    // hide show
    const[loginHome, setLoginHome]=useState(true);
    const[createPinScreen, setCreatePinScreen]=useState(false);
    const[loginPinScreen, setLoginPinScreen]=useState(false);
    const [pinSetScreen, setPinSetScreen]=useState(false)

  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [uhid, setUhid] = useState("");
  const [uhidType, setUhidType] = useState();
  const [isDisabledMob, setIsDisabledMob] = useState(false);
  const [isDisabledUHID, setIsDisabledUHID] = useState(false);
  const [otpMessage, setOtpMessageTag] = useState(false);
  const [otpMessageText, setOtpMessageText] = useState("");
  const [otpM, setOtpM] = useState();
  const [otpRe, setOtpRe] = useState(false);
  const [otpNum, setOtpNum] = useState();
  const [pinNum, setPinNum] = useState('');
  const [pinsetNum, setPinsetNum]=useState("")
  const [isOtpBaseLogin, setIsOtpBaseLogin]=useState(false)
  const [numberEmpty, setNumberEmpty] = useState(false);
  const [uhidEmpty, setUhidEmpty] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);
  // const [showOTPForm, setShowOTPForm] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [callKey, setCallKey] = useState(null);
  const [optErrorMsg, setOptErrorMsg] = useState("");
  const [spinnerResponse, setSpinnerResponse] = useState(false);
  const [sendOtpResponse, setSendOtpResponse]=useState(false)
  const [spinnerResponseverify, setSpinnerResponseverify]=useState(false)
  const [spinnerResponsesetPin, setSpinnerResponsesetPin]=useState(false)
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const callToken = useSelector((state) => state.callToken.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);

  useEffect(() => {
    if (allUsers) {
      navigate(`/my-dashboard`);
    }
  }, []);

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  function mobileNumberCheck() {
    setNumberEmpty(false);
    setUhidEmpty(false);
  }
  function uhidNumberCheck() {
    setUhidEmpty(false);
    setNumberEmpty(false);
  }
  function disAbleMobileField(e) {
    const uhidValue = e.target.value;
    uhidValue == "" ? setIsDisabledMob(false) : setIsDisabledMob(true);
    uhidValue !== "" ? setUhidType("uhid") : setUhidType("");
  }
  function disAbleUhidField(e) {
    const mobValue = e.target.value;
    mobValue == "" ? setIsDisabledUHID(false) : setIsDisabledUHID(true);
    mobValue !== "" ? setUhidType("Mobile_no") : setUhidType("");
  }
 
  function getMobileCheck() {
    setSendOtpResponse(true)
    // if (showOTPForm) {
    //   document.querySelector("#sendOTP").value = "";
    //   setOptErrorMsg("");
    // }
    if ((phone == "" || phone.length != 10) && isDisabledMob == false) {
      setNumberEmpty(true);
      setUhidEmpty(false);
      setSendOtpResponse(false)
    } else if ((uhid == "" || uhid.length <= 1) && isDisabledUHID == false) {
      setUhidEmpty(true);
      setNumberEmpty(false);
      setSendOtpResponse(false)
    }    else{
      let getValue = uhidType === "uhid" ? uhid : phone;
      requestPin(getValue, uhidType,  countryCode, currentLocation.hospital_group_id, currentLocation.hospitalID)
      .then(function (response) {    
   
        let avialblePin=response.data.is_pin_available
        if(avialblePin===false){
          let getValue = uhidType === "uhid" ? uhid : phone;
          getOTP(getValue, uhidType, currentLocation.hospital_group_id)
          .then(function (login_response) {
          
            if (login_response.status==200){            
              const login_text="The OTP has been sent to the mobile number "
              setLoginHome(false)
              setCreatePinScreen(true)
              setLoginPinScreen(false);
              setOtpMessageTag(true)             
              setOtpMessageText(login_text + login_response.data.mobile_number )
              setIsOtpBaseLogin(false)
              setPinSetScreen(false)
              setSendOtpResponse(false)
              setIsResend(true);
            }
            else{
              setOtpRe(true)      
              setOtpM(login_response.data.errors[0].message)           
              setCreatePinScreen(false)
              setLoginHome(true)
              setLoginPinScreen(false);
              setPinSetScreen(false)
              setSendOtpResponse(false)
            }
          })
        }
        else{
         
          setLoginPinScreen(true);
          setLoginHome(false)
          setCreatePinScreen(false)
          setPinSetScreen(false)
          setSendOtpResponse(false)
        }
    
        
      })
      .catch(function () { });
    }
  }
  const loginVerifyPIN =()=>{
    setSpinnerResponse(true)
    verifyPIN(phone, pinNum,  currentLocation.hospital_group_id, currentLocation.hospitalID)
    .then(function (response) {
    
      let res = response.data;
   
      setSpinnerResponse(false)
      setOptErrorMsg('');
      if(res.errors){
        setOptErrorMsg(res.errors[0].message)
      }
      else{
        setOtpRe(false);
        setOtpMessageTag(false);
        // setCurrentUserId(res.users[0].id);
        setOtpMessageText('');
        dispatch(setCallTokenReducer(response.headers.authorization));
        dispatch(setAllUsersReducer(res.users));
       
        if (res && res.users && res.users.length == 1) {
          dispatch(setSwitchTokenReducer(response.headers.authorization));
          dispatch(setSelectedUserReducer(res.users[0]));
          navigate('/my-dashboard');
          goToTop()
        }
        else{
          switchUser(res.users[0].id, response.headers.authorization);
          navigate('/my-dashboard');
          goToTop()
        }
      }
   

    
   
    })
    .catch(function () { });
  }


  const getOtpResend=()=>{
    let getValue = uhidType === "uhid" ? uhid : phone;
          getOTP(getValue, uhidType, currentLocation.hospital_group_id)
    .then(function (login_response) {
      const login_text="The OTP has been sent to the mobile number "
      setOtpMessageTag(true)             
      setOtpMessageText(login_text + login_response.data.mobile_number )
      setIsResend(true);
      setTimeLeft(30);
    })
    .catch(function () { });
  }
  const forgotPin=()=>{
    setIsOtpBaseLogin(false)
    getOtpResend()
    setLoginHome(false)
    setCreatePinScreen(true)
    setLoginPinScreen(false);
    setOptErrorMsg('');
  }
  const loginUsingOTP=()=>{
    setIsOtpBaseLogin(true)
    getOtpResend()
    setLoginHome(false)
    setCreatePinScreen(true)
    setLoginPinScreen(false);
    setOptErrorMsg('');
  }
  function verifiyOtp() {
    setSpinnerResponse(true);
    var payload = new FormData();
    payload.append("hospital_group_id", currentLocation?.hospital_group_id);
    payload.append("registration_source", 1);
    payload.append('enterprise_id', currentLocation?.hospital_group_id);
    if (uhidType === "uhid") {
      payload.append('type', 'uhid');
      payload.append('otp', otpNum);
      payload.append('uid', uhid);
    } else {
      payload.append('isd_code', 91);
      payload.append('otp', otpNum);
      payload.append('uid', phone);
    }
    if(isOtpBaseLogin==true){
      setSpinnerResponseverify(true)
      verifyMobileOTP(payload)
        .then(function (response) {
          let res = response.data;
          setSpinnerResponseverify(false)
          setOptErrorMsg('');
          if(res.errors){
            setOptErrorMsg(res.errors[0].message)
          }
          else{
            setOtpMessageTag(false);
            setOtpMessageText('');
    
            dispatch(setCallTokenReducer(response.headers.authorization));
            dispatch(setAllUsersReducer(res.users));
    
            setOtpRe(false);
            // setCurrentUserId(res.users[0].id);
    
            if (res && res.users && res.users.length == 1) {
              dispatch(setSwitchTokenReducer(response.headers.authorization));
              dispatch(setSelectedUserReducer(res.users[0]));
              navigate('/my-dashboard');
              goToTop()
            }
            else
            {
              switchUser(res.users[0].id, response.headers.authorization);
              navigate('/my-dashboard');
              goToTop()
            }
          }
    
         
        })
        .catch(function (err) { });
    }
    else{
      setSpinnerResponseverify(true)
      verifyMobileOTP(payload)
        .then(function (response) {    
          let res = response.data;
          setSpinnerResponseverify(false)
          setOptErrorMsg('');
          if(res.errors){
            setOptErrorMsg(res.errors[0].message)
          }
          if (response.status==200){    
            setLoginPinScreen(false);
            setLoginHome(false)
            setCreatePinScreen(false)
            setPinSetScreen(true)
          }
        })
        .catch(function (err) { });

    }
  }
  const pinSetlogin=()=>{
    setSpinnerResponsesetPin(true)
    savePIN(phone, pinsetNum,  currentLocation.hospital_group_id, currentLocation.hospitalID)
    .then(function (response) {
   
      let res = response.data;
   
      setSpinnerResponsesetPin(false)
      setOptErrorMsg('');
      if(res.errors){
        setOptErrorMsg(res.errors[0].message)
      }
      else{
        setOtpRe(false);
        setOtpMessageTag(false);
        setOtpMessageText('');
        dispatch(setCallTokenReducer(response.headers.authorization));
        dispatch(setAllUsersReducer(res.users));
       
        if (res && res.users && res.users.length == 1) {
          dispatch(setSwitchTokenReducer(response.headers.authorization));
          dispatch(setSelectedUserReducer(res.users[0]));
          navigate('/my-dashboard');
          goToTop()
        }
        else{
          switchUser(res.users[0].id, response.headers.authorization);
          navigate('/my-dashboard');
          goToTop()
        }  
      }
    

    
   
    })
    
    .catch(function (err) { });
   }
  function switchUser(id, token) {
    let payload = new FormData();
    payload.append("patient_id", id);
    if(is_group_exist == true){
      payload.append("hospital_group_id", currentLocation?.hospital_group_id);
      payload.append("group_id", currentLocation?.hospital_group_id);
     }
    switchPatient(
      payload,
      token ? token : callKey
    )
      .then(function (switch_response) {
        let switch_token = switch_response.headers.authorization;
        let switcUser = switch_response.data.user;
        dispatch(setSwitchTokenReducer(switch_token));
        dispatch(setSelectedUserReducer(switch_response.data.user));

        getProfile(switch_token)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
            
              dispatch(setUserProfileReducer(profile_response.data.user));
              // window.location.reload();

            }
          })
          .catch(function (err) {});

        setShowConfirmation(false);
        let userDetails = {
          "uniqueId":switcUser.uhid, //mandatory uniqueId.
          "patientId":switcUser.id,
          "email":switcUser.email,
          "phone":switcUser.mobile_number,
          "name":switcUser.full_name,
          "profileUrl":switcUser.profile_image,
          // "age":switcUser,
          "dateOfBirth":switcUser.dob,
          "gender":switcUser.gender
          }
          // console.log("userDetailsuserDetails", userDetails)
          window.ReWebSDK.userRegister(userDetails);
      })
      .catch(function (err) {});
  }
  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const pageURL = window.location.origin + window.location.pathname;
  const [SEO_META, setSEO_META] = useState({
    title: DefaultMetaTag.title,
    description: DefaultMetaTag.description,
    keywords: DefaultMetaTag.keyword,
    pageURL: pageURL,
  });

  return (
    <React.Fragment>
      <Helmet_SEO_META SEO_META={SEO_META} />
      {allUsers == null ? (
        <Container className="login-min-body-height">
          {
            loginHome===true ?
          
          <div className="jumbotron vertical-center d-flex align-items-center min-vh-100s">
            <div className="container">
              <div className="row text-center">
                <div className="col-12">
                  <img
                    className="login-logo"
                    src={`${APP_SUB_FOLDER}${Logo}`}
                  />
                </div>
              </div>

              <div className="row text-center mt-3">
                <div className="col-12 p-0">
                  <div className="nav-reigster-form new_login">
                    <div className="row mb-2">
                      <div className="col-12 ">
                        <h3>Log in to your account</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-1"></div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-5 ">
                  <div className="d-flex">
                    <div className="w-50">
                      <select
                        style={{ borderRadius: "0.25rem 0rem 0rem 0.25rem" }}
                        className="form-select"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        disabled={isDisabledMob}
                      >
                        <option value="91">+91</option>
                      </select>
                    </div>
                    <div className="input-group enter-otp">
                      <input
                        type="text"
                        maxLength="10"
                        className="form-control border-radius-left-0 border-start-0 "
                        name="getType"
                        id="mobile_no"
                        placeholder="Mobile Number"
                        onKeyDown={(e) => mobileNumberCheck()}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          disAbleUhidField(e);
                          setPhone(e.target.value);
                          // setOtpRe(false);
                          // setShowOTPForm(false)
                        }}
                        disabled={isDisabledMob}
                      />
                    </div>
                  </div>
                  <div className="d-block d-md-none">
                    {numberEmpty === true && (
                      <>
                        <p className="m-0 fs-7 text-primary mt-2">
                          Please enter a valid{" "}
                          {phone.length > 0 ? "Mobile" : "Mobile/UHID"} number.
                        </p>
                      </>
                    )}
                  </div>
                </div>
                 {
                  showUhidLogin===true ? 
                       <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  mt-3 mt-md-0">
                  <p class="text-primary mb-0 or-circle">
                    <b>OR</b>
                  </p>
                </div>:""

                    }
                     {
                        showUhidLogin===true ? 
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3  mt-3 mt-md-0">
                  <div className="input-group enter-otp">
                    <input
                      type="text"
                      className="form-control"
                      name="getType"
                      id="uhid"
                      placeholder="UHID"
                      maxLength="25"
                      onKeyDown={(e) => uhidNumberCheck()}
                      onChange={(e) => {
                        disAbleMobileField(e);
                        setUhid(e.target.value);
                        // setOtpRe(false);
                        // setShowOTPForm(false)
                      }}
                      disabled={isDisabledUHID}
                    />
                  </div>
                  <div className="d-block d-md-none">
                    {uhidEmpty === true && (
                      <>
                        <p className="m-0 fs-7 text-primary mt-2">
                          Please enter a valid UHID number.
                        </p>
                      </>
                    )}
                  </div>
                </div>
                 :''
                }
           
                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 text-center text-md-start  mt-3 mt-md-0">
                 
                       <button
                       type="submit"
                       className=" rounded border-0 text-white bg-custom px-2 px-md-3 py-2"
                       id="basic-addon1"
                       onClick={() => getMobileCheck()}
                     >
                      Submit {sendOtpResponse == true? <SpinnerComponent />: null}
                     </button>
                  
            
                </div>
              </div>
              <div className="row justify-content-center d-none d-md-flex">
                {numberEmpty === true || uhidEmpty === true ? (
                  <div className="col-lg-1"></div>
                ) : ""}
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-5">
                  {numberEmpty === true && (
                    <>
                      <p className="m-0 fs-7 text-primary mt-2 ">
                        Please enter a valid{" "}
                        {phone.length > 0 ? "Mobile" : "Mobile/UHID"} number.
                      </p>
                    </>
                  )}
                </div>
                {numberEmpty === true || uhidEmpty === true ? (
                  <div className="col-xxl-auto col-xl-auto col-lg-auto col-md-auto  mt-3 mt-md-0">
                    <p className="text-primary mb-0 or-circle border-0">
                      <b>&nbsp;</b>
                    </p>
                  </div>
                ) : ""}
                {
                   showUhidLogin===true ?       <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-6">
                   {uhidEmpty === true && (
                     <>
                       <p className="m-0 fs-7 text-primary mt-2">
                         Please enter a valid UHID number.
                       </p>
                     </>
                   )}
                 </div>:''
                }
                    {
                           showUhidLogin===false?<div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 text-center text-md-start  mt-3 mt-md-0"></div> :''
                    }
              </div>
              <div className=" mt-3 text-center">
             

                <p className="mb-0">
                  Please use mobile number registered with{" "}
                  {sitename} to avoid re-registration
                </p>
                {otpRe === true && (
                  <>
                    {/* <p className="m-0 fs-7 text-primary mt-2">{otpM}</p> */}
                    <p className="m-0 fs-7 text-primary mt-2 mb-3">
                      Sorry, this {uhidType === "uhid" ? "UHID" : "number"} is
                      not registered with us. Please register to continue
                    </p>
                  </>
                )}
                {/* {otpMessage === true && (
                    <p className="m-0 fs-7 mt-4">{otpMessageText}</p>
                  )} */}
                <div className="row my-3">
                <div className="col-12 text-center">
                {optErrorMsg != "" ? (
                        <p className="text-primary mt-2">{optErrorMsg}</p>
                      ) : (
                        ""
                      )}
                <p className="mb-3 mt-3">
                        By logging in, you agree to our{" "}
                        <a
                          href={TermsPageURL}
                          target="_blank"
                          className="text-primary"
                        >
                          Terms and Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href={PrivacyPageURL}
                          target="_blank"
                          className="text-primary"
                        >
                          Privacy Policy
                        </a>
                      </p>
                  <Link
                    to="/user-register"
                    style={{ textDecoration: "underline" }}
                    className="text-primary"
                  >
                    Register Now
                  </Link>{" "}
                  on the {sitename} Patient Portal
                </div>
              </div>
              </div>
              
         
            </div>
          </div>:null
           }
            {
              loginPinScreen  == true ?<div className="mhc-loginscreen">
              
                   
             
                <div class="nav-reigster-form text-center ">
                <img className='login-logo'  src={`${APP_SUB_FOLDER}${Logo}`} />
                  <p className="mt-4 text-secondary">Please enter your 4 digit PIN</p>
                <OTPInput
                    value={pinNum}
                    onChange={setPinNum}
                    numInputs={4}  
                    containerStyle="otp-wrapper"   
                    inputStyle="input"            
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                     />
                      <button className="btn btn-custom rounded-1 mt-3 px-5" onClick={()=>loginVerifyPIN()} >Verify Pin {spinnerResponse == true? <SpinnerComponent />: null}   </button>
                      {optErrorMsg !=''? <p className="text-primary mt-2">{optErrorMsg}</p> : ''}
                      <div className="d-flex mt-4">
                        <div><button className="btn text-primary text-decoration-none me-5" onClick={()=>forgotPin()} >Forgot Pin ?  </button></div>
                        <div><button className="btn text-primary text-decoration-none ms-5" onClick={()=>loginUsingOTP()} >  Login Using OTP</button></div>
                      </div>
                      </div>
                </div>:null
              }
       
            {
              createPinScreen == true  ?     <div className="mhc-loginscreen">
                    <div class="nav-reigster-form text-center ">
                    <img className='login-logo'  src={`${APP_SUB_FOLDER}${Logo}`} />
                
                {otpMessage === true && (<p className="m-0 fs-7 mt-4">{otpMessageText}</p>)}
                 
                  <OTPInput
                    value={otpNum}
                    onChange={setOtpNum}
                    numInputs={4}  
                    containerStyle="otp-wrapper"   
                    inputStyle="input"            
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                     />
                                   {optErrorMsg !=''? <p className="text-primary mt-2">{optErrorMsg}</p> : ''}
                     <div className="d-flex justify-content-center align-items-center">
                       <button className="btn btn-custom rounded-1 mt-3" onClick={()=>verifiyOtp()} >Verify OTP {spinnerResponseverify == true? <SpinnerComponent />: null}    </button>
                       
                       {
                        isResend ? (
                          <div className="anitClock ms-4 mt-3">
                            <img src={`${APP_SUB_FOLDER}${antiClock}`} className="anti-img" alt="" />
                            <p className="otp-time">{timeLeft}</p>
                          </div>
                        ) : (
                          <button  className="btn fs-6 text-decoration-none text-primary text-nowrap mt-3 ms-4" id="basic-addon1" onClick={()=>getOtpResend()} >Resend OTP</button>
                        )
                      }
                    </div>
                </div>
              </div>:null
            }
          {
            pinSetScreen===true? <div className="mhc-loginscreen">
            <div class="nav-reigster-form text-center ">
            <img className='login-logo'  src={`${APP_SUB_FOLDER}${Logo}`} />
        
            <p className="mt-4  mb-0 text-secondary">Please set your 4 digit PIN</p>
         
          <OTPInput
            value={pinsetNum}
            onChange={setPinsetNum}
            numInputs={4}  
            containerStyle="otp-wrapper"   
            inputStyle="input"            
            // renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
             />
               <button className="btn btn-custom rounded-1 mt-3" onClick={()=>pinSetlogin()}  >Set PIN   {spinnerResponsesetPin == true? <SpinnerComponent />: null}   </button>
            
        </div>
      </div>:null
          }
         
        </Container>
      ) : ""}

      <ToastContainer />
    </React.Fragment>
  );
};
export default DashboardLogin2;
