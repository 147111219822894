import React from "react";
import { useSelector, useDispatch } from "react-redux";
const MyReportComingSoon=()=>{
    const dispatch = useDispatch();
    const currentLocation = useSelector((state) => state.currentLocation.value);    
    return(
        <div className="row justify-content-center mt-4"><div className="col-12">
                                <h5 className="text-secondary text-center mb-1">Coming Soon</h5>
                            <p  className="text-secondary mb-2 text-center  lh-base">Alternatively, you can approach {currentLocation.type} at <br /><a className="text-primary text-decoration-none fw-bolder" href={`mailto:${currentLocation.email}`}> <span className="mhc-icon icon-mail"></span> {currentLocation.email} </a> or call <br className="" /> <a className="text-primary text-decoration-none fw-bolder" href={`tel:${currentLocation.primary_contact}`}><span className="mhc-icon icon-phone"></span> {currentLocation.primary_contact}</a> for assistance </p>
                              
                             </div> </div>
    )
}

export default MyReportComingSoon
