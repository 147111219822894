import React, { useEffect, useState } from "react";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import { APP_SUB_FOLDER, apiPATH, servicecategory, timeConvertIntoAM_PM } from "../../utils/constant";
import { useSelector } from "react-redux";
import moment from "moment";
import SelectDateTime from "../../component/select-date-time/selectDateTime";
import { getHealthCheckupSlot, healthCheckuppayOnArrival, healthCheckuppayOnNow } from "../../actions/get-calls";
import { currentTimeConvertIntoAM_PM } from "../../utils/constant";
import LodingComponent from "../../component/loding";
import { Link } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import FamilyMember from "../../component/family-member/familyMember";
import { applyCouponHealth, compareHealthupRequest, removeCouponHealth } from "../../actions/post-call";
import SpinnerComponent from "../../component/spinnerComponent";
import { toast } from "react-toastify";

const HealthCheckupBooking = () => {

    const testDetails = useSelector((state) => state.healthCheckUpTestDetail.value);
    const callToken = useSelector((state) => state.callToken.value);
	const switchToken = useSelector((state) => state.switchToken.value);
    const selectedUser = useSelector((state) => state.selectedUser.value);
    const currentLocation = useSelector((state) => state.currentLocation.value);

    let today = new Date();
	// today = today.setDate(vendorId == 4? today.getDate() + 2 : today.getDate());
   // today = today.setDate(today.getDate());
	const [selectedDay, setSelectedDay] = useState(today);
        const [memberSelect, setMemberSelect] = useState(selectedUser);
    

	const [timeSlots, setTimeSlots] = useState(null);
    const [loadTimeSlots, setLoadTimeSlots]=useState(false)
    const [startTime, setStartTime]=useState("")
	const [endTime, setEndTime]=useState("")
    const [confirmbtn, setConfirmbtn]=useState(false)
    const [confirmDetails, setConfirmDetails]=useState(false)
   
     const [hMappedId, setHMappedId]=useState("")
    const [mappedId, setMappedId]=useState("")   
    const [isTermRead, setIsTermRead] = useState(false);
    const [bookingResponse, setBookingResponse]=useState("")
    const [spinnerResponse, setSpinnerResponse] = useState(false);
    const [couponScreen, setCouponScreen]=useState(false)
    const [couponCode, setCouponCode]=useState("") 
    const [couponCodeType, setCouponCodeType]=useState("")
	const [couponData, setCouponData] = useState(null);

  
    useEffect( () => {
        LoadTimeSlot()
    },[selectedDay])

    const LoadTimeSlot = async()=>{
        const dateNew = moment(selectedDay).format("DD/MM/YYYY");
        await getHealthCheckupSlot(dateNew, testDetails.id, currentLocation.hospital_group_id,currentLocation.hospital_group_id, currentLocation.hospitalID)
        .then(function(response){          
         if(response.data.health_checkup.length>0){
             setTimeSlots(response.data.health_checkup[0].phc_obj[0].slot_time_range )
             setHMappedId(response.data.health_checkup[0].phc_hospital_mapping_id)
             setMappedId(response.data.health_checkup[0].phc_obj[0].mapped_id)           
             setLoadTimeSlots(true)
         }   
         else{
             setTimeSlots([] )
             setLoadTimeSlots(true) 
         }  
     
        })
        .catch(function (err) {
         setLoadTimeSlots(false)
       });
    }

    const setTimeSlot=(sTime, eTime)=>{
        setStartTime(sTime)
        setEndTime(eTime)
        setConfirmbtn(true)
    }
     const confirmAppoitment=async()=>{
        setConfirmDetails(true)
        setSpinnerResponse(true);
        const dateNew = moment(selectedDay).format("DD/MM/YYYY");
        let payload = { 
             slot_end_time: endTime,
             checkup_date: dateNew,
             slot_start_time: startTime,
             group_id: currentLocation.hospital_group_id,
             hospital_group_id: currentLocation.hospital_group_id,
             preventive_health_checkup_id: testDetails.id,
             patient_id: memberSelect.id,
             phc_hospital_mapping_id: hMappedId,
             hospital_id: currentLocation.hospitalID,
             mapped_id: mappedId,
             device_id: 4
        };
        await compareHealthupRequest(payload)
        .then(function(response){           
      
            setSpinnerResponse(false);
            setBookingResponse(response.data)
        })
        .catch(function (err) {         
          });
     }


     function getJWTtoken() {
		if (typeof window !== "undefined") {
			if (switchToken) {
				return switchToken;
			} else {
				return callToken;
			}
		} else if (callToken === undefined) {
			return null;
		} else {
			return null;
		}
	}

        


const payAtHosState=()=>{
    setCouponScreen(true)
    setConfirmDetails(false)
    setCouponCodeType(2)
  
}
const paynowState=()=>{
    setCouponScreen(true)
    setConfirmDetails(false)
    setCouponCodeType(1)
}
     const getcancel=()=>{
        setCouponScreen(false)
        setConfirmDetails(true)
        setCouponCodeType("")  
     }
     const applyCouponCode=async()=>{
        let JWTresponse = getJWTtoken();
        let payload={
            type: couponCodeType,
             coupon_code: couponCode,
            hospital_id: currentLocation.hospitalID,
            is_phc: 1,
            booking_id: bookingResponse.booking_id,
            group_id: currentLocation.hospital_group_id,
            hospital_group_id: currentLocation.hospital_group_id
        }
        await applyCouponHealth(payload, JWTresponse)
        .then(function(response){
         console.log("response", response)
         let res = response.data;
         if (res && res.errors && res.errors.length > 0) {
             return showError(res.errors[0].message);
         }
         setCouponData(res)
        })
        .catch(function (err) {         
        });
    }

    const removeCouponCode= async()=>{
        let JWTresponse = getJWTtoken();
        let payload={
            type: couponCodeType,
             coupon_code: couponCode,
            hospital_id: currentLocation.hospitalID,
            is_phc: 1,
            booking_id: bookingResponse.booking_id,
            group_id: currentLocation.hospital_group_id,
            hospital_group_id: currentLocation.hospital_group_id
        }
        await removeCouponHealth(payload, JWTresponse)
        .then(function (response) {
            let res = response.data;
            if (res && res.errors && res.errors.length > 0) {
                return showError(res.errors[0].message);
            }

            setCouponData(null);
        })
        .catch(function (err) {         
        });
    }
       const payAtHospital=async()=>{
        await healthCheckuppayOnArrival(bookingResponse.booking_id, currentLocation.hospital_group_id,currentLocation.hospital_group_id, currentLocation.hospitalID)
        .then(function(response){          
            showToast(response.data.message)
            const delayDebounceFn = setTimeout(async () => {
                window.open(apiPATH + bookingResponse.actions[1]?.uri, '_self').focus();
               }, 1000)
               return () => clearTimeout(delayDebounceFn);    
           

        })
        .catch(function (err) {         
        });
       }

       const payNow =async()=>{
         await healthCheckuppayOnNow(bookingResponse.booking_id)
         .then(function(response){             
                window.open(apiPATH + bookingResponse.actions[0]?.uri, '_self').focus();
        
         })
         .catch(function (err) {         
        });
       }

       const resetBooking=()=>{
           setTimeSlots(null)
           setLoadTimeSlots(false) 
           setStartTime("");
           setEndTime("");
            setConfirmbtn(false);
            setConfirmDetails(false)
            setIsTermRead(false)
            setBookingResponse("")
            LoadTimeSlot()
            setCouponCode("")
            setCouponScreen(false)
            setConfirmDetails(false)
            setCouponCodeType("")  
     
       }

       function showError(str) {
        toast.error(str, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false
        });
      }
    
      function showToast(str) {
        toast.success(str, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false
        });
      }

    const breadCrumbData = [
        { type:1, url: `/`, name: 'Home' },
        {  type:1, url: "/my-dashboard", name: "My Dashboard" },
        {  type:1,  url: `/health-checkup`, name: 'Health checkup' },
        {  type:1,  url: "", name: 'Package Booking' },
     
    ];
return(
    <React.Fragment>
              
    <div className='container'>
        <div className="health-profile-bread">
    <BreadcrumbWithUrl breadcrumbs={breadCrumbData}  />
    </div>
 
    <div className="row">
        <div className="col-lg-6">
        
        <FamilyMember
               
               btnDisplay={false}    
                            
                    setMemberSelect={setMemberSelect}
                    resetBooking={resetBooking}
                                     />
       
        <SelectDateTime
		selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
		minDate={moment(today).toDate()}   
        resetBooking={resetBooking}
		/>
        {
            loadTimeSlots===false ? <LodingComponent  className="text-center mt-3" />
            :<>
            {
                timeSlots?.length > 0 ?  <ul className="list-unstyled d-flex flex-wrap my-3">
                {timeSlots?.map((item) =>{           
                   const replaceItem=item.split("::")      
               
                  return(
                      <li>
                       
                       <button
                          //disabled={item.blocked_count}
                          className={`btn time-slot-btn rounded-1 `}
                          style={{"min-width":"180px"}}
                          onClick={()=>setTimeSlot(replaceItem[0].trim(), replaceItem[1].trim() )}
                          >
                           
                           {timeConvertIntoAM_PM(replaceItem[0].trim())} -  {timeConvertIntoAM_PM(replaceItem[1].trim())}
                        
                      </button> 
                  </li>
           
                      )
                })}
                      </ul>
                      :<p className="alert alert-danger text-center">Time Slot not available</p>
            }
              
            </>

        }

        <div className="d-flex justify-content-end mb-4">       
        {
            confirmbtn === false ?   <button className="btn btn-primary ms-3" 	disabled>Confirm</button>
            :<button className="btn btn-primary ms-3" onClick={confirmAppoitment}>Confirm   {spinnerResponse == true ? <SpinnerComponent /> : null}</button>
        }
      
        </div>
        
     
        </div>
        {/* end main col */}
     
        <div className="col-lg-6">
        <div className="health-profile mt-3">  
        <div className="mhc-package-details mb-2">
            <div className="row align-items-center border-bottom">
                <div className="col-5">Patient Name</div>
                <div className="col-1"><span>:</span></div>
                <div className="col-6"><span className="fw-bold">{memberSelect?.full_name}</span></div>
              
            </div>
            <div className="row mt-2">
                <div className="col-5"><span>Test Name</span> </div>
                <div className="col-1"><span>:</span></div>
                <div className="col-6"><span>{testDetails.title}</span></div>
            </div>
            <div className="row mt-3">
                        <div className="col-5"><span>Health Checkup Fee</span> </div>
                        <div className="col-1"><span>:</span></div>
                        <div className="col-6"><span>INR  {testDetails.discount_price}</span></div>
                      </div>
            <div className="row mt-3">
                <div className="col-5">Mobile No.</div>
                <div className="col-1"><span>:</span></div>
                <div className="col-6"><span className="fw-bold">{memberSelect.mobile_number}</span></div>
            </div>
            <div className="row mt-3">
            <div className="col-5">Appointment </div>
            <div className="col-1"><span>:</span></div>
            <div className="col-6"><span className="fw-bold">{moment(selectedDay).format("ddd Do MMM YYYY")} {startTime? '|':''}  {timeConvertIntoAM_PM(startTime)} {startTime? '-':''} {timeConvertIntoAM_PM(endTime)}</span></div>
            </div> 
        </div>


         {
            confirmDetails===true ? <div className="d-flex justify-content-end my-4">       
           
            <button className="btn btn-primary ms-3" onClick={payAtHosState}>Pay at Hospital</button>
            <button className="btn btn-primary ms-3"  onClick={paynowState}  >Pay Now</button>
          </div>:''
         }

        {
            couponScreen===true?<>
            <div className="mhc-patient-details pt-4 mt-4">
               {
                	!couponData ?(
                        <>                        
                        <div className="row">
                      <div className="col-md-9 col-lg-9 col-xl-10">
                      <div className="form-floating">
                            <input type="text" className="form-control" id="CouponCode" placeholder="Coupon Code" 
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            />
                            <label htmlFor="name">Coupon Code </label>
                            </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-xl-2 mt-2 mt-md-0">
                                <button className="btn btn-outline-secondary w-100 rounded-1 bg-white"  onClick={(e) => applyCouponCode()}>Apply</button>
                            </div>
                        
                      </div>
                        </>
                    ):(<>
                    	<div className="col-12">
																<label className="alert alert-success w-100">Coupon code has been applied successfully.
																	<a className="btn btn-link p-0 text-danger ms-1 text-decoration-none" onClick={(e) => removeCouponCode()}>Remove coupon</a>
																</label>
															</div>
                    </>)
               }
          
                  
                      <div className="row mt-3 border-bottom">
                        <div className="col-5 mb-2"><span>Convenience fee</span> </div>
                        <div className="col-1"><span>:</span></div>
                        <div className="col-6 text-end"><span>INR {bookingResponse.convenience_fees}</span></div>
                      </div>
                      {
														couponData && (
															<div className="row mt-3">
																<div className="col-6">Coupon Discount </div>
																<div className="col-6 text-end">
																	<span className="me-1">:</span>
																	<span className="fw-bold">INR {couponData.discount_amount}</span>
																</div>
															</div>
														)
													}
                      <div className="row mt-3">
                        <div className="col-5"><span><b>Total to be Paid</b></span> </div>
                        <div className="col-1"><span>:</span></div>
                        <div className="col-6 text-end"><span><b>INR   <span className="ms-1">
                        {
                                couponData ? couponData.total_amount + bookingResponse.convenience_fees  : bookingResponse.total_amount + bookingResponse.convenience_fees
                            }
                        </span>
                           
                            
                            </b></span></div>
                      </div>
             
                </div>
                <div className="form-check mt-3">
													<input
														className="form-check-input"
														type="checkbox"
														onClick={(e) => setIsTermRead(e.target.checked)}
														id="isTerm" />
													<label className="form-check-label  d-block d-sm-inline" htmlFor="isTerm">
														I have read and agree to the <a href="https://www.woodlandshospital.in/disclaimer.php" target="_blank" className="text-primary text-decoration-none">Terms and Conditions.</a>
													</label>
												</div>
               
           
               {
                isTermRead ? <div className="d-flex justify-content-end my-4">
                <Link to="/health-checkup" className="btn btn-outline-primary">Replan</Link>
                <button  className="btn btn-outline-primary ms-3" onClick={getcancel}>Cancel</button>
                   {
                    couponCodeType===2 ?     <button className="btn btn-primary ms-3" onClick={payAtHospital}>Pay at Hospital</button>
                    :  <button className="btn btn-primary ms-3"  onClick={payNow} >Pay Now</button>
                   }
           
              
              </div>:
               <div className="d-flex justify-content-end my-4">
               <Link to="/health-checkup" className="btn btn-outline-primary">Replan</Link>
               <button  className="btn btn-outline-primary ms-3" onClick={getcancel}>Cancel</button>
               {
                 couponCodeType===2 ?  <button className="btn btn-primary ms-3" disabled>Pay at Hospital</button>
                 :   <button className="btn btn-primary ms-3" disabled >Pay Now</button>
               }
             
            
             </div>
               }
               
            </>:""
        }
        
        </div>
        </div>
        {/* end main col */}
    </div>
    </div>
    </React.Fragment>
)}

export default HealthCheckupBooking
