import React, { useState } from "react";
import { APP_SUB_FOLDER,  servicecategory } from "../../../utils/constant";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import LodingComponent from "../../../component/loding";
import {
  HealthCheckupList
} from "../../../actions/get-calls";

import { setBookPackagedetailsReducer } from "../../../redux/slice/bookPackagedetails";
import NoMoreData from "../../../component/no-more-data/noMoreData";

const BookPackage = () => {
  const dispatch = useDispatch();
  // const location = useLocation();
  // const [keyword, setKeyword] = useState("");
  // const [callingType, setCallingType] = useState("package");


  const currentLocation = useSelector((state) => state.currentLocation.value);

  const [PackageList, setPackageList]=useState(null)
  const [diagnosticCategory, setDiagnosticCategory] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [diagnosticCategories, setDiagnosticCategories] = useState(null);
  const [healthCheckUpList, setHealthCheckUpList]=useState([])
  const [loding, setLoding]=useState(false)
  const [pageNo, setPageNo]=useState(1)
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [compareId1, setCompareId1] = useState(null);
  const [compareId2, setCompareId2] = useState(null);
  
  
  useEffect(async () => {
    await HealthCheckupList(pageNo, currentLocation.hospital_group_id, currentLocation.hospital_group_id, currentLocation.hospitalID, servicecategory[2]?.id)
    .then(function(response){
       let healthData=response.data.health_checkup 
       let sortData=  healthData.sort((a, b) => parseFloat(a.category_id) - parseFloat(b.category_id));
            var unique = sortData.filter(function (obj, idx, arr) {
              return arr.findIndex((o) => o.category_id === obj.category_id) === idx
              });              
              setDiagnosticCategory(sortData[0]?.category_id);     
        
       setDiagnosticCategories(unique);
       setSelectedCategoryName(sortData[0]?.category_name)  
       setPackageList(sortData)
       setTotal(response.data.pagination.total )
       const _filterData = sortData.filter(obj => obj.category_id === healthData[0].category_id); 
       setHealthCheckUpList(_filterData)
       setLoding(true) 

    })

    .catch(function (err) {
      setLoding(false)
    });
  }, []);
 


    const LoadDataByTab=(e)=>{
      const _filterData = PackageList.filter(obj => obj.category_id === parseInt(e)); 
      setHealthCheckUpList(_filterData) 
      let currentIndex = diagnosticCategories && diagnosticCategories.map((t) => t.category_id).indexOf(parseInt(e));    
      setSelectedCategoryName(diagnosticCategories[currentIndex].category_name);
 
    }

  
   


// Load Mor Not Working Properly 
   const loadMoreHealthProducts=async()=>{
    setLoadingMore(true); 
    const page=pageNo + 1
    setPageNo(page);
    await HealthCheckupList(page, currentLocation.hospital_group_id, currentLocation.hospital_group_id, currentLocation.hospitalID, servicecategory[2]?.id)
    .then(function(response){
       let healthData=response.data.health_checkup 
       let sortData=  healthData.sort((a, b) => parseFloat(a.category_id) - parseFloat(b.category_id));
            var unique = sortData.filter(function (obj, idx, arr) {
              return arr.findIndex((o) => o.category_id === obj.category_id) === idx
              });              
              setDiagnosticCategory(sortData[0]?.category_id);     
        
       setDiagnosticCategories(unique);
       setSelectedCategoryName(sortData[0]?.category_name)  
       setPackageList(sortData)
       setTotal(response.data.pagination.total )
       const _filterData = sortData.filter(obj => obj.category_id === healthData[0].category_id); 

 
       setHealthCheckUpList(healthCheckUpList.concat(_filterData))
       setLoding(true) 
       setLoadingMore(false); 
    })

    .catch(function (err) {
      setLoding(true)
      setLoadingMore(false); 
    });
   }

   function setCompare(e) {
    if (e.target.checked) {
      if (!compareId1) {
        setCompareId1(parseInt(e.target.value));
      } else {
        setCompareId2(parseInt(e.target.value));
      }
    } else {
      if (compareId1 === parseInt(e.target.value)) {
        setCompareId1(null);
      } else {
        setCompareId2(null);
      }
    }
  }

 const forwordTestDetails=(e, details)=>{
  dispatch(setBookPackagedetailsReducer(details))
 }

  return (
    <>

      <section>
        <div className="container min-body-height">
      

          <div className="mhc-tabs Package-btn-tabs mt-3">

            {
                    loding===false ?       <LodingComponent  className="mhc-Loding-minHeight" />:

                    <>
                    {
                       healthCheckUpList && healthCheckUpList.length >0 ?   <Tabs
                       activeKey={diagnosticCategory}
                       onSelect={(e) => {                
                         setDiagnosticCategory(e);              
                         setCompareId1(null)
                         setCompareId2(null)
                         LoadDataByTab(e)
                       }}
                     >
                        {diagnosticCategories &&
                         diagnosticCategories.map((mainItem) => (
                           <Tab
                           eventKey={mainItem.category_id}
                           title={mainItem.category_name}
                           key={mainItem.category_id}
                          // onClick={setDiagnosticCategory(mainItem.category_id)}
                         >
                         <div className="row mt-2 mb-4">
                     
                                             {
                               healthCheckUpList.map((item) => (
                                 <div className="col-lg-6 col-xl-4 mb-4" >
                                   <div className="mhc-package-tab ">
                                     <div className="d-flex justify-content-between align-items-start ">
                                       <div><p className="m-0 packg-tittle">{item.title}</p>
                                         <span className="packg-parm">Includes {item.test_count} Tests </span>
                                       </div>
                                       <div className="form-check ">
                                           <input className="form-check-input" type="checkbox" defaultValue={item.id} id={item.id} 
                                              value={item.id}

                                              disabled={
                                                compareId1 &&
                                                compareId2 &&
                                                item.id !== compareId1 &&
                                                item.id !== compareId2
                                              }
                                             onChange={(e) => setCompare(e)}
                                           />
                                           <label className="form-check-label d-block d-sm-inline" htmlFor={item.id}>
                                          
                                           </label>
                                         </div>
                                     </div>
                                
                                     <div className="d-flex justify-content-between align-items-center mt-3">
                                     <div>
                                     <span className="text-primary">INR <strike>{item.actual_price}</strike>.00 </span> 
                                           <span className="devider"></span>
                                         <span className="text-primary"> INR {item.discount_price}.00</span>
                                          <span> {item.discount_percent}</span>
                                       </div>
                                       <Link onClick={(e)=>forwordTestDetails(e,item)} 
                                      
                                       to={`/book-package-or-test/packages/${
                                        selectedCategoryName
                                          ? selectedCategoryName
                                              .toLowerCase()
                                              .trim()
                                              .replaceAll(" ", "-")
                                          : "-"
                                      }/${item.id}`} 
                                      className="btn btn-primary rounded-1">Book</Link>
                                     </div>
                                   </div>
           
                                 </div>
           
                               ))
                             }
                         
                       
                                     {loadingMore ? (
                                   <LodingComponent  className="text-center" />
                           
                                     ) : (
                                      healthCheckUpList?.length !== total ? (
                                         <div className="text-center mb-4">
                                           <button
                                             onClick={loadMoreHealthProducts}
                                             className="btn btn-outline-primary rounded-1"
                                           >
                                             Load more 
                                           </button>
                                         </div>
                                       ):(          <NoMoreData className="text-center mb-3" />)
                                     )} 
                                 {
                                   compareId1 && compareId2 &&(
                                     <div className="col-12 text-center">
                                     <Link                         
                                      to={`/package-comparison/${
                                        selectedCategoryName
                                          ? selectedCategoryName
                                              .toLowerCase()
                                              .trim()
                                              .replaceAll(" ", "-")
                                          : "-"
                                      }/${compareId1}/${compareId2}`}
                                     className="btn btn-primary rounded-1">Compare</Link>
                                   </div>
                                   )
                                 }
                      
                           
                        
                          
                         </div>
         
                           </Tab>
                       ))}
         
         
                     </Tabs>:<>
                            <div className="col-12">
                              <div className="alert alert-danger text-center">
                                No packages available!
                              </div>
                            </div>
                          </>
                    
                       }
                    </>
       
           }
          </div>
        </div>
      </section>
    </>
  );
};

export default BookPackage;
