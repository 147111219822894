import axiosClient from "./api-handlers";

//search pincode after 6 numbers
export async function pincodeService(pCode) {
  const response = await axiosClient.get(
    `/patients/ios/v2/website/get-onemgpincode?pincode=${pCode}
`
  );
  return response;
}

export async function orderMedicine(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/addinor/v2/patients/create-medicine-order`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}
