import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const healthCheckUpTestDetail = createSlice({
  name: "healthCheckUpTestDetail",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setHealthCheckUpTestDetailReducer: (state, action) => {
      state.value = action.payload;
    },

    clearHealthCheckUpTestDetailReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setHealthCheckUpTestDetailReducer, clearHealthCheckUpTestDetailReducer } = healthCheckUpTestDetail.actions;
export default healthCheckUpTestDetail.reducer;
