import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";


import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { ExternalDataDelete } from "../../actions/dashboard-api";
import DownloadIcon from "../../assets/images/download-btn.svg";
import { saveAs } from "file-saver";
import PdfIcon from '../../assets/images/mhc-pdf.svg'
import { APP_SUB_FOLDER } from "../../utils/constant";
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true
};

  const PatientMyBillsListing = ({ patientMyBills}) => {
   
  // const selectedUser = useSelector((state) => state.selectedUser.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const [imaData, setiMaData] = useState();
  const [show, setShow] = useState(false);
const [deleteModal, setDeleteModal]=useState(false)
const [record_id, setRecord_id]=useState()

const [billDocuments, setBillDocuments] = useState(patientMyBills)

useEffect(() => {
    setBillDocuments(patientMyBills)
},[billDocuments, patientMyBills])


const savePdf = (e, url) => {
    saveAs(url, "myreport.pdf");
  };
  const handleClose = () => setShow(false);
  const handlerSlider = (e, url) => {
    e.preventDefault();
    let geturl = url;
    setiMaData(geturl)
    setShow(true)   
  }

  const handleDeleteModal=(e, record_id)=>{
    setDeleteModal(true)
    setRecord_id(record_id)
  }
 const closeDeleteModal=()=>{
  setDeleteModal(false)
  setRecord_id()
 }
 const deleteRecord=()=>{
      ExternalDataDelete(record_id, switchToken).then(function (response) {   
        if(response.statusText == "OK" || response.status == 200){
        let arr =  billDocuments;
        let index = arr.findIndex(x=>x.record_id=== record_id);
        let newArr = arr.splice(index, 1);
        setBillDocuments(newArr)
        return showToast(response.data.message); 
      }else{
        return showError("Delete failed. Please try again later");  
      }
       

      })
      .catch(function (err) {
        console.log(err);
      });
setDeleteModal(false)
 }
 function showError(str) {
  toast.error(str, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });
}

 function showToast(str) {
  toast.success(str, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });
}


  return (
    <React.Fragment>
  
     
      <div className="table-responsive">
     


     {
        billDocuments===null?<h6 className="text-center mt-2 mb-4">   It seems like you do not have any bills to display.</h6>
        :      <table className="table mhc-document-table">
          <thead>
            <tr>
              <th>Document Type</th>
            
              <th className="text-end">Download Bill</th>
           
            </tr>
          </thead>
          <tbody>
            {
      
              billDocuments?.map((item,i) => {
              
                  let img = item.documents;
                  let date = moment(item.billDate).format('DD MMM YYYY')
                  let day = moment(item.record_date).format('ddd')
                  let url = item.pdfLink;
                  return (
                    <tr key={i+1}>
                      <td>
                            {/* {item.documents.length > 0 ? <Link className="text-secondary text-decoration-none" to="" onClick={(e) => handlerSlider(e, img)}><img src={item.cat_image} alt={item.cat_image} className="test-icon" /> {item.document_name}</Link> */}
                            <div className="d-flex">
                                <div> <img src={`${APP_SUB_FOLDER}${PdfIcon}`} alt={`${APP_SUB_FOLDER}${PdfIcon}`} className="test-icon" /></div>
                                <div className="ms-2">
                                <span className="mb-1 custom-text fw-bold">{date}</span> <br />
                                <span className="text-primary">INR {item.billAmount}</span> 
                                </div>
                            </div>
                            
                           
                         
                      </td>
                    
                      <td className="text-end">
                      <a
                        onClick={(e) => savePdf(e, url)}
                        download={item.patient_report_pdf}>
                        <img
                          src={`${APP_SUB_FOLDER}${DownloadIcon}`}
                          alt={`${APP_SUB_FOLDER}${DownloadIcon}`}
                          className="downloadbtn"
                        />
                      </a>
                      </td>
                     
                    </tr>
                  )
                })
            }


          </tbody>
        </table>
     }
  
      </div>
      <Modal show={show} onHide={handleClose} className="mhc-imgPopup"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="minHeight5">


          <Slider {...settings}>
      
            {
              imaData === undefined ? "" : imaData.map((itemm) => (
                <div>
            
                  <embed type="image/jpg" src={itemm.document_path} className="popup-img" />
                </div>
              ))
            }


          </Slider>
        </Modal.Body>

      </Modal>
      <Modal show={deleteModal} onHide={closeDeleteModal} className="mhc-imgPopup"
        size="sm"
        centered
      >
     
        <Modal.Body>
          <div className="text-center py-2">
          <p className="mb-3">Are You want to Delete Record ?</p>


<button className="btn btn-outline-custom py-1 px-4 me-3" onClick={closeDeleteModal}>No</button>
<button className="btn btn-custom  py-1 px-4" onClick={()=>deleteRecord()}>Yes</button>
          </div>
                  
        </Modal.Body>

      </Modal>
     
    </React.Fragment>
  )
}

export default PatientMyBillsListing