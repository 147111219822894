import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";


import { ToastContainer, toast } from 'react-toastify';
import { getProfile, configurations } from "../../actions/get-calls";
import { setCallTokenReducer } from "../../redux/slice/call-token";
import { setSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setAllUsersReducer } from "../../redux/slice/all-users";
import { setSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer } from "../../redux/slice/user-profile";
import { getOTP,  savePIN,  switchPatient, verifyMobileOTP } from "../../actions/post-call";
import antiClock from "../../assets/images/anticlock.png";
import { useNavigate } from 'react-router-dom';
import SpinnerComponent from "../spinnerComponent";
import { APP_SUB_FOLDER, is_group_exist } from "../../utils/constant";
import OTPInput from "react-otp-input";
const VerifyRegister = ({ mobile, setOtpVerified }) => {
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const [spinnerResponse, setSpinnerResponse]=useState(false)
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const navigate = useNavigate();
  const [isVerify, setIsVerify] = useState(true);
  const [isSetPin, SetIsSetPin ]=useState(false)
  const [currentOTP, setCurrentOTP] = useState('');
  const [mobile_no, setMobileNo] = useState(mobile);
  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(true);
  const [pinsetNum, setPinsetNum]=useState("")
  const [spinnerResponsesetPin, setSpinnerResponsesetPin]=useState(false)
  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });
  function verifyOTP(e) {
    setSpinnerResponse(true)
    e.preventDefault();
    verifyMobileOTP({ isd_code: "91", otp: currentOTP, uid: mobile_no , hospital_group_id: currentLocation.hospital_group_id,  group_id: currentLocation.hospital_group_id})
      .then(function (login_response) {
        setSpinnerResponse(false)
    
        let login_res = login_response.data;
        let firstToken = login_response.headers.authorization;

        if (login_res.errors && login_res.errors.length > 0) {
          return showError(login_res.errors[0].message);
        }

 
        setIsVerify(false)
        SetIsSetPin(true)
     

        setIsResend(false);
        timeLeft(30);
        setMobileNo('')

      }).catch(function (err) { });
  }
  const pinSetlogin=()=>{
    setSpinnerResponsesetPin(true)
    savePIN(mobile_no, pinsetNum,  currentLocation.hospital_group_id, currentLocation.hospitalID)
    .then(function (response) {
      setSpinnerResponsesetPin(false)
      let res = response.data;
   
     
      if(res.errors){      
        return showError(res.errors[0].message);
      }
      else{
        setOtpVerified(true);
        dispatch(setCallTokenReducer(response.headers.authorization));
        dispatch(setAllUsersReducer(res.users));
       
        if (res && res.users && res.users.length == 1) {
          dispatch(setSwitchTokenReducer(response.headers.authorization));
          dispatch(setSelectedUserReducer(res.users[0]));
          navigate('/my-dashboard');
          goToTop()
        }
        else{
          switchUser(res.users[0].id, response.headers.authorization);
          navigate('/my-dashboard');
          goToTop()
        }  
      }
    

    
   
    })
    
    .catch(function (err) { });
   }
   function switchUser(id, token) {
    let payload = new FormData();
    payload.append("patient_id", id);
    if(is_group_exist == true){
      payload.append("hospital_group_id", currentLocation?.hospital_group_id);
      payload.append("group_id", currentLocation?.hospital_group_id);
     }
    switchPatient(
      payload,
      token 
    )
      .then(function (switch_response) {
        let switch_token = switch_response.headers.authorization;
        let switcUser = switch_response.data.user;
        dispatch(setSwitchTokenReducer(switch_token));
        dispatch(setSelectedUserReducer(switch_response.data.user));

        getProfile(switch_token)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
            
              dispatch(setUserProfileReducer(profile_response.data.user));
              // window.location.reload();

            }
          })
          .catch(function (err) {});

     //   setShowConfirmation(false);
     let userDetails = {
      "uniqueId":switcUser.uhid, //mandatory uniqueId.
      "patientId":switcUser.id,
      "email":switcUser.email,
      "phone":switcUser.mobile_number,
      "name":switcUser.full_name,
      "profileUrl":switcUser.profile_image,
      // "age":switcUser,
      "dateOfBirth":switcUser.dob,
      "gender":switcUser.gender
      }
      // console.log("userDetailsuserDetails", userDetails)
      window.ReWebSDK.userRegister(userDetails);
      })
      .catch(function (err) {});
  }
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }


  function handleOtpRequest(e) {
    e.preventDefault();
    getOTP(mobile_no, currentLocation.hospital_group_id)
      .then(function (x_response) {
        let x_res = x_response.data;

        if (x_res && x_res.errors) {
          x_res.errors.map((e) => {
            return showError(e.message);
          })
          return false;
        }

        setIsResend(true);
        setTimeLeft(30);
      })
      .catch(function () { });
  }

  return (
<>
    {
      isVerify===true ?
    
    <form className="mhc-register-form  py-4">
             
    <div className="row justify-content-center">
    {/* <div className="col-md-12 mb-5 text-center"><img className='login-logo' src={logo} /></div> */}
     <div className="col-md-12 mb-3">
         <h3 className="fs-4">Verify OTP</h3>
       </div>

 
     <div className="col-sm-8 ">
     <div className="d-flex ">
       <div className="form-floating w-100 mb-3">
         <input
           type="text"
           className="form-control border-radius-right-0 "
           maxLength="4"
           value={currentOTP}
           id="OTP"
           name="OTP"
           placeholder="Enter OTP"
           onChange={(e) => setCurrentOTP(e.target.value)}
           tabIndex="24"
         />
         <label>OTP <sup>*</sup></label>
       </div>               
       
       {
           isResend===true ? (
             <div className="input-group-text border-radius-left-0 border-start-0  custom-text bg-white">
             <div className="anitClock ">
               <img src={`${APP_SUB_FOLDER}${antiClock}`} className="anti-img" alt="" />
               <p className="otp-time">{timeLeft}</p>
             </div></div>
           ) : (
    <button onClick={(e) => handleOtpRequest(e)} className="input-group-text border-radius-left-0 border-start-0 text-primary bg-white " disabled={isResend}>Resend OTP</button>
           )
         }
        
       </div>
     </div>
     <div className="col-sm-4 ">
 
       <button className="btn btn-custom rounded-1" onClick={(e) => verifyOTP(e)}>Verify OTP {spinnerResponse == true? <SpinnerComponent />: null}</button>
     </div>              
   </div>
     <div className="row mt-3 mt-md-0  justify-content-center">
     <div className="col-12">
       <p className="m-0">An OTP has been sent to your mobile number, please enter to verify.</p>
     </div>
     </div>

 </form>
  :""
  
        }
         {isSetPin===true? <div className="mhc-loginscreen h-auto">
            <div class="nav-reigster-form text-center ">
       
        
            <p className="mt-4  mb-0 text-secondary">Please set your 4 digit PIN</p>
         
          <OTPInput
            value={pinsetNum}
            onChange={setPinsetNum}
            numInputs={4}  
            containerStyle="otp-wrapper"   
            inputStyle="input"            
            // renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
             />
               <button className="btn btn-custom rounded-1 mt-3" onClick={()=>pinSetlogin()}  >Set PIN   {spinnerResponsesetPin == true? <SpinnerComponent />: null}   </button>
            
        </div>
      </div>:null
          }
        </>
  );
};

export default VerifyRegister;
